import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
	XSpace,
	XDropdown,
	XAvatar,
	XDownOutlined,
	XSpaceNotif,
	Breadcrumb,
	XBreadcrumb,
	XUpdateModal,
} from './HeaderStyle';
import { XContainer } from '../../styles/GlobalStyle';
// actions
import { logoutApi, logoutReset } from '../../actions/logoutAction';
import { Col, Row, Space, notification, Avatar, Spin, Skeleton } from 'antd';
import config from '../../config';
import { checkModulePermissions, getUserProfile } from '../../utils/helper';
import { SoundOutlined } from '@ant-design/icons';
// Todo Notification Commented for Demo Branch
import NotificationPopover from '../Notification/NotificationsPopover';
import moment from 'moment';
import {
	getCodeUpdateDatesApi,
	getCodeUpdateDatesReset,
} from '../../actions/getCodeUpdateDatesAction';

const HeaderFile = (props) => {
	const {
		logout,
		logoutReset,
		logoutState,
		getCodeUpdateDates,
		getCodeUpdateDatesState,
		getCodeUpdateDatesReset,
	} = props;
	const navigate = useNavigate();
	const [updatedDatesModal, setUpdatedDatesModal] = useState(false);
	const [datesLoading, setDatesLoading] = useState(false);
	const [updatedDates, setUpdatedDates] = useState('');

	const items = [
		{
			key: '1',
			label: <Link to="/profile">My Profile</Link>,
			icon: (
				<img
					className="img-fluid"
					src="/images/profile-icon.svg"
					alt=""
				/>
			),
		},
		{
			type: 'divider',
		},
		{
			key: '2',
			label: checkModulePermissions('super-admin') && (
				<Link
					onClick={() => {
						setUpdatedDatesModal(true);
						getCodeUpdateDates(); // Fetch the update dates when the link is clicked
					}}
				>
					Policies and Codes Updated at
				</Link>
			),
			icon: <SoundOutlined style={{ fontSize: '1rem' }} />,
		},
		{
			type: 'divider',
		},
		{
			key: '3',
			label: <Link to="/change-password">Reset Password</Link>,
			icon: <img className="img-fluid" src="/images/reset.svg" alt="" />,
		},
		{
			type: 'divider',
		},
		{
			key: '4',
			label: (
				<Link
					onClick={() => {
						console.log('loks');
						logout();
					}}
				>
					Log Out
				</Link>
			),
			icon: <img className="img-fluid" src="/images/logout.svg" alt="" />,
		},
	];

	// use effect
	useEffect(() => {
		if (logoutState.apiState === 'success') {
			localStorage.removeItem('accessToken');
			localStorage.removeItem('username');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('expiresAt');
			localStorage.removeItem('profile');
			localStorage.removeItem('permissions');
			localStorage.removeItem('fcmToken');
			logoutReset();
			navigate('/login');
		} else if (logoutState.apiState === 'error') {
			notification.error({ message: logoutState.message });
			logoutReset();
		}
	}, [logoutState]);

	useEffect(() => {
		if (getCodeUpdateDatesState.apiState === 'loading') {
			setDatesLoading(true);
		}
		if (getCodeUpdateDatesState.apiState === 'success') {
			setDatesLoading(false);
			setUpdatedDates(getCodeUpdateDatesState.data);
		}
		if (getCodeUpdateDatesState.apiState === 'error') {
			setDatesLoading(false);
			notification.error({ message: getCodeUpdateDatesState.message });
		}
		getCodeUpdateDatesReset();
	}, [getCodeUpdateDatesState]);

	return (
		<>
			<XContainer
				fluid
				className="mb-4 mt-2"
				style={{ display: 'flex', justifyContent: 'space-between' }}
			>
				{props.breadcrumb === 'true' ? (
					<div className="d-block">
						{props.preview === 'true' ? (
							<>
								<label className="headerTagStyle">
									{props.previewId}
								</label>
								<br />
								<XBreadcrumb
									items={[
										{
											title: (
												<Link to="/case-filing-management">
													{props.headerTag}
												</Link>
											),
										},
										{
											title: (
												<Link
													onClick={() => navigate(-1)}
												>
													{props.caseId}
												</Link>
											),
										},
										{
											title: <>{props.previewId}</>,
										},
									]}
								/>
							</>
						) : (
							<>
								<label className="headerTagStyle">
									{props.caseId}
								</label>
								<br />
								<XBreadcrumb
									items={[
										{
											title: (
												<Link
													to={
														props.link
															? `${props.link}`
															: '/case-filing-management'
													}
												>
													{props.headerTag}
												</Link>
											),
										},
										{
											title: <>{props.caseId}</>,
										},
									]}
								/>
							</>
						)}
					</div>
				) : (
					<label className="headerTagStyle">{props.headerTag}</label>
				)}
				<Row>
					<Col xs={12} lg={12} xl={12}>
						<XDropdown
							menu={{
								items,
							}}
							placement="bottom"
						>
							<XSpace>
								<XAvatar
									size={30}
									src={
										getUserProfile() === 'undefined'
											? '/images/prifile.svg'
											: `${
													config.s3_url
											  }${getUserProfile()}`
									}
								/>
								<XDownOutlined />
							</XSpace>
						</XDropdown>
					</Col>
					{/* // Todo Notification Commented for Demo branch */}
					<NotificationPopover />
					{/* <Col
					xs={12}
					lg={12}
					xl={12}
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					 <XSpaceNotif>
						<img src="/images/bell-icon.svg" alt="bell" />{' '}
					</XSpaceNotif> 
				</Col> */}
				</Row>
			</XContainer>
			<XUpdateModal
				title="Policies and Codes Updated at"
				centered
				open={updatedDatesModal}
				onCancel={() => {
					setUpdatedDatesModal(false);
					getCodeUpdateDatesReset(); // Reset the state when modal is closed
					setUpdatedDates('');
				}}
				width={650}
				footer={false}
			>
				<Row gutter={(15, 15)}>
					{datesLoading && (
						<Col span={24} style={{ textAlign: 'center' }}>
							<Skeleton active paragraph={{ rows: 3 }} />
						</Col>
					)}
					{updatedDates &&
						updatedDates.map((item) => (
							<Col
								xs={24}
								sm={12}
								md={12}
								lg={12}
								className="my-2"
								key={item._id}
							>
								<div className="modalCard">
									<Row
										gutter={(15, 15)}
										className="align-items-center"
									>
										<Col xs={6}>
											<div className="cardImgBg">
												<img
													src="/images/policies-ncd-icon.svg"
													alt=""
												/>
											</div>
										</Col>
										<Col xs={18}>
											<h6>
												{item.code_type} - Last Updated
												Date
											</h6>
											<h4>
												{moment(
													item.latest_update.date,
												).format('DD MMM YYYY')}
											</h4>
										</Col>
									</Row>
								</div>
							</Col>
						))}
				</Row>
			</XUpdateModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	logoutState: state.logout,
	getCodeUpdateDatesState: state.getCodeUpdateDates,
});

const mapDispatchToProps = (dispatch) => ({
	logout: (params) => dispatch(logoutApi(params)),
	logoutReset: () => dispatch(logoutReset()),
	getCodeUpdateDates: () => dispatch(getCodeUpdateDatesApi()),
	getCodeUpdateDatesReset: () => dispatch(getCodeUpdateDatesReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderFile);
