import { Col, notification, Spin, Badge } from 'antd';
import { onMessage } from 'firebase/messaging';
import { XSpaceNotif } from '../Header/HeaderStyle';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import {
	List,
	Popover,
	Typography,
	ListItemText,
	ListSubheader,
	ListItemButton,
} from '@mui/material';
import {
	getNotificationApi,
	getNotificationReset,
} from '../../actions/getNotificationAction';
import { getUserToken } from '../../utils/helper';
import config from '../../config';
import { messaging } from '../../firebase/firebase';

const NotificationPopover = (props) => {
	const { getNotification, getNotificationReset, getNotificationState } =
		props;
	const [open, setOpen] = useState(null);
	const [notifications, setNotifications] = useState([]);
	const [unreadCount, setUnreadCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const handleOpen = (event) => {
		getNotification();
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	// Handle incoming messages
	onMessage(messaging, (payload) => {
		console.log('Message received. ', payload);
		setUnreadCount(unreadCount + 1);
	});

	useEffect(() => {
		if (getNotificationState.apiState === 'success') {
			setNotifications(getNotificationState.data?.notificationList);
			setUnreadCount(getNotificationState.data?.unreadCount);
			setLoading(false);
		} else if (getNotificationState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: getNotificationState.message });
		}
		getNotificationReset();
	}, [getNotificationState]);

	useEffect(() => {
		setLoading(true);
		getNotification();
	}, []);

	return (
		<>
			<Col
				xs={12}
				lg={12}
				xl={12}
				style={{
					display: 'flex',
					justifyContent: 'center',
					position: 'relative',
				}}
			>
				<XSpaceNotif>
					<div style={{ position: 'relative' }}>
						<Badge count={unreadCount} offset={[6, 0]}>
							<img
								onClick={handleOpen}
								style={{ cursor: 'pointer' }}
								src="/images/bell-icon.svg"
								alt="bell"
							/>{' '}
						</Badge>
					</div>
				</XSpaceNotif>
			</Col>
			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				PaperProps={{
					sx: {
						mt: 1,
						ml: 0.75,
						width: 360,
						...(notifications.length > 0
							? { maxHeight: 'inherit !important' }
							: { height: 180 }),
						// maxHeight: 'inherit !important',
						// height:340,
						p: '0 !important',
					},
				}}
			>
				<div style={{ maxHeight: '340px', overflowY: 'auto' }}>
					{loading ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								// height: '100%',
								height: 340,
								width: '100%',
								textAlign: 'center',
								position: 'absolute',
								top: 0,
								left: 0,
							}}
						>
							<Spin />
						</div>
					) : notifications.length > 0 ? (
						<List disablePadding>
							{notifications.map((notification, i) => {
								return (
									<div index={i}>
										<NotificationItem
											notification={notification}
											getNotification={getNotification}
										/>
									</div>
								);
							})}
						</List>
					) : (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%',
								// height:"340px",
								width: '100%',
								textAlign: 'center',
								position: 'absolute',
								top: 0,
								left: 0,
							}}
						>
							<span> You don’t have notifications</span>
						</div>
					)}
				</div>
			</Popover>
		</>
	);
};
// ----------------------------------------------------------------------------
function NotificationItem(props) {
	const navigate = useNavigate();
	const { notification,getNotification } = props;
	const notificationRouteHanler = async (id) => {
		try {
			if(!notification.isRead){
				const { data } = await axios.post(
					`${config.api.base_url}/notification/mark-as-read`,
					{
						notification_id: id,
					},
					{
						headers: {
							'Content-type': 'application/json; charset=UTF-8',
							Authorization: 'Bearer ' + getUserToken(),
						},
					},
				);

				if (data.statusCode === 200) {
					console.log('mark them read successfully')
				} else {
					console.error('Error occurred while mark notification is read');
				}
			}
			
					getNotification();
					navigate(notification.url);
			
		} catch (error) {
			console.error('Error occurred:', error);
		}
	};

	const currentDate = moment();
	let displayTime;
	if (moment(notification?.createdAt).isSame(currentDate, 'day')) {
		displayTime = 'today,';
	} else if (
		moment(notification?.createdAt).isSame(
			currentDate.clone().subtract(1, 'days'),
			'day',
		)
	) {
		displayTime = 'yesterday,';
	} else {
		displayTime = moment(notification?.createdAt).format('MMM D,');
	}

	return (
		<>
			<ListItemButton
				sx={{
					py: 1.5,
					px: 2.5,
					mt: '0.5px',
					borderRadius: '0 !important',
					...(notification?.isRead == false && {
						bgcolor: 'action.selected',
					}),
				}}
			>
				<ListItemText
					onClick={() => notificationRouteHanler(notification._id)}
					primary={
						<>
							<Typography
								variant="body2"
								sx={{
									// color: 'primary.main',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<span>{notification.title}</span>
								<span
									style={{
										marginLeft: 'auto',
										color: 'text.disabled',
									}}
								>
									{displayTime}{' '}
									{moment(notification?.createdAt).format(
										'hh:mm A',
									)}
								</span>
							</Typography>
							<Typography
								variant="body2"
								sx={{
									mt: 0.5,
									color: 'primary',
								}}
								dangerouslySetInnerHTML={{
									__html: notification.message,
								}}
							></Typography>
						</>
					}
				/>
			</ListItemButton>
		</>
	);
}

const mapStateToProps = (state) => ({
	getNotificationState: state.getNotification,
});

const mapDispatchToProps = (dispatch) => ({
	getNotification: (params) => dispatch(getNotificationApi(params)),
	getNotificationReset: () => dispatch(getNotificationReset()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(NotificationPopover);
// export default NotificationPopover;
