import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button,
	Input,
	Space,
	Divider,
	notification,
	Affix,
	Select,
	Tooltip,
	Tag,
	List,
	Checkbox,
	Radio,
	Modal,
	Card,
	Image,
	Upload,
	Skeleton,
	Spin,
} from 'antd';
import { XLottie } from '../Login/LoginStyle';
import { useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
// import ReloadingAnimation from '../../json/reloading.json';
import ReloadingAnimation from '../../json/decodex_updated05.json';
import Thanks from '../../json/thanksNew.json';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XSelect,
	XCardsCriteriaBg,
	XRoundButton,
	XModalTitle,
	XModalSubtitle,
	XList,
	XCircle,
	XLabel,
	XSelectAddCase,
	XAffix,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsCaseBg } from '../../styles/GlobalStyle';
import { XButtonImg } from '../Manage/ManageStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XCardsGrdBG,
	XButtonAdd,
	XTitleHead,
	XDivider,
	XButtonLike,
	XButtonCloseBar,
	XButtonReload,
	XNotificationBar,
	XResults,
	XLottieOverlay,
	XButtonConfirm,
	XCheckboxGroup,
	XuploadAddCase,
	XViewMoreBTN,
} from './AnalyseCoverageStyle';
import {
	PlusOutlined,
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
	CopyOutlined,
	MailOutlined,
	CloseOutlined,
	ReloadOutlined,
	SyncOutlined,
	EyeOutlined,
	FilePdfOutlined,
	InboxOutlined,
	DownOutlined,
	UpOutlined,
} from '@ant-design/icons';
// Actions
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import Header from '../../components/Header/Header';
import {
	filterByLabel,
	handleInputChange,
	handleSelectChange,
} from '../../utils/formFunctions';
import {
	getAnalysisReset,
	getAnalysisApi,
} from '../../actions/getAnalysisAction';
import { medicalCoverage } from '../../utils/constants';
import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
import { checkModulePermissions } from '../../utils/helper';
import { editCaseApi } from '../../actions/editCaseAction';
import CaseOperations from '../../components/HorizontalTimeline/CaseOperations';
import {
	XAddCodeSticky,
	XButtonModal,
	XICDModal,
	XICDModalFooterBTN,
	XRadio,
	XRelatedCard,
	XRelatedReadMore,
	XTabIcdCpt,
} from '../CaseForm/CaseFormStyle';
import config from '../../config';
import {
	getIcdCodesApi,
	getIcdCodesReset,
} from '../../actions/getIcdCodesAction';
import { XcaseAnsyCardScroll } from '../CaseAnalysis/CaseAnalysisStyle';
import {
	getCptCodesApi,
	getCptCodesReset,
} from '../../actions/getCptCodesAction';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import DrawerCodingTools from '../../components/CodingTools/DrawerCodingTools';
const { TextArea } = Input;
const moment = require('moment');

const { Dragger } = Upload;

const AnalyseCoverage = (props) => {
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		getStateDropdownState,
		getStateDropdownReset,
		getStateDropdown,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
	} = props;
	const missingFieldsCardRef = useRef(null);
	const [form] = XForm.useForm();
	const [formUpload] = XForm.useForm();
	const [clinicalDocsForm] = XForm.useForm();
	const [showClinicalTextArea, setShowClinicalTextArea] = useState(false);
	const [isClinicalUploadModalOpen, setIsClinicalUploadModalOpen] =
		useState(false);
	const [clinicalDocsLoading, setClinicalDocsLoading] = useState(false);
	const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
		[],
	);
	const [submitClinicalDocs, setSubmitClinicalDocs] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [formData, setFormData] = useState();
	const [clinicalSummaryTextLoading, setClinicalSummaryTextLoading] =
		useState(false);
	const [supportingDocsSummary, setSupportingDocsSummary] = useState('');
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	const [newOptionForm] = XForm.useForm();
	const [summaryForm] = XForm.useForm();
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState([false, '']);
	const [summary, setSummary] = useState('');
	const [state, setState] = useState('');
	const [provider, setProvider] = useState('');
	const [modalTextArea, setModalTextArea] = useState('');
	const [coverage, setCoverage] = useState('Yes');
	const [feedbackId, setFeedbackId] = useState(null);
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
		status: '',
		missing_points_for_coverage: [],
	});
	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
	const [callGetAnalysisAPiBtn, setCallGetAnalysisAPiBtn] = useState(true);
	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	const [missingFields, setMissingFields] = useState([]);
	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
		[],
	);
	const [missingFieldTitle, setMissingFieldTitle] = useState('');
	const [missingFieldsForm] = XForm.useForm();
	const [lastAnalysisData, setLastAnalysisData] = useState({});
	const [showAnalysisData, setShowAnalysisData] = useState(false);
	const [analysisCompleted, setAnalysisCompleted] = useState('');
	const [newOptionText, setNewOptionText] = useState('');
	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	const [id, setId] = useState('');
	const [coverageloading, setCoverageLoading] = useState(false);
	const [LooksGoodLoading, setLooksGoodLoading] = useState(false);
	const [isMissingFieldsThere, setIsMissingFieldsThere] = useState(false);
	const [
		updatedClinicalDocumentFileList,
		setUpdatedClinicalDocumentFileList,
	] = useState([]);
	const [documentName, setDocumentName] = useState('');
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [uploadedSupporingDoc, setUploadedSupporingDoc] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [clinicalDocumentsToDisplay, setClinicalDocumentsToDisplay] =
		useState([]);
	const [supportingDoc, setSupportingDoc] = useState([]);
	const [clinicalSelectedOption, setClinicalSelectedOption] =
		useState('clinical_text'); // Initial value can be 'textarea' or 'upload'
	const [supportingFiles, setSupportingFiles] = useState([]);
	const [isClinicalDocUploadFromCommon, setIsClinicalDocUploadFromCommon] =
		useState(false);
	const [isUploadClinicalDoc, setIsUploadClinicalDoc] = useState(false);

	console.log(
		'analysisCompletedanalysisCompletedanalysisCompletedanalysisCompletedanalysisCompleted!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!',
		analysisCompleted,
	);

	const [icdform] = XForm.useForm();
	const [cptform] = XForm.useForm();
	const [openIcdCptDrawer, setOpenIcdCptDrawer] = useState(true);
	const [openIcdCptDrawer2, setOpenIcdCptDrawer2] = useState(false);
	const [activeCode, setActiveCode] = useState(null);
	const [icdFilters, setIcdFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
		related: false,
		childCode: '',
	});
	const [icdCodes, setIcdCodes] = useState([]); // To store fetched ICD codes
	const [icdLoading, setIcdLoading] = useState(false); // To manage loading state
	const [searchResults, setSearchResults] = useState([]); // To store search results
	const [triggeredBySearch, setTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [triggeredByEnter, setTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [loadMore, setLoadMore] = useState(false); // To track search-triggered calls
	const [icdSearchLoading, setIcdSearchLoading] = useState(false); // To manage loading state
	//CPT Codes
	const [cptFilters, setCptFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
	});
	const [cptCodes, setCptCodes] = useState([]); // To store fetched ICD codes
	const [cptLoading, setCptLoading] = useState(false); // To manage loading state
	const [cptSearchResults, setCptSearchResults] = useState([]); // To store search results
	const [cptTriggeredBySearch, setCptTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [cptTriggeredByEnter, setCptTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [cptLoadMore, setCptLoadMore] = useState(false); // To track search-triggered calls
	const [cptSearchLoading, setCptSearchLoading] = useState(false); // To manage loading state
	// State to track the "fixed" prop value
	const [isFixed, setIsFixed] = useState(true);

	// Open the modal and set a timer to automatically close it after 3 seconds
	useEffect(() => {
		let timer;
		if (thanksModal) {
			timer = setTimeout(() => {
				setThanksModal([false, '']);
			}, 3000);
		}

		// Clear the timer if the component unmounts or showThanksModal changes
		return () => clearTimeout(timer);
	}, [thanksModal]);

	useEffect(() => {
		// Function to check screen width and update "isFixed" only if below 991px
		const handleResize = () => {
			if (window.innerWidth < 991) {
				setIsFixed(false);
				closeIcdCptDrawer();
				setOpenIcdCptDrawer2(false);
				setActiveCode(null);
				setIcdLoading(false);
				setTriggeredBySearch(false);
				setIcdSearchLoading(false);
				setLoadMore(false);
				icdform.resetFields();
				setCptLoading(false);
				setCptTriggeredBySearch(false);
				setCptSearchLoading(false);
				setCptLoadMore(false);
				cptform.resetFields();
			} else {
				setIsFixed(true); // Ensures `fixed` is `true` on larger screens
				showIcdCptDrawer();
			}
		};

		// Initial check on mount
		handleResize();

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		// Cleanup the event listener on component unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	useEffect(() => {
		if (modalOpen[0] && modalOpen[4] !== 'feedbackSummary') {
			// Check if modal is open and it's not the feedbackSummary modal
			const [description, code] = modalTextArea.split(' - ');
			form.setFieldsValue({
				modal_text_area: description || '', // Prepopulate description
				code: code ? code.split(': ')[1] : '', // Extract and prepopulate code
			});
		}
	}, [modalOpen, modalTextArea, form]);

	// Example of handleCodeClick
	const handleCodeClick = (codeType, codeData) => {
		console.log(activeCode, 'activecode');
		// Check if the code has leading or trailing spaces
		let trimmedCode = codeData.code;

		if (trimmedCode !== trimmedCode.trim()) {
			trimmedCode = trimmedCode.trim();
		}

		// // Check if it's a child code
		// const isChildCode = trimmedCode.includes('.');
		// const parentCode = isChildCode
		// 	? trimmedCode.split('.')[0]
		// 	: trimmedCode;
		setIcdCodes();
		closeIcdCptDrawer();
		setActiveCode({
			type: codeType,
			code: codeData.code,
			description: codeData.description,
			codeTypeText: codeData.codeTypeText,
			buttontext: codeData.buttontext,
		});

		if (codeType === 'CPT') {
			// // Set filters to fetch related codes
			setCptFilters({
				related: true,
				childCode: trimmedCode,
			});
			setCptTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
		} else {
			// Set filters to fetch related codes
			setIcdFilters({
				related: true,
				childCode: trimmedCode,
			});
			setTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
		}
	};

	const showIcdCptDrawer = () => {
		setOpenIcdCptDrawer(true);
		setIcdCodes();
		setActiveCode(null);
		setSearchResults();
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptCodes();
		setCptSearchResults();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer = () => {
		setOpenIcdCptDrawer(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer2 = () => {
		setOpenIcdCptDrawer2(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		showIcdCptDrawer();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const handleUploadCancel = () => {
		setIsClinicalUploadModalOpen(false);
		setIsUploadModalOpen(false);
		setClinicalDocumentFileList([]);
		setUpdatedClinicalDocumentFileList([]);
		setClinicalDocsLoading(false);
		setSubmitClinicalDocs(false);
		setFormData({
			...formData,
			clinical_summary: '',
		});

		clinicalDocsForm.setFieldsValue({ clinical_docs: '' });
	};

	const handleSupportingDocChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setSupportingDoc(updatedFileList);
		formUpload.validateFields(['supporting_docs']);
	};

	const uploadSupportingDocButton = (
		<Button
			onClick={() => setIsUploadModalOpen(true)}
			className="m-0"
			style={{
				borderRadius: '6px',
				border: '1px dashed #d9d9d9',
				display: 'block',
				height: '100px',
				padding: '4px 15px',
				width: '100px',
				background: 'rgba(0, 0, 0, 0.02)',
			}}
		>
			<PlusOutlined style={{ color: '#000' }} />
			<div style={{ color: '#000' }}>Upload</div>
		</Button>
	);

	const handlePreview = async (file) => {
		if (file && file.name && file.name.split('.').includes('pdf')) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handlePreviewClinicalSummary = async (file) => {
		// Check if the file is a PDF
		if (file && file.name && file.name.split('.').includes('pdf')) {
			// If there's a URL, use it directly; otherwise, generate a preview from the file
			const fileUrl = file.url ? `${config.s3_url}${file.url}` : null;
			setPreviewImage(fileUrl || file.preview);
			setPdfPreviewOpen(true); // Open PDF preview modal
		} else {
			// For non-PDF files, generate a preview if necessary
			if (!file.url && !file.preview && file.originFileObj) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true); // Open image preview modal
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleClinicalDocumentChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		setUpdatedClinicalDocumentFileList(updatedFileList);
		summaryForm.setFieldsValue({
			clinical_summary: '',
		});
		setFormData({
			...formData,
			clinical_summary: '',
		});
		setSummary('');
		summaryForm.validateFields(['clinical_document']);
	};

	const uploadButton = (
		<Button
			style={{
				border: 0,
				background: 'none',
				margin: '0',
				boxShadow: 'none',
			}}
		>
			<PlusOutlined style={{ color: '#000' }} />
			<div style={{ color: '#000' }}>Upload</div>
		</Button>
	);

	const handleRemoveFile = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: async () => {
				const updatedFileList = supportingFiles.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				const UploadedFile = uploadedSupporingDoc.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				// const updatedUploadedFile = updatedUploadedSupporingDoc.filter(
				// 	(item) => item.url.uid !== file.url.uid,
				// );
				setSupportingFiles(updatedFileList);
				// setUpdatedUploadedSupporingDoc(updatedUploadedFile);
				setUploadedSupporingDoc(UploadedFile);
				// if (!file.url.originFileObj) {
				// 	setDeletedUploadedSupportingDoc((prevFiles) => [
				// 		...prevFiles,
				// 		file,
				// 	]);
				// }
			},
			onCancel: () => {
				console.log('Deletion cancelled');
			},
		});
	};

	const handleRemoveClinicalDocuments = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: async () => {
				const updatedFileList = clinicalDocumentFileList.filter(
					(item) => item.uid !== file.uid,
				);
				const updatedFileListToDisplay =
					clinicalDocumentsToDisplay.filter(
						(item) => item.uid !== file.uid,
					);
				setClinicalDocumentFileList(updatedFileList);
				setUpdatedClinicalDocumentFileList(updatedFileList);
				setClinicalDocumentsToDisplay(updatedFileListToDisplay);
				form.validateFields(['clinical_document']);
				if (!file.originFileObj) {
					// setDeletedClinicalDocuments((prevFiles) => [
					// 	...prevFiles,
					// 	file,
					// ]);
				}
			},
			onCancel: () => {
				form.validateFields(['clinical_document']);
				console.log('Deletion cancelled');
			},
		});
	};

	// Function to generate a random alphanumeric string of given length
	const generateRandomAlphaNumeric = (length) => {
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length),
			);
		}
		return result;
	};

	// Function to generate an ID with specified format
	const generateCustomId = () => {
		const randomPart = generateRandomAlphaNumeric(9); // First 9 characters alphanumeric
		const timestampPart = moment().format('YYDDMMHHMMSSMS'); // Last 15 characters formatted as YYDDMMHHMMSSMS
		const customId = randomPart + timestampPart;

		return customId;
	};
	// const showDeleteConfirm = (title, id, content, item) => {
	// 	XModal.confirm({
	// 		title: `Delete ${title}?`,
	// 		content: (
	// 			<p className="modalPara1">
	// 				Are you sure you want to delete - {content}?
	// 			</p>
	// 		),
	// 		icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
	// 		okText: 'Yes, Confirm',
	// 		okType: 'danger',
	// 		cancelText: 'No, Cancel',
	// 		className: 'confirmModal',
	// 		onOk() {
	// 			deletePageContent(item, id);
	// 		},
	// 		onCancel() {
	// 			console.log('Cancel');
	// 		},
	// 	});
	// };

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			if (title === 'originalQuery') {
				setPageContent({
					...pageContent,
					originalQuery: content,
				});
			} else {
				setPageContent({
					...pageContent,
					[title]: [...pageContent[[title]], content],
				});
			}
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		// setSummary(formData.clinical_summary);
		setClinicalSummaryTextLoading(false);
		setShowClinicalTextArea(false);
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification').classList.remove('d-none');
	};

	// const deletePageContent = (item, id) => {
	// 	const updatedPageContent = { ...pageContent };
	// 	updatedPageContent[[item]].splice(id, 1);
	// 	setPageContent(updatedPageContent);
	// 	setModalOpen([false, '', ' ']);
	// 	setModalTextArea('');
	// 	form.resetFields();
	// 	document.getElementById('notification').classList.remove('d-none');
	// };

	const handleAddOption = (index) => {
		setNewCheckBoxModal([true, index]);
		setNewOptionText('');
		newOptionForm.setFieldsValue({});
		newOptionForm.resetFields();
	};

	const handleSupportingDocSubmit = async () => {
		formUpload
			.validateFields()
			.then(async () => {
				if (supportingDoc && supportingDoc.length > 0 && documentName) {
					let obj = {
						url: supportingDoc[0],
						document_name: documentName,
					};
					setUploadedSupporingDoc((prevFiles) => [...prevFiles, obj]);
					obj.preview = await getBase64(
						supportingDoc[0].originFileObj,
					);
					setSupportingFiles((prevFiles) => [...prevFiles, obj]);
					setSupportingDoc([]);
					setDocumentName('');
					formUpload.resetFields();
					setIsUploadModalOpen(false);
				}
			})
			.catch((err) => {});
	};

	const handleNewCheckBoxModal = () => {
		// Create a new option object
		newOptionForm
			.validateFields()
			.then(() => {
				const newOption = {
					label: newOptionText,
					value: newOptionText,
				};

				if (
					newCheckBoxModal[1] !== null &&
					newCheckBoxModal[1] >= 0 &&
					newCheckBoxModal[1] < missingFields.length
				) {
					const updatedFields = [...missingFields];
					updatedFields[newCheckBoxModal[1]].options.push(newOption);
					setMissingFields(updatedFields);

					// Get the current form values
					const currentValues = missingFieldsForm.getFieldsValue();

					// Add the new option to the appropriate field and mark it as checked
					if (
						!currentValues[updatedFields[newCheckBoxModal[1]].name]
					) {
						currentValues[updatedFields[newCheckBoxModal[1]].name] =
							[];
					}
					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
						newOptionText,
					);

					// Update the form with the new values
					missingFieldsForm.setFieldsValue(currentValues);
				}

				// Close the modal and reset the newOptionText state
				setNewCheckBoxModal([false, null]);
				setNewOptionText('');
				newOptionForm.setFieldsValue({});
				newOptionForm.resetFields();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	const handleClinicalSummaryDoc = () => {
		const formData = new FormData();

		// // Append supportingDocs files
		// supportingFiles.forEach((file) => {
		// 	formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
		// });

		// Append clinicalDocs files
		clinicalDocumentFileList.forEach((file) => {
			formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
		});
		formData.append('supportingDocsSummary', supportingDocsSummary);
		// formData.append('state', state);
		// formData.append('provider', provider);
		// formData.append('analysis_id', id);
		// Log to check FormData contents
		// setLoading(true);
		setIsClinicalDocUploadFromCommon(true);
		analyseCoverage(formData);
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	// const getBase64 = (file, callback) => {
	// 	const reader = new FileReader();
	// 	reader.readAsDataURL(file);
	// 	reader.onload = () => callback(reader.result);
	// 	reader.onerror = (error) => console.error('Error reading file:', error);
	// };

	const handleClinicalDocsChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			// file.preview = await getBase64(file.originFileObj);
			return file;
		});
		// const updatedFileList = newFileList.map((file) => {
		// 	if (file.status === 'error') {
		// 		return { ...file, status: 'done' };
		// 	}

		// 	// Get base64 string for the file and set preview
		// 	getBase64(file.originFileObj, (base64String) => {
		// 		let newFile = file;
		// 		newFile.preview = base64String;
		// 		setUpdatedClinicalDocumentFileList([newFile]);

		// 	});

		// 	return file;
		// });
		setClinicalDocumentFileList(updatedFileList);
		setUpdatedClinicalDocumentFileList(updatedFileList);
		clinicalDocsForm.validateFields(['clinical_docs']);
	};

	// Function to handle form submission
	const handleShazamClick = () => {
		setLoading(true); // Assuming you have setLoading function declared somewhere
		const newId = generateCustomId();
		setId(newId); // Update state with generated ID

		const formData = new FormData();
		// Append supportingDocs files
		supportingFiles.forEach((file) => {
			formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
		});

		// Append clinicalDocs files
		clinicalDocumentFileList.forEach((file) => {
			formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
		});
		formData.append('clinicalSummary', summary);
		formData.append('state', state);
		formData.append('provider', provider);
		formData.append('analysis_id', newId);
		// Log to check FormData contents
		setIsUploadClinicalDoc(true);
		setIsClinicalDocUploadFromCommon(true);
		setLoading(true);
		analyseCoverage(formData);

		// Assuming analyseCoverage function makes an API call
		// analyseCoverage({
		// 	clinicalSummary: summary,
		// 	state: state,
		// 	provider: provider,
		// 	analysis_id: newId, // Use the newly generated ID here
		// 	supportingDocsSummary: '',
		// })
		// 	.then(() => {
		// 		setLoading(false);
		// 	})
		// 	.catch((error) => {
		// 		setLoading(false);
		// 		console.error('API Error:', error);
		// 	});
	};

	useEffect(() => {
		if (showAnalysisData) {
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [showAnalysisData]);

	useEffect(() => {
		if (missingFieldsModal && missingFieldsCardRef.current) {
			// ADD
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
			// ADD
			missingFieldsCardRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [missingFieldsModal]);

	useEffect(() => {
		if (analyseCoverageState.apiState === 'success') {
			//
			setClinicalSummaryTextLoading(false);
			setClinicalDocumentsToDisplay(updatedClinicalDocumentFileList);
			handleUploadCancel();
			setClinicalDocsLoading(false);
			setShowClinicalTextArea(false);
			// setUpdateModalButtonText('Update');
			//
			setFeedbackId(analyseCoverageState.data.feedback_id);
			setSupportingDocsSummary(
				analyseCoverageState.data?.supportingDocsSummary,
			);
			setLastAnalysisData(analyseCoverageState.data);
			if (isClinicalDocUploadFromCommon) {
				setSummary(analyseCoverageState.data['original_query'] ?? '');
			}
			// if (isClinicalDocUploadFromCommon) {
			// 	setPageContent({
			// 		...pageContent,
			// 		originalQuery:
			// 			analyseCoverageState.data['original_query'] ?? '',
			// 	});
			// } else {
			setPageContent({
				...pageContent,
				originalQuery:
					analyseCoverageState.data['original_query'] ?? '',
				pastHistory: analyseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					analyseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					analyseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					analyseCoverageState.data.missing_points_for_coverage ?? [],
			});
			// }

			// old
			// setPageContent({
			// 	...pageContent,
			// 	originalQuery:
			// 		analyseCoverageState.data['original_query'] ?? '',
			// 	pastHistory: analyseCoverageState.data['Past History'] ?? [],
			// 	presentComplaint:
			// 		analyseCoverageState.data['Present Complaint'] ?? [],
			// 	recommendedProcedure:
			// 		analyseCoverageState.data['Suggested Treatment'] ?? [],
			// 	medicalCoverage: [
			// 		analyseCoverageState.data['Medicare Evaluation']
			// 			? analyseCoverageState.data['Medicare Evaluation'][
			// 					'Coverage'
			// 			  ]
			// 			: 'No',
			// 		analyseCoverageState.data['Medicare Evaluation']
			// 			? analyseCoverageState.data['Medicare Evaluation'][
			// 					'Rationale'
			// 			  ]
			// 			: '',
			// 		analyseCoverageState.data['Medicare Evaluation']
			// 			? analyseCoverageState.data['Medicare Evaluation'][
			// 					'reference'
			// 			  ]
			// 			: null,
			// 	],
			// 	missing_points_for_coverage:
			// 		analyseCoverageState.data.missing_points_for_coverage ?? [],
			// });

			setAnalysisCompleted('success');
			analyseCoverageReset();
			// setLoading(false);
			setIsUploadClinicalDoc(false);
			setIsClinicalDocUploadFromCommon(false);

			if (
				analyseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				analyseCoverageState.data.is_fields_missing &&
				analyseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (analyseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						analyseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						analyseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						analyseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
				setIsMissingFieldsThere(false);
			}
		}

		if (analyseCoverageState.apiState === 'error') {
			// setLoading(false);
			setIsClinicalDocUploadFromCommon(false);
			// setShowClinicalTextArea(false);
			setAnalysisCompleted('error');
			setIsMissingFieldsThere(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			analyseCoverageReset();
		}
	}, [analyseCoverageState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			setLastAnalysisData(
				getAnalysisState.data?.coverage_feedback_response,
			);
			setPageContent({
				...pageContent,
				originalQuery: summary ?? '',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			setLoading(false);
			setCoverageLoading(false);
			setLooksGoodLoading(false);
			setModalOpen([false, '', '']);
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}

		if (getAnalysisState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
		}
		getAnalysisReset();
	}, [getAnalysisState]);

	useEffect(() => {
		if (reviseCoverageState.apiState === 'success') {
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setLastAnalysisData(reviseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});
			console.log('success!!!!!!!!!!!!!!!!!!!!');
			setAnalysisCompleted('success');

			// setLoading(false);
			if (
				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				reviseCoverageState.data.is_fields_missing &&
				reviseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (reviseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						reviseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						reviseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						reviseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
				setIsMissingFieldsThere(false);
			}
			reviseCoverageReset();
		}
		if (reviseCoverageState.apiState === 'error') {
			// setShowClinicalTextArea(false);
			// setLoading(false);
			setAnalysisCompleted('error');
			reviseCoverageReset();
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [reviseCoverageState]);
	console.log('loading_______________', loading);

	useEffect(() => {
		if (sendFeedbackState.apiState === 'success') {
			// setThanksModal(true);
			sendFeedbackReset();
			if (sendFeedbackState.data === 'negative')
				setThanksModal([true, 'Thanks for making us Better!']);
			else
				setThanksModal([true, 'Case has been analysed and confirmed!']);
			if (callGetAnalysisAPiBtn) {
				// setLoading(true);
				getAnalysis({ feedback_id: feedbackId });
				setCallGetAnalysisAPiBtn(true);
			} else {
				setCoverageLoading(false);
				setLooksGoodLoading(false);
				setModalOpen([false, '', '']);
			}
		} else if (sendFeedbackState.apiState === 'error') {
			// notification.error({ message: sendFeedbackState.message });
			// setModalOpen([false, '', '']);
			sendFeedbackReset();
			setCallGetAnalysisAPiBtn(true);
			setCoverageLoading(false);
			setLooksGoodLoading(false);
		}
	}, [sendFeedbackState]);

	useEffect(() => {
		document.getElementById('getQust').classList.remove('bottom-100');
		document.getElementById('getAns').classList.remove('top-0');
		document.getElementById('getAns').classList.add('top-100');
		document.getElementById('getAns')?.classList.add('d-none');
		getStateDropdown();
		summaryForm.setFieldsValue({
			clinical_category: 'clinical_text',
		});
	}, []);

	return (
		<>
			{/* <XContainer>
				<div className="marginTopMobile "></div>
				<Affix offsetTop={10} id="notification" className="d-none">
					<div className="marginBottomMobile"></div>
					<div
						className="mb-4"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1110',
						}}
					>
						<XNotificationBar>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								className="refreshBtn"
								onClick={() => {
									setLoading(true);
									document
										.getElementById('notification')
										.classList.add('d-none');
									reviseCoverage({
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										last_analysis_data: lastAnalysisData,
										state: state,
										provider: provider,
										analysis_id: id,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar type="button" className="closeBar">
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</Affix>
			</XContainer> */}
			<XContainer fluid>
				<div className="marginTopMobile "></div>
				<Affix id="notification" className="d-none" offsetTop={10}>
					<div className="marginBottomMobile"></div>
					<div
						className="mb-3"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1111110',
						}}
					>
						<XNotificationBar style={{ zIndex: '22222' }}>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								style={{ padding: '18px' }}
								className="refreshBtn"
								onClick={() => {
									setLoading(true);
									// closeIcdCptDrawer();
									closeIcdCptDrawer2();
									document
										.getElementById('notification')
										?.classList.add('d-none');

									reviseCoverageReset();
									// Update lastAnalysisData with the latest pageContent values
									const updatedLastAnalysisData = {
										...lastAnalysisData, // Keep all the existing keys and values intact
										'Past History': pageContent.pastHistory, // Update pastHistory
										'Present Complaint':
											pageContent.presentComplaint, // Update presentComplaint
										'Suggested Treatment':
											pageContent.recommendedProcedure, // Update recommendedProcedure
										'Medicare Evaluation': {
											...lastAnalysisData[
												'Medicare Evaluation'
											],
											Coverage:
												pageContent.medicalCoverage[0], // Update medicalCoverage fields
											Rationale:
												pageContent.medicalCoverage[1],
											reference:
												pageContent.medicalCoverage[2],
										},
										original_query:
											pageContent.originalQuery, // Update original_query
										past_history: pageContent.pastHistory, // Update past_history in lastAnalysisData
										present_complaint:
											pageContent.presentComplaint, // Update present_complaint in lastAnalysisData
										status: pageContent.status, // Update status in lastAnalysisData
										missing_points_for_coverage:
											pageContent.missing_points_for_coverage, // Update missing_points_for_coverage
										// Add other updates if necessary, or keep them unchanged
									};
									reviseCoverage({
										// case_id: patientData._id,
										analysis_id: id,
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										medicalCoverage:
											pageContent.medicalCoverage,
										state: state,
										provider: provider,
										last_analysis_data:
											updatedLastAnalysisData,
										supportingDocsSummary:
											supportingDocsSummary,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar
								type="button"
								className="closeBar mt-3"
							>
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											?.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</Affix>
			</XContainer>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Analyse Coverage" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="analyseCoverage" />
				</Col>
			</>

			<XContainer className="slideBox" fluid>
				{/* Old Lottie */}
				{/* {loading && (
					<XLottieOverlay>
						<XLottie
							animationData={ReloadingAnimation}
							loop={true}
						/>
					</XLottieOverlay>
				)} */}

				{loading && (
					<AnalysisLoader
						analysisId={id}
						isOpenAnalysis={'true'}
						analysisCompleted={analysisCompleted}
						setAnalysisCompleted={setAnalysisCompleted}
						setLoading={setLoading}
						loading={loading}
						isMissingFieldsThere={isMissingFieldsThere}
						setIsMissingFieldsThere={setIsMissingFieldsThere}
					/>
				)}

				<div id="getQust" className="">
					<Row>
						<Col
							xs={{ span: 24, offset: 0 }}
							lg={{ span: 6, offset: 9 }}
							className="text-center"
						>
							<XLottieMargin>
								<XLottie
									animationData={LoginAnimation}
									loop={true}
								/>
							</XLottieMargin>
						</Col>
					</Row>
					<XForm
						form={summaryForm}
						name="loginForm"
						layout="vertical"
						autoComplete="off"
						onFinish={handleShazamClick}
					>
						<Row gutter={[30, 15]}>
							<Col
								xs={{ span: 24, offset: 0 }}
								lg={{ span: 8, offset: 8 }}
							>
								<Title>DeCodeX</Title>
							</Col>
							<Col
								xs={{ span: 24, offset: 0 }}
								lg={{ span: 20, offset: 2 }}
								className="my-4"
							>
								<XPara1 className="text-center">
									DeCodeX utilizes comprehensive standardized
									clinical notes to generate recommended
									best-fit diagnostic ICD-10, procedural
									CPT/HCPCS or drug NDC codes. It also
									provides insights into Medicare coverage.
								</XPara1>
							</Col>
							{/* start */}
							{/* Clinical Summary */}
							<Col xs={24}>
								<Row gutter={[15, 15]}>
									<Col xs={24}>
										<XForm.Item
											className="addCaseLabel mb-1"
											name="clinical_category"
											// value={formData?.clinical_category}
											// label="Clinical Summary"
											rules={[
												{
													required: true,
													message:
														'Please select option!',
												},
											]}
										>
											<XRadio.Group
												defaultValue={
													clinicalSelectedOption
												}
												onChange={(e) => {
													handleInputChange(
														e,
														formData,
														setFormData,
													);
													if (
														e.target.value ===
														'clinical_text'
													) {
														setClinicalSelectedOption(
															'clinical_text',
														);
													} else if (
														e.target.value ===
														'clinical_document'
													) {
														setClinicalSelectedOption(
															'clinical_document',
														);
													}
												}}
												className=""
											>
												<XRadio
													className="pb-2"
													value={'clinical_text'}
												>
													Enter Clinical Summary
												</XRadio>
												<XRadio
													className="pb-2"
													value={'clinical_document'}
												>
													Upload Clinical Summary
												</XRadio>
											</XRadio.Group>
										</XForm.Item>
									</Col>

									{clinicalSelectedOption ===
										'clinical_text' && (
										<Col xs={24}>
											<XForm.Item
												className="addCaseLabel mb-0"
												name="clinical_summary"
												rules={[
													{
														required: true,
														message:
															'Please enter clinical summary',
													},
												]}
											>
												<TextArea
													className="inputBg"
													name="clinical_summary"
													showCount
													style={{
														marginBottom: 24,
													}}
													onChange={(e) => {
														handleInputChange(
															e,
															formData,
															setFormData,
														);
														setSummary(
															e.target.value,
														);
														setClinicalDocumentFileList(
															[],
														);
														setUpdatedClinicalDocumentFileList(
															[],
														);
													}}
													autoSize={{
														minRows: 5,
													}}
													placeholder="Enter Detailed Clinical Summary"
												/>
											</XForm.Item>
										</Col>
									)}

									{clinicalSelectedOption ===
										'clinical_document' && (
										<Col xs={24} md={24} lg={24}>
											<>
												<XForm.Item
													className="addCaseLabe"
													name="clinical_document"
													rules={[
														{
															required: true,
															validator: (
																_,
																value,
															) => {
																if (
																	clinicalDocumentFileList.length ===
																	0
																) {
																	return Promise.reject(
																		new Error(
																			'Please upload Clinical Document!',
																		),
																	);
																}
																return Promise.resolve();
															},
														},
													]}
												>
													<XuploadAddCase
														action={null}
														multiple={false}
														maxCount={1}
														showUploadList={{
															showPreviewIcon: true,
															showRemoveIcon: true,
														}}
														className="supporting_files"
														listType="picture-card"
														fileList={
															clinicalDocumentFileList
														}
														onPreview={
															handlePreviewClinicalSummary
														}
														onChange={
															handleClinicalDocumentChange
														}
														onRemove={(file) => {
															handleRemoveClinicalDocuments(
																file,
															);
															return false; // Prevent default removal behavior to avoid triggering onChange
														}}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
														// onDownload={
														// 	handleDownload
														// }
													>
														{clinicalDocumentFileList.length >=
														1
															? null
															: uploadButton}
													</XuploadAddCase>
												</XForm.Item>
											</>
										</Col>
									)}
								</Row>
							</Col>
							{/* Clinical Summary Ends */}
							{/* Supporting Docs new column start */}
							<Col xs={24}>
								<XForm.Item
									className="addCaseLabel"
									label="Upload supporting documents (Optional)"
								>
									<Space
										size={[15, 15]}
										style={{
											flexWrap: 'wrap',
											alignItems: 'start',
										}}
									>
										{supportingFiles.length > 0 &&
											supportingFiles.map((ele) => {
												return (
													<>
														{(
															ele.url
																? ele.url.name
																		.split(
																			'.',
																		)
																		.includes(
																			'pdf',
																		)
																: ele.originFileObj.name
																		.split(
																			'.',
																		)
																		.includes(
																			'pdf',
																		)
														) ? (
															<>
																<div className="image-container_case_filing">
																	<Card
																		hoverable
																		style={{
																			// display:
																			// 	'flex',
																			justifyContent:
																				'center',
																			alignItems:
																				'center',
																			borderRadius:
																				'6px',
																			border: '1px dashed #d9d9d9',
																			display:
																				'block',
																			height: '100px',
																			padding:
																				'4px 15px',
																			width: '100px',
																			background:
																				'rgba(0, 0, 0, 0.02)',
																			// margin: '20px',
																		}}
																		onClick={() =>
																			handlePreview(
																				ele.url,
																			)
																		}
																		id="timeline-documents-hover"
																	>
																		<div
																			style={{
																				display:
																					'flex',
																				justifyContent:
																					'center',
																				alignItems:
																					'center',
																				flexDirection:
																					'column',
																				textAlign:
																					'center',
																			}}
																		>
																			<FilePdfOutlined
																				style={{
																					fontSize:
																						'30px',
																					border: 'none',
																					background:
																						'transparent',
																				}}
																			/>
																		</div>
																	</Card>
																	<div
																		className="overlay_case_filing"
																		style={{
																			height: '100px',
																		}}
																	>
																		<EyeOutlined
																			onClick={() =>
																				handlePreview(
																					ele.url,
																				)
																			}
																			className="icon eye-icon"
																		/>
																		<DeleteOutlined
																			onClick={() =>
																				handleRemoveFile(
																					ele,
																				)
																			}
																			className="icon download-icon"
																		/>
																	</div>
																	<Tooltip
																		title={
																			ele.document_name
																		}
																	>
																		<div
																			style={{
																				textAlign:
																					'center',
																				marginTop:
																					'5px',
																				fontSize:
																					'12px',
																				color: '#000',
																				whiteSpace:
																					'nowrap',
																				overflow:
																					'hidden',
																				textOverflow:
																					'ellipsis',
																				width: '100px', // or any other width as per requirement
																			}}
																		>
																			{
																				ele.document_name
																			}
																		</div>
																	</Tooltip>
																</div>
															</>
														) : (
															<>
																<div className="image-container_case_filing">
																	<Image
																		width={
																			100
																		}
																		height={
																			100
																		}
																		style={{
																			borderRadius:
																				'10px',
																		}}
																		className="supporting-docs-hover"
																		src={`${ele.preview}`}
																		onClick={() =>
																			handlePreview(
																				ele.url,
																			)
																		}
																	/>
																	<div
																		className="overlay_case_filing"
																		style={{
																			height: '100px',
																		}}
																	>
																		<EyeOutlined
																			onClick={() =>
																				handlePreview(
																					ele.url,
																				)
																			}
																			className="icon eye-icon"
																		/>
																		<DeleteOutlined
																			onClick={() =>
																				handleRemoveFile(
																					ele,
																				)
																			}
																			className="icon download-icon"
																		/>
																	</div>
																	<Tooltip
																		title={
																			ele.document_name
																		}
																	>
																		<div
																			style={{
																				textAlign:
																					'center',
																				marginTop:
																					'5px',
																				fontSize:
																					'12px',
																				color: '#000',
																				whiteSpace:
																					'nowrap',
																				overflow:
																					'hidden',
																				textOverflow:
																					'ellipsis',
																				width: '100px', // or any other width as per requirement
																			}}
																		>
																			{
																				ele.document_name
																			}
																		</div>
																	</Tooltip>
																</div>
															</>
														)}
													</>
												);
											})}
										{supportingFiles.length > 25 ? null : (
											<>
												{checkModulePermissions(
													'ma-plan-appeals-processing',
												).sub_modules.includes(
													'upload-supporting-documents',
												) ||
												checkModulePermissions(
													'case-filing',
												).sub_modules.includes(
													'upload-supporting-documents',
												) ? (
													uploadSupportingDocButton
												) : (
													<>
														You are not autthorized
														to upload supporitng
														Documents
													</>
												)}
											</>
										)}
									</Space>
								</XForm.Item>
							</Col>
							{/* Supporting Docs new column end */}

							{/* end */}
							{/* <Col xs={{ span: 24 }}>
								<XForm.Item
									name="summary"
									rules={[
										{
											required: true,
											message: 'Please input summary!',
										},
									]}
									className="mb-1"
								>
									<TextArea
										name="summery"
										showCount
										style={{
											marginBottom: 24,
										}}
										autoSize={{
											minRows: 5,
										}}
										className="textArea mt-2"
										placeholder="Enter Clinical Summary"
										onChange={(e) =>
											setSummary(e.target.value)
										}
									/>
								</XForm.Item>
							</Col> */}
							<Col xs={{ span: 24 }} lg={{ span: 12 }}>
								<XForm.Item
									name="state"
									label="State"
									rules={[
										{
											required: true,
											message: 'Please select State',
										},
									]}
								>
									<XSelect
										size="large"
										placeholder="Select State"
										name="state"
										showSearch={true}
										// onFocus={() => {
										// 	 getStateDropdown();
										// }}
										onSearch={(search) => {
											if (search.length >= 3) {
												getStateDropdown({
													search,
												});
											} else if (search.length === 0) {
												getStateDropdown();
											}
										}}
										filterOption={filterByLabel}
										options={
											getStateDropdownState.apiState ===
												'success' &&
											getStateDropdownState.data
												.all_states?.length > 0 &&
											getStateDropdownState.data.all_states.map(
												(itm) => {
													return {
														label: itm.state,
														value: itm.state,
														providers:
															itm.providers,
													};
												},
											)
										}
										onSelect={(value, options) => {
											setState(value);
											summaryForm.setFieldValue(
												'insurance_company',
												null,
											);
											setInsuranceCompanies(
												options.providers,
											);
										}}
									/>
								</XForm.Item>
							</Col>
							<Col xs={{ span: 24 }} lg={{ span: 12 }}>
								<XForm.Item
									name="insurance_company"
									label="Insurance Company"
								>
									<XSelect
										size="large"
										placeholder="Select Insurance Company"
										name="insurance_company"
										showSearch={true}
										filterOption={filterByLabel}
										options={
											insuranceCompanies.length > 0 &&
											insuranceCompanies.map((itm) => {
												return {
													label: itm.name,
													value: itm.name,
												};
											})
										}
										onSelect={(value) => {
											setProvider(value);
										}}
									/>
								</XForm.Item>
							</Col>
							<Col xs={24} className="text-center mt-3">
								<Button htmlType="submit" type="primary">
									Shazaam !!
								</Button>
							</Col>
						</Row>
					</XForm>
				</div>
				<div id="getAns" className="slideUp top-100 mt-4 d-none">
					<Row gutter={(0, 30)}>
						<Col xs={24} sm={24} md={24} lg={14} xl={16}>
							{/* <XCardsGrdBG>
						<Row className="align-items-center">
							<Col xs={20} lg={22}>
								<XPara1>{pageContent.originalQuery}</XPara1>
							</Col>
							<Col xs={4} lg={2} className="text-end">
								<Button
									shape="circle"
									type="button"
									onClick={() => {
										document
											.getElementById('getAns')
											.classList.remove('top-0');
										document
											.getElementById('getAns')
											.classList.add('top-100');

										document
											.getElementById('notification')
											.classList.add('d-none');
										document
											.getElementById('getAns')
											.classList.add('d-none');
										document
											.getElementById('getQust')
											.classList.add('slideDown');

										document
											.getElementById('getQust')
											.classList.remove('bottom-100');
										document
											.getElementById('getQust')
											.classList.add('bottom-0');
									}}
								>
									<img
										className="img-fluid"
										src="/images/reload-icon.svg"
										alt=""
									/>
								</Button>
							</Col>
						</Row>
					</XCardsGrdBG> */}
							{/* new component start */}
							<CaseOperations
								aipa={false}
								previewMode={false}
								matchGender={''}
								loading={loading}
								summary={summary}
								state={state}
								provider={provider}
								setSummary={setSummary}
								handleRemoveClinicalDocuments={
									handleRemoveClinicalDocuments
								}
								handleClinicalSummaryDoc={
									handleClinicalSummaryDoc
								}
								isOpenAnalysis={'true'}
								patientData={''}
								mainDataLoading={''}
								handleClinicalDocsChange={
									handleClinicalDocsChange
								}
								clinicalDocumentFileList={
									clinicalDocumentFileList
								}
								clinicalDocsForm={clinicalDocsForm}
								handleUploadCancel={handleUploadCancel}
								clinicalDocsLoading={clinicalDocsLoading}
								setIsClinicalUploadModalOpen={
									setIsClinicalUploadModalOpen
								}
								isClinicalUploadModalOpen={
									isClinicalUploadModalOpen
								}
								setPreviewImage={setPreviewImage}
								previewImage={previewImage}
								setPreviewTitle={setPreviewTitle}
								previewTitle={previewTitle}
								setPdfPreviewOpen={setPdfPreviewOpen}
								pdfPreviewOpen={pdfPreviewOpen}
								documentsToDisplay={supportingFiles}
								supportingDocsSummary={supportingDocsSummary}
								clinicalSummaryTextLoading={
									clinicalSummaryTextLoading
								}
								setClinicalSummaryTextLoading={
									setClinicalSummaryTextLoading
								}
								setShowClinicalTextArea={
									setShowClinicalTextArea
								}
								showClinicalTextArea={showClinicalTextArea}
								setFormData={setFormData}
								formData={formData}
								id={id}
								// editCase={editCase}
								setPageContent={setPageContent}
								pageContent={pageContent}
								clinicalDocumentsToDisplay={
									clinicalDocumentsToDisplay
								}
								feedbackId={feedbackId}
								reviseCoverage={reviseCoverage}
								lastAnalysisData={lastAnalysisData}
								// closeIcdCptDrawer2={closeIcdCptDrawer2}
								// closeIcdCptDrawer={closeIcdCptDrawer}
								setLoading={setLoading}
								getBase64={getBase64}
								setClinicalDocsLoading={setClinicalDocsLoading}
								setSubmitClinicalDocs={setSubmitClinicalDocs}
								setUpdatedClinicalDocumentFileList={''}
								form={form}
								setModalTextArea={setModalTextArea}
								setModalOpen={setModalOpen}
								handleCodeClick={handleCodeClick}
								modalOpen={modalOpen}
								sendFeedback={sendFeedback}
								sendFeedbackState={sendFeedbackState}
								modalTextArea={modalTextArea}
								updatePageContent={updatePageContent}
								missingFieldsForm={missingFieldsForm}
								missingFieldsCardRef={missingFieldsCardRef}
								handleAddOption={handleAddOption}
								missingFields={missingFields}
								missingFieldInstructions={
									missingFieldInstructions
								}
								setMissingFieldsModal={setMissingFieldsModal}
								setMissingFieldInstructions={
									setMissingFieldInstructions
								}
								setMissingFields={setMissingFields}
								missingFieldTitle={missingFieldTitle}
								missingFieldsModal={missingFieldsModal}
								// LooksGoodLoading={LooksGoodLoading}
								// coverageloading={coverageloading}
								// setCoverageLoading={
								// 	setCoverageLoading
								// }
								// setLooksGoodLoading={
								// 	setLooksGoodLoading
								// }
							/>
							{/* new component end */}

							{/* Past Clinical History */}
							{/* <XCardsCaseBg className="mt-4">
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/past-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color1 d-none d-sm-block">
											Past Clinical History
										</span>

										<span className="color1 d-block d-sm-none text-start">
											Past Clinical <br /> History
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
									onClick={() => {
										form.setFieldsValue({
											modal_text_area: '',
										});
										setModalTextArea('');
										setModalOpen([
											true,
											'Add Past Clinical History',
											'',
											null,
											'pastHistory',
										]);
									}}
								></XButtonAdd>
							</Col>
						</Row>
						{pageContent.pastHistory.length > 0 &&
							pageContent.pastHistory.map((row, index) => {
								const splitPastHistory =
									row.split('- ICD-10 Code:');
								return (
									<Row
										gutter={(15, 15)}
										className="mt-3 align-items-center"
									>
										<Col xs={24} lg={20}>
											{splitPastHistory.length > 1 ? (
												<XPara1
													style={{
														fontStyle: 'italic',
													}}
												>
													{splitPastHistory[0]}
													<span
														style={{
															padding: '3px',
															background:
																'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
														}}
													>
														ICD-10:
														{splitPastHistory[1]}
													</span>
												</XPara1>
											) : (
												<XPara1
													style={{
														fontStyle: 'italic',
													}}
												>
													{row}
												</XPara1>
											)}
										</Col>
										<Col
											xs={24}
											lg={4}
											className="text-end"
										>
											<XButtonImg
												type="text"
												onClick={() => {
													form.setFieldsValue({
														modal_text_area: row,
													});
													setModalTextArea(row);
													setModalOpen([
														true,
														`Edit Past Clinical History`,
														row,
														index,
														'pastHistory',
													]);
												}}
											>
												<EditOutlined />
											</XButtonImg>
											<XButtonImg
												type="text"
												onClick={() => {
													showDeleteConfirm(
														'Past Clinical History',
														index,
														row,
														'pastHistory',
													);
												}}
											>
												<DeleteOutlined />
											</XButtonImg>
										</Col>
									</Row>
								);
							})}
						<XDivider dashed="true" />
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/present-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color2 d-none d-sm-block">
											Present Complaint
										</span>

										<span className="color2 d-block d-sm-none text-start">
											Present <br /> Complaint
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
									onClick={() => {
										form.setFieldsValue({
											modal_text_area: '',
										});
										setModalTextArea('');
										setModalOpen([
											true,
											'Add Present Complaint',
											'',
											null,
											'presentComplaint',
										]);
									}}
								></XButtonAdd>
							</Col>
						</Row>
						{pageContent.presentComplaint.length > 0 &&
							pageContent.presentComplaint.map((row, index) => {
								const splitPresentComplaint =
									row.split('- ICD-10 Code:');

								return (
									<Row
										gutter={(15, 15)}
										className="mt-3 align-items-center"
									>
										<Col xs={24} lg={20}>
											{splitPresentComplaint.length >
											1 ? (
												<XPara1
													style={{
														fontStyle: 'italic',
													}}
												>
													{splitPresentComplaint[0]}
													<span
														style={{
															padding: '3px',
															background:
																'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
														}}
													>
														ICD-10:
														{
															splitPresentComplaint[1]
														}
													</span>
												</XPara1>
											) : (
												<XPara1
													style={{
														fontStyle: 'italic',
													}}
												>
													{row}
												</XPara1>
											)}{' '}
										</Col>
										<Col
											xs={24}
											lg={4}
											className="text-end"
										>
											<XButtonImg
												type="text"
												onClick={() => {
													form.setFieldsValue({
														modal_text_area: row,
													});
													setModalTextArea(row);
													setModalOpen([
														true,
														`Edit Present Complaint`,
														row,
														index,
														'presentComplaint',
													]);
												}}
											>
												<EditOutlined />
											</XButtonImg>
											<XButtonImg
												type="text"
												onClick={() => {
													showDeleteConfirm(
														'Present Complaint',
														index,
														row,
														'presentComplaint',
													);
												}}
											>
												<DeleteOutlined />
											</XButtonImg>
										</Col>
									</Row>
								);
							})}
						<XDivider dashed="true" />
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/procedure-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color3 d-none d-sm-block">
											Denial Procedure
										</span>

										<span className="color3 d-block d-sm-none text-start">
											Denial <br /> Procedure
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
									onClick={() => {
										form.setFieldsValue({
											modal_text_area: '',
										});
										setModalTextArea('');
										setModalOpen([
											true,
											'Add Denial Procedure',
											'',
											null,
											'recommendedProcedure',
										]);
									}}
								></XButtonAdd>
							</Col>
						</Row>
						{pageContent.recommendedProcedure.length > 0 &&
							pageContent.recommendedProcedure.map(
								(row, index) => {
									const splitRecommendedProcedure =
										row.split('- CPT Code:');
									return (
										<Row
											gutter={(15, 15)}
											className="mt-3 align-items-center"
										>
											<Col xs={24} lg={20}>
												{splitRecommendedProcedure.length >
												1 ? (
													<XPara1
														style={{
															fontStyle: 'italic',
														}}
													>
														{
															splitRecommendedProcedure[0]
														}
														<span
															style={{
																padding: '3px',
																background:
																	'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
															}}
														>
															CPT:
															{
																splitRecommendedProcedure[1]
															}
														</span>
													</XPara1>
												) : (
													<XPara1
														style={{
															fontStyle: 'italic',
														}}
													>
														{row}
													</XPara1>
												)}{' '}
											</Col>
											<Col
												xs={24}
												lg={4}
												className="text-end"
											>
												<XButtonImg
													type="text"
													onClick={() => {
														form.setFieldsValue({
															modal_text_area:
																row,
														});
														setModalTextArea(row);
														setModalOpen([
															true,
															`Edit Denial Procedure`,
															row,
															index,
															'recommendedProcedure',
														]);
													}}
												>
													<EditOutlined />
												</XButtonImg>
												<XButtonImg
													type="text"
													onClick={() => {
														showDeleteConfirm(
															'Denial Procedure',
															index,
															row,
															'recommendedProcedure',
														);
													}}
												>
													<DeleteOutlined />
												</XButtonImg>
											</Col>
										</Row>
									);
								},
							)}
						<XDivider dashed="true" />
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={24}>
								<Space align="center">
									<img
										className="iconResp"
										src={
											pageContent.medicalCoverage[0] ===
											'Yes'
												? '/images/coverage-icon.svg'
												: '/images/cross-icon.svg'
										}
										alt=""
									/>
									<XTitleHead>
										<span
											className={
												pageContent
													.medicalCoverage[0] ===
												'Yes'
													? 'color4'
													: 'color5'
											}
										>
											Medicare Coverage
										</span>
									</XTitleHead>
								</Space>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24}>
								<XPara1>
									{pageContent.medicalCoverage[1]}
									{pageContent.medicalCoverage[2] &&
									Object.keys(pageContent.medicalCoverage[2])
										.length > 0 ? (
										<>
											<br />
											<br />
											<i>
												Reference:{' '}
												<a
													href={
														pageContent
															.medicalCoverage[2][
															'target'
														]
													}
													rel="noreferrer"
													target="_blank"
												>
													{
														pageContent
															.medicalCoverage[2][
															'display_text'
														]
													}
												</a>
											</i>
										</>
									) : (
										''
									)}
								</XPara1>
							</Col>
						</Row>
					</XCardsCaseBg> */}
							{/* {pageContent.missing_points_for_coverage &&
						pageContent.missing_points_for_coverage.length > 0 && (
							<XCardsCriteriaBg className="mt-4">
								<div className="criteria_list">
									<Col xs={24}>
										<Space align="center">
											<img
												src={'/images/criteria.svg'}
												alt=""
											/>
											<XTitleHead>
												<span className="criteria_list_title">
													Missing Inclusion Criteria
												</span>
												&nbsp;
												<Tooltip title="This feature is experimental and may contain errors as it evolves. Please exercise discretion, especially when reviewing critical information.">
													<Tag
														style={{
															border: '1px solid #9B68BF',
															color: '#9B68BF',
															fontSize: '11px',
														}}
													>
														Alpha
													</Tag>
												</Tooltip>
											</XTitleHead>
										</Space>
									</Col>
								</div>

								<Row
									gutter={(15, 15)}
									className="mt-3 align-items-center criteria_sub_list"
								>
									<Col xs={24}>
										<ul className="listCircule">
											{pageContent.missing_points_for_coverage?.map(
												(row, index) => {
													return <li>{row}</li>;
												},
											)}
										</ul>
									</Col>
								</Row>
							</XCardsCriteriaBg>
						)} */}

							{checkModulePermissions(
								'ma-plan-appeals-processing',
							).sub_modules.includes('update-coverage') ||
							checkModulePermissions(
								'case-filing',
							).sub_modules.includes('update-coverage') ? (
								!missingFieldsModal && (
									<>
										<div className="showMobile">
											<Row
												gutter={(15, 15)}
												className="mt-4"
											>
												<Col xs={12}>
													<XButtonConfirm
														className="w-100"
														type="primary"
														size="large"
														shape="round"
														style={{
															background: '#fff',
														}}
														icon={
															<img
																className="rotateButton"
																src="/images/like-icon.svg"
																alt=""
															/>
														}
														onClick={() => {
															form.setFieldsValue(
																{
																	modal_text_area:
																		pageContent.medicalCoverage
																			? pageContent
																					.medicalCoverage[1]
																			: '',
																	medicare_flag:
																		pageContent.medicalCoverage
																			? pageContent
																					.medicalCoverage[0]
																			: '',
																},
															);
															setModalTextArea(
																pageContent
																	.medicalCoverage[1],
															);
															setModalOpen([
																true,
																`Edit Medicare Coverage`,
																pageContent
																	.medicalCoverage[1],
																1,
																'feedbackSummary',
															]);
															setCallGetAnalysisAPiBtn(
																true,
															);
														}}
													>
														Refine
													</XButtonConfirm>
												</Col>
												<Col xs={12}>
													<XButtonConfirm
														className="w-100"
														type="primary"
														size="large"
														shape="round"
														style={{
															background: '#fff',
														}}
														icon={
															<img
																src="/images/like-icon.svg"
																alt=""
															/>
														}
														loading={
															LooksGoodLoading
														}
														onClick={() => {
															setLooksGoodLoading(
																true,
															);
															setCallGetAnalysisAPiBtn(
																true,
															);
															sendFeedback({
																feedback_id:
																	feedbackId,
																feedback:
																	'positive',
																status: 'approved',
																response:
																	pageContent.medicalCoverage,
															});
														}}
													>
														Looks Good
													</XButtonConfirm>
												</Col>
												<Col xs={24} className="mt-4">
													<XButtonConfirm
														className="btnActive w-100"
														loading={
															coverageloading
														}
														onClick={() => {
															setCoverageLoading(
																true,
															);
															setCallGetAnalysisAPiBtn(
																true,
															);

															sendFeedback({
																feedback_id:
																	feedbackId,
																status: 'approved',
																response:
																	pageContent.medicalCoverage,
															});
														}}
														disabled={
															pageContent.status ===
															'approved'
																? true
																: false
														}
														type="primary"
														shape="round"
														// icon={
														// 	<img
														// 		src="/images/check 1.svg"
														// 		alt=""
														// 	/>
														// }
														size="large"
													>
														Confirm Coverage
													</XButtonConfirm>
												</Col>
											</Row>
										</div>
										<div className="showDesktop">
											<Row className="text-end">
												<Col
													xs={24}
													lg={12}
													className="mt-4"
												>
													<Space
														style={{
															width: '100%',
														}}
													>
														<XButtonConfirm
															className="mb-2 mb-md-0 mb-sm-0"
															type="primary"
															size="large"
															shape="round"
															style={{
																background:
																	'#fff',
															}}
															icon={
																<img
																	className="rotateButton"
																	src="/images/like-icon.svg"
																	alt=""
																/>
															}
															onClick={() => {
																form.setFieldsValue(
																	{
																		modal_text_area:
																			pageContent.medicalCoverage
																				? pageContent
																						.medicalCoverage[1]
																				: '',
																		medicare_flag:
																			pageContent.medicalCoverage
																				? pageContent
																						.medicalCoverage[0]
																				: '',
																	},
																);
																setModalTextArea(
																	pageContent
																		.medicalCoverage[1],
																);
																setModalOpen([
																	true,
																	`Edit Medicare Coverage`,
																	pageContent
																		.medicalCoverage[1],
																	1,
																	'feedbackSummary',
																]);
																setCallGetAnalysisAPiBtn(
																	true,
																);
															}}
														>
															Refine
														</XButtonConfirm>
														<XButtonConfirm
															className="mb-2 mb-md-0 mb-sm-0"
															type="primary"
															size="large"
															shape="round"
															style={{
																background:
																	'#fff',
															}}
															icon={
																<img
																	src="/images/like-icon.svg"
																	alt=""
																/>
															}
															loading={
																LooksGoodLoading
															}
															onClick={() => {
																setLooksGoodLoading(
																	true,
																);
																setCallGetAnalysisAPiBtn(
																	true,
																);
																sendFeedback({
																	feedback_id:
																		feedbackId,
																	feedback:
																		'positive',
																	status: 'approved',
																	response:
																		pageContent.medicalCoverage,
																});
															}}
														>
															Looks Good
														</XButtonConfirm>
													</Space>
												</Col>
												<Col
													xs={24}
													lg={12}
													className="mt-4 text-md-end"
												>
													<XButtonConfirm
														loading={
															coverageloading
														}
														onClick={() => {
															setCoverageLoading(
																true,
															);
															setCallGetAnalysisAPiBtn(
																true,
															);

															sendFeedback({
																feedback_id:
																	feedbackId,
																status: 'approved',
																response:
																	pageContent.medicalCoverage,
															});
														}}
														disabled={
															pageContent.status ===
															'approved'
																? true
																: false
														}
														type="primary"
														shape="round"
														// icon={
														// 	<img
														// 		src="/images/check 1.svg"
														// 		alt=""
														// 	/>
														// }
														size="large"
														className="btnActive"
													>
														Confirm Coverage
													</XButtonConfirm>
												</Col>
											</Row>
										</div>
									</>
								)
							) : (
								<></>
							)}

							{/* <Row className=" text-end">
						<Col xs={24} className="mt-4">
							<Space>
								<XButtonLike
									type="primary"
									shape="round"
									icon={
										<img
											className="rotateButton"
											src="/images/like-icon.svg"
											alt=""
										/>
									}
									size="large"
									onClick={() => {
										form.setFieldsValue({
											modal_text_area:
												pageContent.medicalCoverage
													? pageContent
															.medicalCoverage[1]
													: '',
											medicare_flag:
												pageContent.medicalCoverage
													? pageContent
															.medicalCoverage[0]
													: '',
										});
										setModalTextArea(
											pageContent.medicalCoverage[1],
										);
										setModalOpen([
											true,
											`Edit Medicare Coverage`,
											pageContent.medicalCoverage[1],
											1,
											'feedbackSummary',
										]);
										setCallGetAnalysisAPiBtn(true);
									}}
								>
									Refine
								</XButtonLike>
								<XButtonLike
									onClick={() => {
										sendFeedback({
											feedback_id: feedbackId,
											feedback: 'positive',
											status: 'approved',
											response:
												pageContent.medicalCoverage,
										});

										setCallGetAnalysisAPiBtn(false);
									}}
									type="primary"
									shape="round"
									icon={
										<img
											src="/images/like-icon.svg"
											alt=""
										/>
									}
									size="large"
								>
									Looks Good
								</XButtonLike>
							</Space>
						</Col>
					</Row> */}
						</Col>
						{/* // todo coding tools */}
						<Col
							xs={24}
							sm={24}
							md={24}
							lg={10}
							xl={8}
							className="mt-4 mt-lg-0"
						>
							<DrawerCodingTools
								previewMode={false}
								fixed={isFixed}
								icdform={icdform}
								cptform={cptform}
								activeCode={activeCode}
								setActiveCode={setActiveCode}
								closeIcdCptDrawer={closeIcdCptDrawer}
								openIcdCptDrawer={openIcdCptDrawer}
								handleCodeClick={handleCodeClick}
								setModalOpen={setModalOpen}
								setModalTextArea={setModalTextArea}
								closeIcdCptDrawer2={closeIcdCptDrawer2}
								openIcdCptDrawer2={openIcdCptDrawer2}
								modalOpen={modalOpen}
								updatePageContent={updatePageContent}
								modalTextArea={modalTextArea}
								setIcdCodes={setIcdCodes}
								icdCodes={icdCodes}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								icdLoading={icdLoading}
								setIcdLoading={setIcdLoading}
								triggeredBySearch={triggeredBySearch}
								setTriggeredBySearch={setTriggeredBySearch}
								triggeredByEnter={triggeredByEnter}
								setTriggeredByEnter={setTriggeredByEnter}
								loadMore={loadMore}
								setLoadMore={setLoadMore}
								icdSearchLoading={icdSearchLoading}
								setIcdSearchLoading={setIcdSearchLoading}
								cptCodes={cptCodes}
								setCptCodes={setCptCodes}
								cptLoading={cptLoading}
								setCptLoading={setCptLoading}
								cptSearchResults={cptSearchResults}
								setCptSearchResults={setCptSearchResults}
								cptTriggeredBySearch={cptTriggeredBySearch}
								setCptTriggeredBySearch={
									setCptTriggeredBySearch
								}
								cptTriggeredByEnter={cptTriggeredByEnter}
								setCptTriggeredByEnter={setCptTriggeredByEnter}
								cptLoadMore={cptLoadMore}
								setCptLoadMore={setCptLoadMore}
								cptSearchLoading={cptSearchLoading}
								setCptSearchLoading={setCptSearchLoading}
								icdFilters={icdFilters}
								setIcdFilters={setIcdFilters}
								cptFilters={cptFilters}
								setCptFilters={setCptFilters}
							/>
						</Col>
					</Row>
				</div>
			</XContainer>

			{/* //todo Drawer of the Coding Tools Start */}
			{!isFixed && (
				<XAddCodeSticky type="primary" onClick={showIcdCptDrawer}>
					Coding Tools
				</XAddCodeSticky>
			)}

			{/* <XModal
				title={
					<>
						<EditOutlined />
						<span className="ms-2">{modalOpen[1]}</span>
					</>
				}
				centered
				open={modalOpen[0]}
				onOk={() => {
					form.validateFields()
						.then((res) => {
							if (modalOpen[4] === 'feedbackSummary') {
								sendFeedback({
									feedback_id: feedbackId,
									feedback: 'negative',
									response: [coverage, modalTextArea],
								});
							} else {
								updatePageContent(
									modalOpen[4],
									modalOpen[3],
									modalTextArea,
								);
							}
						})
						.catch((err) => {
							console.log('error', err);
						});
				}}
				onCancel={() => {
					form.resetFields();
					setModalTextArea('');
					setModalOpen([false, '', ' ']);
				}}
				className="modalButton"
				okText="Submit"
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							{modalOpen[4] === 'feedbackSummary' && (
								<XForm.Item
									name="medicare_flag"
									label="Coverage"
									rules={[
										{
											required: true,
											message: 'Field is required',
										},
									]}
								>
									<Select
										showSearch
										placeholder="Select Coverage"
										optionFilterProp="children"
										onChange={(e) => {
											setCoverage(e);
										}}
										options={medicalCoverage}
									/>
								</XForm.Item>
							)}
							<XForm.Item
								name="modal_text_area"
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}
							>
								<TextArea
									name="modal_text_area"
									showCount
									style={{
										height: 120,
										marginBottom: 24,
									}}
									className="textArea mt-2"
									placeholder="Enter"
									onChange={(e) =>
										setModalTextArea(e.target.value)
									}
								>
									
								</TextArea>
							</XForm.Item>
						</Col>
					</Row>
				</XForm>
			</XModal> */}

			<Modal
				title="Upload Supporting Documents"
				open={isUploadModalOpen}
				onOk={handleSupportingDocSubmit}
				onCancel={handleUploadCancel}
			>
				<XForm form={formUpload} layout="vertical" autoComplete="off">
					<XForm.Item
						name="Supporting_docs"
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (supportingDoc.length === 0) {
										return Promise.reject(
											new Error('Please Select file!'),
										);
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Dragger
							fileList={supportingDoc}
							maxCount={1}
							beforeUpload={(file) => {
								const isGIF = file.type === 'image/gif';
								const isVideo = file.type.startsWith('video/');
								const isAudio = file.type.startsWith('audio/');
								const isJPEG = file.type === 'image/jpeg';
								const isJPG = file.type === 'image/jpg';
								const isPNG = file.type === 'image/png';
								const isLt10M = file.size / 1024 / 1024 < 10;

								if (!isLt10M) {
									notification.error({
										message: 'File Size Exceeded',
										description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
									});
								}
								if (
									isGIF ||
									isVideo ||
									isAudio ||
									isJPEG ||
									isJPG ||
									isPNG
								) {
									notification.error({
										message: 'File Type Error',
										description: `GIF, video, image and audio files are not allowed.`,
									});
								}

								return (
									isLt10M &&
									!isGIF &&
									!isVideo &&
									!isAudio &&
									!isJPEG &&
									!isJPG &&
									!isPNG
								);
							}}
							// accept=".pdf,.jpeg,.jpg,.png"
							accept=".pdf"
							onChange={handleSupportingDocChange}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</XForm.Item>
					<XForm.Item
						name="document_name"
						label="Document Name"
						className="mt-3"
						rules={[
							{
								required: true,
								message: 'Document Name is required',
							},
						]}
					>
						<Input
							placeholder="Enter Document Name"
							// value={documentName}
							name="document_name"
							onChange={(e) => {
								setDocumentName(e.target.value);
							}}
						/>
					</XForm.Item>
				</XForm>
			</Modal>

			<XModal
				centered
				open={thanksModal[0]}
				closable={false} // Hide the close icon
				// onOk={() => setThanksModal([false, ''])}
				// onCancel={() => {
				// 	setThanksModal([false, '']);
				// }}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					gutter={(15, 0)}
					className="mt-2"
				>
					<Col xs={24} lg={18}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1
							style={{ textAlign: 'center' }}
							className="mt-0 mb-4 "
						>
							{thanksModal[1]}
						</XPara1>
					</Col>
				</Row>
			</XModal>

			{/* Modal for Missing Fields */}
			<XModal
				title={
					<>
						{/* <EditOutlined /> */}
						<XModalTitle className="ms-2">
							Additional Information Required
						</XModalTitle>
						{missingFieldTitle && (
							<XModalSubtitle className="ms-2 mt-2">
								{missingFieldTitle}
							</XModalSubtitle>
						)}
						<XDivider dashed className="mt-3" />
					</>
				}
				centered
				// open={missingFieldsModal}
				open={''}
				onOk={() => {
					missingFieldsForm
						.validateFields()
						.then((values) => {
							setLoading(true);
							reviseCoverageReset();
							reviseCoverage({
								feedbackId: feedbackId,
								clinicalHistory: pageContent.pastHistory,
								presentComplaint: pageContent.presentComplaint,
								recommendedProcedure:
									pageContent.recommendedProcedure,
								medicalCoverage: pageContent.medicalCoverage,
								state: state,
								provider: provider,
								missing_fields: values,
								last_analysis_data: lastAnalysisData,
								analysis_id: id,
							});

							missingFieldsForm.resetFields();
							setMissingFields([]);
							setMissingFieldInstructions([]);
							setMissingFieldsModal(false);
						})
						.catch((error) => {
							return;
						});
				}}
				onCancel={() => {
					missingFieldsForm.resetFields();
					setMissingFields([]);
					setMissingFieldInstructions([]);
					setMissingFieldsModal(false);
				}}
				className="modalButton"
				width={900}
				okText="Submit"
				style={{
					padding: '40px',
					borderRadius: '5px !important',
					'ant-modal-content': {
						padding: '30px !important',
						borderRadius: '5px !important',
					},
				}}
			>
				<XForm
					form={missingFieldsForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						{missingFieldInstructions.length > 0 && (
							<Col xs={24}>
								<XList
									size="small"
									header={<div>Instructions</div>}
									bordered
									dataSource={missingFieldInstructions}
									renderItem={(item, index) => (
										<List.Item>
											<XCircle>{index + 1}</XCircle>{' '}
											{item}
										</List.Item>
									)}
								/>
								<br />
							</Col>
						)}

						{missingFields.map((item, index) => {
							if (item.type == 'textarea') {
								return (
									<Col xs={24}>
										<XForm.Item
											label={
												<XLabel>{item.label}</XLabel>
											}
											name={item.name}
											rules={[
												item.hasOwnProperty(
													'required',
												) &&
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												item.hasOwnProperty('regex') &&
													item.regex && {
														validator: (
															rule,
															value,
														) => {
															let regex =
																new RegExp(
																	item.regex.slice(
																		1,
																		-1,
																	),
																);
															if (
																!regex.test(
																	value,
																)
															)
																return Promise.reject(
																	new Error(
																		item.regex_error_message ??
																			'Invalid Data',
																	),
																);
															return Promise.resolve();
														},
													},
											]}
										>
											<TextArea
												rows={3}
												name={item.name}
												showCount
												className="textArea"
												placeholder="Enter"
											></TextArea>
										</XForm.Item>
									</Col>
								);
							} else if (item.type == 'input') {
								return (
									<Col xs={24}>
										<XForm.Item
											label={
												<XLabel>{item.label}</XLabel>
											}
											name={item.name}
											rules={[
												item.hasOwnProperty(
													'required',
												) &&
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												item.hasOwnProperty('regex') &&
													item.regex && {
														validator: (
															rule,
															value,
														) => {
															let regex =
																new RegExp(
																	item.regex.slice(
																		1,
																		-1,
																	),
																);
															if (
																!regex.test(
																	value,
																)
															)
																return Promise.reject(
																	new Error(
																		item.regex_error_message ??
																			'Invalid Data',
																	),
																);
															return Promise.resolve();
														},
													},
											]}
										>
											<Input
												size="large"
												name={item.name}
												placeholder="Enter"
											></Input>
										</XForm.Item>
									</Col>
								);
							} else if (item.type == 'checkbox') {
								return (
									<>
										<Col xs={24} key={index}>
											<XForm.Item
												name={item.name}
												rules={[
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												]}
											>
												<XCheckboxGroup
													name={item.name}
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}
												>
													<XList
														className="normal-header"
														size="small"
														bordered
														header={
															<div
																style={{
																	display:
																		'flex',
																	justifyContent:
																		'space-between',
																	alignItems:
																		'center',
																}}
															>
																<XLabel>
																	{item.label}
																</XLabel>
																<XRoundButton
																	shape="circle"
																	icon={
																		<PlusOutlined />
																	}
																	onClick={() =>
																		handleAddOption(
																			index,
																		)
																	}
																/>
															</div>
														}
														dataSource={
															item.options
														}
														renderItem={(
															option,
															idx,
														) => (
															<List.Item>
																<Checkbox
																	value={
																		option.value
																	}
																>
																	{
																		option.label
																	}
																</Checkbox>
															</List.Item>
														)}
													/>
												</XCheckboxGroup>
											</XForm.Item>
										</Col>
									</>
								);
							} else if (item.type == 'radio') {
								return (
									<Col xs={24} key={index}>
										<XForm.Item
											name={item.name}
											rules={[
												item.required && {
													required: true,
													message:
														'Field is required',
												},
											]}
										>
											<Radio.Group
												style={{
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<XList
													className="normal-header"
													size="small"
													bordered
													header={
														<div
															style={{
																display: 'flex',
																justifyContent:
																	'space-between',
																alignItems:
																	'center',
															}}
														>
															<XLabel>
																{item.label}
															</XLabel>
														</div>
													}
													dataSource={item.options}
													renderItem={(
														option,
														idx,
													) => (
														<List.Item>
															<Radio
																value={
																	option.value
																}
															>
																{option.label}
															</Radio>
														</List.Item>
													)}
												/>
											</Radio.Group>
										</XForm.Item>
									</Col>
								);
							}
						})}
					</Row>
				</XForm>
			</XModal>

			{/* Modal for adding new option */}
			<XModal
				title="Add New Option"
				open={newCheckBoxModal[0]}
				onOk={handleNewCheckBoxModal}
				okText="Submit"
				onCancel={() => {
					setNewCheckBoxModal([false, null]);
					setNewOptionText('');
					newOptionForm.setFieldsValue({});
					newOptionForm.resetFields();
				}}
			>
				<XForm
					form={newOptionForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name={'new-option'}
						rules={[
							{
								required: true,
								message: 'Please input option',
							},
						]}
					>
						<Input
							size="large"
							className="mt-3 mb-3"
							value={newOptionText}
							onChange={(e) => setNewOptionText(e.target.value)}
							placeholder="Enter new option text"
						/>
					</XForm.Item>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getStateDropdownState: state.getStateDropdown,
	getAnalysisState: state.getAnalysis,
});

const mapDispatchToProps = (dispatch) => ({
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	getStateDropdown: () => dispatch(getStateDropdownApi()),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseCoverage);
