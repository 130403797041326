import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button,
	Input,
	Space,
	Divider,
	notification,
	Affix,
	Select,
	Result,
	Tooltip,
	Checkbox,
	Radio,
	Tag,
	List,
	Image,
	Form,
	Card,
	Modal,
	Upload,
	Avatar,
	Skeleton,
	Spin,
} from 'antd';
import { XLottie } from '../Login/LoginStyle';
import { Router, useNavigate, useParams } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
// import ReloadingAnimation from '../../json/reloading.json';
import ReloadingAnimation from '../../json/decodex_updated05.json';
import Thanks from '../../json/thanksNew.json';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XTag,
	XCardsCriteriaBg,
	XRoundButton,
	XLabel,
	XList,
	XCircle,
	XModalTitle,
	XModalSubtitle,
	XAffix,
	XCardSummaryEdit,
	XSelectAddCase,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsCaseBg } from '../../styles/GlobalStyle';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
	XRadio,
	ParagraphPage,
	XAvatarName,
	MainTitle,
	AddressWrap,
	XTabIcdCpt,
	XICDModalFooterBTN,
	XButtonModal,
	XICDModal,
	XButtonWrap,
	XRelatedCard,
	XRelatedReadMore,
	XDrawerAddCode,
	XCardSupportingDocument,
	XAddCodeSticky,
} from '../CaseForm/CaseFormStyle';

import { XButtonImg } from '../Manage/ManageStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XCardsGrdBG,
	XButtonAdd,
	XTitleHead,
	XDivider,
	XButtonLike,
	XButtonCloseBar,
	XButtonReload,
	XNotificationBar,
	XResults,
	XLottieOverlay,
	XButtonConfirm,
	XCheckboxGroup,
	XRecommendedCard,
	XInputText,
	XCardRelatedCodes,
	XTitleCard,
	XCollapseMain,
	XCollapseInner,
	XViewMoreBTN,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import {
	PlusOutlined,
	EditOutlined,
	DeleteOutlined,
	CloseOutlined,
	ArrowLeftOutlined,
	WarningOutlined,
	FilePdfOutlined,
	EyeOutlined,
	InboxOutlined,
	CaretRightOutlined,
	DownOutlined,
	UpOutlined,
} from '@ant-design/icons';
// Actions
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import {
	checkModulePermissions,
	getUserToken,
	toTitleCase,
} from '../../utils/helper';
import moment from 'moment';
import Header from '../../components/Header/Header';
import { ReactSortable } from 'react-sortablejs';
import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
import config from '../../config';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { handleInputChange } from '../../utils/formFunctions';
import ReadMoreArea from '@foxeian/react-read-more';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import axios from 'axios';
import { XcaseAnsyCardScroll } from './CaseAnalysisStyle';
import dayjs from 'dayjs';
import {
	getIcdCodesApi,
	getIcdCodesReset,
} from '../../actions/getIcdCodesAction';
import CaseOperations from '../../components/HorizontalTimeline/CaseOperations';
import {
	getCptCodesApi,
	getCptCodesReset,
} from '../../actions/getCptCodesAction';
import DrawerCodingTools from '../../components/CodingTools/DrawerCodingTools';

const { TextArea } = Input;
const { Dragger } = Upload;

const CaseAnalysisView = (props) => {
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
		editCaseReset,
		editCaseState,
		editCase,
		getIcdCodes,
		getIcdCodesState,
		getIcdCodesReset,
		getCptCodes,
		getCptCodesState,
		getCptCodesReset,
	} = props;
	const navigate = useNavigate();
	const missingFieldsCardRef = useRef(null);
	const id = useParams().id;
	const [form] = XForm.useForm();
	const [summaryForm] = XForm.useForm();
	const [newOptionForm] = XForm.useForm();
	const [missingFieldsForm] = XForm.useForm();
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState([false, '']);
	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	const [missingFields, setMissingFields] = useState([]);
	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
		[],
	);
	const [missingFieldTitle, setMissingFieldTitle] = useState('');
	const [lastAnalysisData, setLastAnalysisData] = useState({});
	const [showAnalysisData, setShowAnalysisData] = useState(false);
	const [summary, setSummary] = useState('');
	const [patientData, setPatientData] = useState({});
	const [modalTextArea, setModalTextArea] = useState('');
	const [coverage, setCoverage] = useState('Yes');
	const [feedbackId, setFeedbackId] = useState(null);
	const [matchDateOfBirth, setMatchDateOfbirth] = useState(true);
	const [matchGender, setMatchGender] = useState(true);
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
		status: '',
		missing_points_for_coverage: [],
	});
	const [newOptionText, setNewOptionText] = useState('');
	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	const [analysisCompleted, setAnalysisCompleted] = useState('');
	const [isMissingFieldsThere, setIsMissingFieldsThere] = useState(false);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [pdfWidth, setPdfWidth] = useState(700);
	const [previewTitle, setPreviewTitle] = useState('');
	const [clinicalDocumentsToDisplay, setClinicalDocumentsToDisplay] =
		useState([]);
	const [clinicalForm] = XForm.useForm();
	const [clinicalDocsForm] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const [showClinicalTextArea, setShowClinicalTextArea] = useState(false);
	const [documentsToDisplay, setDocumentsToDisplay] = useState([]);
	const [isClinicalUploadModalOpen, setIsClinicalUploadModalOpen] =
		useState(false);
	const [clinicalDocsLoading, setClinicalDocsLoading] = useState(false);
	const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
		[],
	);
	const [
		updatedClinicalDocumentFileList,
		setUpdatedClinicalDocumentFileList,
	] = useState([]);
	const [clinicalSummaryTextLoading, setClinicalSummaryTextLoading] =
		useState(false);
	const [coverageloading, setCoverageLoading] = useState(false);
	const [submitClinicalDocs, setSubmitClinicalDocs] = useState(false); // Initial value can be 'textarea' or 'upload'
	const colors = ['#0C79FE', '#5DC466', '#FFA033', '#B67AD5'];
	// State for holding filters
	const [icdform] = XForm.useForm();
	const [cptform] = XForm.useForm();
	const [openIcdCptDrawer, setOpenIcdCptDrawer] = useState(true);
	const [openIcdCptDrawer2, setOpenIcdCptDrawer2] = useState(false);
	const [activeCode, setActiveCode] = useState(null);
	const [icdFilters, setIcdFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
		related: false,
		childCode: '',
	});
	const [icdCodes, setIcdCodes] = useState([]); // To store fetched ICD codes
	const [icdLoading, setIcdLoading] = useState(false); // To manage loading state
	const [searchResults, setSearchResults] = useState([]); // To store search results
	const [triggeredBySearch, setTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [triggeredByEnter, setTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [loadMore, setLoadMore] = useState(false); // To track search-triggered calls
	const [icdSearchLoading, setIcdSearchLoading] = useState(false); // To manage loading state
	const [LooksGoodLoading, setLooksGoodLoading] = useState(false);
	//CPT Codes
	const [cptFilters, setCptFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
	});
	const [cptCodes, setCptCodes] = useState([]); // To store fetched ICD codes
	const [cptLoading, setCptLoading] = useState(false); // To manage loading state
	const [cptSearchResults, setCptSearchResults] = useState([]); // To store search results
	const [cptTriggeredBySearch, setCptTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [cptTriggeredByEnter, setCptTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [cptLoadMore, setCptLoadMore] = useState(false); // To track search-triggered calls
	const [cptSearchLoading, setCptSearchLoading] = useState(false); // To manage loading state
	// State to track the "fixed" prop value
	const [isFixed, setIsFixed] = useState(true);
	const [caseClosed, setCaseClosed] = useState(false);

	useEffect(() => {
		// Function to check screen width and update "isFixed" only if below 991px
		const handleResize = () => {
			if (window.innerWidth < 991) {
				setIsFixed(false);
				closeIcdCptDrawer();
				setOpenIcdCptDrawer2(false);
				setActiveCode(null);
				setIcdLoading(false);
				setTriggeredBySearch(false);
				setIcdSearchLoading(false);
				setLoadMore(false);
				icdform.resetFields();
				setCptLoading(false);
				setCptTriggeredBySearch(false);
				setCptSearchLoading(false);
				setCptLoadMore(false);
				cptform.resetFields();
			} else {
				setIsFixed(true); // Ensures `fixed` is `true` on larger screens
				showIcdCptDrawer();
			}
		};

		// Initial check on mount
		handleResize();

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		// Cleanup the event listener on component unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (modalOpen[0] && modalOpen[4] !== 'feedbackSummary') {
			// Check if modal is open and it's not the feedbackSummary modal
			const [description, code] = modalTextArea.split(' - ');
			form.setFieldsValue({
				modal_text_area: description || '', // Prepopulate description
				code: code ? code.split(': ')[1] : '', // Extract and prepopulate code
			});
		}
	}, [modalOpen, modalTextArea, form]);

	// Example of handleCodeClick
	const handleCodeClick = (codeType, codeData) => {
		// Check if the code has leading or trailing spaces
		let trimmedCode = codeData.code;

		if (trimmedCode !== trimmedCode.trim()) {
			trimmedCode = trimmedCode.trim();
		}

		// // Check if it's a child code
		// const isChildCode = trimmedCode.includes('.');
		// const parentCode = isChildCode
		// 	? trimmedCode.split('.')[0]
		// 	: trimmedCode;
		setIcdCodes();
		closeIcdCptDrawer();
		setActiveCode({
			type: codeType,
			code: codeData.code,
			description: codeData.description,
			codeTypeText: codeData.codeTypeText,
			buttontext: codeData.buttontext,
		});

		if (codeType === 'CPT') {
			// // Set filters to fetch related codes
			setCptFilters({
				related: true,
				childCode: trimmedCode,
			});
			setCptTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
		} else {
			// Set filters to fetch related codes
			setIcdFilters({
				related: true,
				childCode: trimmedCode,
			});
			setTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
		}
	};

	const showIcdCptDrawer = () => {
		setOpenIcdCptDrawer(true);
		setIcdCodes();
		setActiveCode(null);
		setSearchResults();
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptCodes();
		setCptSearchResults();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer = () => {
		setOpenIcdCptDrawer(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer2 = () => {
		setOpenIcdCptDrawer2(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		showIcdCptDrawer();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleCancel = () => setPreviewOpen(false);

	// const handlePreview = async (file) => {
	// 	console.log(file);
	// 	if (file && file.name && file.name.split('.').includes('pdf')) {
	// 		setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
	// 		setPdfPreviewOpen(true);
	// 	} else {
	// 		if (!file.url && !file.preview) {
	// 			file.preview = await getBase64(file.originFileObj);
	// 		}
	// 		setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
	// 		setPreviewOpen(true);
	// 		setPreviewTitle(
	// 			file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
	// 		);
	// 	}
	// };

	// const showDeleteConfirm = (title, id, content, item) => {
	// 	XModal.confirm({
	// 		title: `Delete ${title}?`,
	// 		content: (
	// 			<p className="modalPara1">
	// 				Are you sure you want to delete - {content}?
	// 			</p>
	// 		),
	// 		icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
	// 		okText: 'Yes, Confirm',
	// 		okType: 'danger',
	// 		cancelText: 'No, Cancel',
	// 		className: 'confirmModal',
	// 		onOk() {
	// 			deletePageContent(item, id);
	// 		},
	// 		onCancel() {
	// 			console.log('Cancel');
	// 		},
	// 	});
	// };

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			setPageContent({
				...pageContent,
				[title]: [...pageContent[[title]], content],
			});
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification')?.classList.remove('d-none');
	};

	// const deletePageContent = (item, id) => {
	// 	const updatedPageContent = { ...pageContent };
	// 	updatedPageContent[[item]].splice(id, 1);
	// 	setPageContent(updatedPageContent);
	// 	setModalOpen([false, '', ' ']);
	// 	setModalTextArea('');
	// 	form.resetFields();
	// 	document.getElementById('notification')?.classList.remove('d-none');
	// };

	const dateOfBirth = (inputDateString) => {
		const date = new Date(inputDateString);

		// Extract the year, month, and day components from the Date object
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is zero-based, so add 1
		const day = date.getDate();

		// Format the date as "MM-DD-YYYY" or any desired format
		return `${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}-${year}`;
	};

	function compareDates(dateString, yearNumber) {
		// Parse the date string using Moment.js
		const dateOfBirth = moment(dateString);

		// Calculate the current year
		const currentYear = moment().year();

		// Extract the year from the parsed date of birth
		const birthYear = dateOfBirth.year();

		// Calculate the age by subtracting the birth year from the current year
		const age = currentYear - birthYear;
		// Extract the last two digits of the year number
		const yearDigits = parseInt(yearNumber.toString().slice(-2));
		// Compare the years
		return age === yearDigits;
	}
	function compareGender(patientGender, analysisGender) {
		// Get the first character of the analysisGender string and normalize to uppercase
		const firstCharAnalysis = analysisGender.charAt(0).toUpperCase();

		// Check if patientGender is "M" (Male) or "F" (Female)
		if (
			patientGender.toUpperCase() === 'MALE' ||
			patientGender.toUpperCase() === 'FEMALE'
		) {
			// Compare the first character of analysisGender with patientGender
			return firstCharAnalysis === patientGender.charAt(0).toUpperCase();
		} else {
			// Match patientGender with "O" (Other)
			return firstCharAnalysis === 'O';
		}
	}

	const handleAddOption = (index) => {
		setNewCheckBoxModal([true, index]);
		setNewOptionText('');
		newOptionForm.setFieldsValue({});
		newOptionForm.resetFields();
	};

	const handleNewCheckBoxModal = () => {
		// Create a new option object
		newOptionForm
			.validateFields()
			.then(() => {
				const newOption = {
					label: newOptionText,
					value: newOptionText,
				};

				if (
					newCheckBoxModal[1] !== null &&
					newCheckBoxModal[1] >= 0 &&
					newCheckBoxModal[1] < missingFields.length
				) {
					const updatedFields = [...missingFields];
					updatedFields[newCheckBoxModal[1]].options.push(newOption);
					setMissingFields(updatedFields);

					// Get the current form values
					const currentValues = missingFieldsForm.getFieldsValue();

					// Add the new option to the appropriate field and mark it as checked
					if (
						!currentValues[updatedFields[newCheckBoxModal[1]].name]
					) {
						currentValues[updatedFields[newCheckBoxModal[1]].name] =
							[];
					}
					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
						newOptionText,
					);

					// Update the form with the new values
					missingFieldsForm.setFieldsValue(currentValues);
				}

				// Close the modal and reset the newOptionText state
				setNewCheckBoxModal([false, null]);
				setNewOptionText('');
				newOptionForm.setFieldsValue({});
				newOptionForm.resetFields();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	// use effects
	useEffect(() => {
		if (id) getAnalysis({ id: id });
	}, [id]);

	useEffect(() => {
		if (showAnalysisData) {
			document.getElementById('getAns')?.classList.add('top-0');
			document.getElementById('getAns')?.classList.remove('top-100');
		}
	}, [showAnalysisData]);

	useEffect(() => {
		if (
			getAnalysisState.apiState === 'success' &&
			!Object.keys(getAnalysisState.data.coverage_feedback_response)
				.length > 0 &&
			patientData.clinical_summary
		) {
			if (
				patientData.allocated_to &&
				(!getAnalysisState.data.coverage_feedback_response[
					'original_query'
				] ||
					!getAnalysisState.data.coverage_feedback_response[
						'Past History'
					]?.length > 0 ||
					!getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					]?.length > 0 ||
					!getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					]?.length > 0 ||
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					] === 'No')
			) {
				setLoading(true);
				document
					.getElementById('notification')
					?.classList.add('d-none');
				analyseCoverage({
					clinicalSummary: patientData.clinical_summary,
					case_id: patientData._id,
					state: patientData.state,
					provider: patientData.insurance_company?.name,
					supportingDocsSummary:
						patientData && patientData.summary_docs,
				});
			}
		}
		getAnalysisReset();
	}, [patientData]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			if (
				getAnalysisState.data.case_details &&
				getAnalysisState.data.case_details.allocated_to === null
			) {
				notification.error({
					message: 'Case Not Allocated',
					description:
						"Oops! It seems like this case hasn't been allocated to anyone yet. Please allocate it first before proceeding with the analysis.",
				});
			}
			setPatientData(getAnalysisState.data.case_details);
			setPageContent({
				...pageContent,
				originalQuery:
					getAnalysisState.data.coverage_feedback_response[
						'original_query'
					] ?? '-',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '-',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.age
			)
				setMatchDateOfbirth(
					compareDates(
						getAnalysisState.data.case_details.date_of_birth,
						getAnalysisState.data.coverage_feedback_response.age,
					),
				);
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.gender
			)
				setMatchGender(
					compareGender(
						getAnalysisState.data.case_details.gender,
						getAnalysisState.data.coverage_feedback_response.gender,
					),
				);
			setLastAnalysisData(
				getAnalysisState.data?.coverage_feedback_response,
			);
			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			document.getElementById('getAns')?.classList.add('top-0');
			document.getElementById('getAns')?.classList.remove('top-100');

			// Check if the last timeline entry indicates that the case is closed
			const lastTimelineEntry =
				getAnalysisState.data.timelineData?.slice(-1)[0];
			if (
				lastTimelineEntry &&
				(lastTimelineEntry.appeal_status === 'Approved' ||
					lastTimelineEntry.appeal_status === 'Rejected')
			) {
				setCaseClosed(true);
			} else {
				setCaseClosed(false);
			}

			const supportingDocs =
				getAnalysisState.data.case_details.supporting_docs.length > 0 &&
				getAnalysisState.data.case_details.supporting_docs.map(
					(doc) => {
						return doc;
					},
				);
			setDocumentsToDisplay(supportingDocs);

			const clinicalDocs =
				getAnalysisState.data.case_details.clinical_docs.map((doc) => {
					return doc;
				});
			setClinicalDocumentsToDisplay(clinicalDocs);
		}
	}, [getAnalysisState.apiState]);

	useEffect(() => {
		if (analyseCoverageState.apiState === 'success') {
			setFeedbackId(analyseCoverageState.data.feedback_id);
			setLastAnalysisData(analyseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery:
					analyseCoverageState.data['original_query'] ?? '',
				pastHistory: analyseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					analyseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					analyseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					analyseCoverageState.data.missing_points_for_coverage ?? [],
			});
			setAnalysisCompleted('success');
			setSummary('');
			summaryForm.resetFields();
			analyseCoverageReset();
			// setLoading(false);
			if (
				analyseCoverageState.data &&
				analyseCoverageState.data.date_of_birth
			)
				setMatchDateOfbirth(
					compareDates(
						patientData.date_of_birth,
						analyseCoverageState.data.age,
					),
				);
			if (analyseCoverageState.data && analyseCoverageState.data.gender)
				setMatchGender(
					compareGender(
						patientData.gender,
						analyseCoverageState.data.gender,
					),
				);
			if (
				analyseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				analyseCoverageState.data.is_fields_missing &&
				analyseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (analyseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						analyseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						analyseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						analyseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
			}
		}

		if (analyseCoverageState.apiState === 'error') {
			// setLoading(false);
			setIsMissingFieldsThere(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			analyseCoverageReset();
		}
	}, [analyseCoverageState]);

	useEffect(() => {
		if (reviseCoverageState.apiState === 'success') {
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setLastAnalysisData(reviseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});
			setAnalysisCompleted('success');
			if (reviseCoverageState.data && reviseCoverageState.data.age)
				setMatchDateOfbirth(
					compareDates(
						patientData.date_of_birth,
						reviseCoverageState.data.age,
					),
				);
			if (reviseCoverageState.data && reviseCoverageState.data.gender)
				setMatchGender(
					compareGender(
						patientData.gender,
						reviseCoverageState.data.gender,
					),
				);
			if (
				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				reviseCoverageState.data.is_fields_missing &&
				reviseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (reviseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						reviseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						reviseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						reviseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
				setIsMissingFieldsThere(false);
			}
			reviseCoverageReset();
			// setLoading(false);
		} else if (reviseCoverageState.apiState === 'error') {
			// setLoading(false);
			setAnalysisCompleted('error');
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
			reviseCoverageReset();
		}
	}, [reviseCoverageState]);

	useEffect(() => {
		if (sendFeedbackState.apiState === 'success') {
			setCoverageLoading(false);
			setLooksGoodLoading(false);
			setModalOpen([false, '', '']);
			if (sendFeedbackState.data === 'negative')
				setThanksModal([true, 'Thanks for making us Better!']);
			else
				setThanksModal([true, 'Case has been analysed and confirmed!']);
			sendFeedbackReset();
			if (id) getAnalysis({ id: id });
		}
		if (sendFeedbackState.apiState === 'error') {
			setCoverageLoading(false);
			setLooksGoodLoading(false);
		}
	}, [sendFeedbackState]);

	const onSortChange = (name, values) => {
		console.log(name, values);

		missingFieldsForm.setFieldValue({
			name: values,
		});
	};

	//Clinical Docs Upload
	const showClinicalModal = () => {
		setIsClinicalUploadModalOpen(true);
	};

	const handleClinicalDocsUpload = async () => {
		setClinicalDocsLoading(true);
		try {
			clinicalDocsForm
				.validateFields()
				.then(async () => {
					if (clinicalDocumentFileList.length > 0) {
						await Promise.all(
							clinicalDocumentFileList.map(async (fileData) => {
								let get_ext =
									fileData.originFileObj.name.split('.');
								let ext = get_ext[get_ext.length - 1];
								const res = await axios.get(
									`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${patientData.patient_last_name}${fileData.originFileObj.uid}&ext=${ext}&id=${patientData.case_id}`,
									{
										headers: {
											'Content-type':
												'application/json; charset=UTF-8',
											Authorization:
												'Bearer ' + getUserToken(),
										},
									},
								);

								const result = await axios.put(
									res.data.data,
									fileData.originFileObj,
								);

								if (result.status === 200) {
									const documentPath = `/clinical_documents/${patientData.case_id}/${patientData.patient_last_name}${fileData.originFileObj.uid}.${ext}`;
									const newFile = {
										uid: fileData.originFileObj.uid,
										name: fileData.originFileObj.name,
										status: 'done',
										url: documentPath,
									};
									setUpdatedClinicalDocumentFileList(
										(prevFileList) => [{ url: newFile }],
									);
								}
							}),
						).then((res) => {
							setSubmitClinicalDocs(true);
						});
					}
					setSubmitClinicalDocs(true);
				})
				.catch((err) => {
					setClinicalDocsLoading(false);
				});
		} catch (error) {
			setSubmitClinicalDocs(false);
			// Handle error if any
			notification.success({
				message: 'Could not upload',
			});
			setClinicalDocsLoading(false);
			console.error('Error occurred:', error);
		}
	};

	const handleUploadCancel = () => {
		setIsClinicalUploadModalOpen(false);
		setClinicalDocumentFileList([]);
		setUpdatedClinicalDocumentFileList([]);
		setClinicalDocsLoading(false);
		setSubmitClinicalDocs(false);
		clinicalDocsForm.setFieldsValue({ clinical_docs: '' });
	};

	const handleClinicalDocsChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		clinicalDocsForm.validateFields(['clinical_docs']);
	};

	// const showClinicalDelete = (title, file) => {
	// 	XModal.confirm({
	// 		title: `Delete ${title}?`,
	// 		content: (
	// 			<p className="modalPara1">
	// 				Are you sure you want to delete - {title}?
	// 			</p>
	// 		),
	// 		icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
	// 		okText: 'Yes, Confirm',
	// 		okType: 'danger',
	// 		cancelText: 'No, Cancel',
	// 		className: 'confirmModal',
	// 		onOk: async () => {
	// 			// Make an API call to delete the file from S3 and your collection
	// 			try {
	// 				// Extract file name and extension from the file URL
	// 				const urlParts = file.url.split('/');
	// 				const fileName = urlParts[urlParts.length - 1];
	// 				const case_id = urlParts[urlParts.length - 2];
	// 				const [patientName, ext] = fileName.split('.');

	// 				// Make API calls to delete the file from S3 and collection
	// 				await axios.delete(
	// 					`${config.api.base_url}/settings/delete-signed-url?folder_name=clinical_documents&file_name=${patientName}&ext=${ext}&id=${case_id}`,
	// 					{
	// 						headers: {
	// 							Authorization: 'Bearer ' + getUserToken(),
	// 						},
	// 					},
	// 				);

	// 				// Remove the file from supporting_docs array
	// 				const updatedClinicalDocs =
	// 					clinicalDocumentsToDisplay.filter(
	// 						(item) => item.url.uid === file.uid,
	// 					);

	// 				// Make API call to update the supporting_docs field in your case
	// 				await editCase({
	// 					id: id,
	// 					deleted_clinical_docs: updatedClinicalDocs,
	// 					remove: true,
	// 					singleDelete: true,
	// 				});

	// 				console.log(
	// 					'File deleted successfully from S3 and collection',
	// 				);
	// 			} catch (error) {
	// 				console.error('Error deleting file:', error);
	// 				// Handle error
	// 			}
	// 		},
	// 		onCancel() {
	// 			console.log('Cancel');
	// 		},
	// 	});
	// };

	useEffect(() => {
		if (submitClinicalDocs) {
			editCase({
				id: id,
				clinical_docs: updatedClinicalDocumentFileList,
				upload_documents: true,
				remove: true,
			});
		}
	}, [submitClinicalDocs]);

	useEffect(() => {
		if (editCaseState.apiState === 'success') {
			setClinicalSummaryTextLoading(false);
			handleUploadCancel();
			setClinicalDocsLoading(false);
			setShowClinicalTextArea(false);
			notification.success({
				message: editCaseState.message,
			});
			setModalOpen([false, '']);
			getAnalysis({ id: id });
			editCaseReset();
		}
		if (editCaseState.apiState === 'error') {
			setClinicalSummaryTextLoading(false);
			handleUploadCancel();
			setClinicalDocsLoading(false);
			setShowClinicalTextArea(false);
			notification.error({
				message: editCaseState.message
					? editCaseState.message
					: 'Unable to update case status, Please try again',
			});
		}
		editCaseReset();
	}, [editCaseState]);

	useEffect(() => {
		if (missingFieldsModal && missingFieldsCardRef.current) {
			missingFieldsCardRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [missingFieldsModal]);

	return (
		<>
			{/* Notification Bar to raeanalyse */}
			<XContainer fluid>
				<div className="marginTopMobile "></div>
				<Affix id="notification" className="d-none" offsetTop={10}>
					<div className="marginBottomMobile"></div>
					<div
						className="mb-3"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1110',
						}}
					>
						<XNotificationBar>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								style={{ padding: '18px' }}
								className="refreshBtn"
								onClick={() => {
									closeIcdCptDrawer2();
									setLoading(true);
									document
										.getElementById('notification')
										?.classList.add('d-none');

									reviseCoverageReset();
									// Update lastAnalysisData with the latest pageContent values
									const updatedLastAnalysisData = {
										...lastAnalysisData, // Keep all the existing keys and values intact
										'Past History': pageContent.pastHistory, // Update pastHistory
										'Present Complaint':
											pageContent.presentComplaint, // Update presentComplaint
										'Suggested Treatment':
											pageContent.recommendedProcedure, // Update recommendedProcedure
										'Medicare Evaluation': {
											...lastAnalysisData[
												'Medicare Evaluation'
											],
											Coverage:
												pageContent.medicalCoverage[0], // Update medicalCoverage fields
											Rationale:
												pageContent.medicalCoverage[1],
											reference:
												pageContent.medicalCoverage[2],
										},
										original_query:
											pageContent.originalQuery, // Update original_query
										past_history: pageContent.pastHistory, // Update past_history in lastAnalysisData
										present_complaint:
											pageContent.presentComplaint, // Update present_complaint in lastAnalysisData
										status: pageContent.status, // Update status in lastAnalysisData
										missing_points_for_coverage:
											pageContent.missing_points_for_coverage, // Update missing_points_for_coverage
										// Add other updates if necessary, or keep them unchanged
									};
									reviseCoverage({
										case_id: patientData._id,
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										medicalCoverage:
											pageContent.medicalCoverage,
										state: patientData.state,
										provider:
											patientData.insurance_company?.name,
										last_analysis_data:
											updatedLastAnalysisData,
										supportingDocsSummary:
											patientData &&
											patientData.summary_docs,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar
								type="button"
								className="closeBar mt-3"
							>
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											?.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</Affix>
			</XContainer>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header
						className="mt-4"
						headerTag="Case Analysis"
						breadcrumb="true"
						link="/case-analysis"
						caseId={patientData.case_id}
					/>
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="analyseCoverage" />
				</Col>
			</>
			{/* <div id="notification" className="d-none marginNone" ></div> */}
			<XContainer fluid>
				{checkModulePermissions('case-analysis').authorized ? (
					<>
						<Row gutter={(0, 30)}>
							<Col xs={24} sm={24} md={24} lg={14} xl={16}>
								{/* <Col xs={24} sm={24} md={24} lg={24} xl={24}> */}
								{/* <XCardBackBg className="mb-3">
							<Row>
								<Col xs={24}>
									<XButtonBack
										onClick={() => navigate(-1)}
										icon={<ArrowLeftOutlined />}
										size="large"
									/>

									<XEditTitle>View Case</XEditTitle>
								</Col>
							</Row>
						</XCardBackBg> */}
								{/* <XCardBackBg className="mb-3">
									<Row
										align={'middle'}
										className="align-items-center"
									>
										<Col xs={24} sm={12} lg={12}>
											<Space>
												<XAvatarName
													size="large"
													gap="4"
												>
													{patientData.patient_first_name &&
														patientData
															?.patient_first_name[0]}
													{patientData.patient_last_name &&
														patientData
															?.patient_last_name[0]}
												</XAvatarName>
												<MainTitle>
													{patientData.patient_name}
													<br />
													<span>
														{patientData.mrn}
													</span>
												</MainTitle>
											</Space>
										</Col>
										<Col
											xs={24}
											sm={12}
											lg={12}
											className="text-end"
										></Col>
									</Row>
									<AddressWrap className="mt-4">
										<Row gutter={[15, 15]}>
											<Col xs={24} sm={12} lg={8}>
												<div className="heading">
													DOB <br />
													<span>
														{patientData.date_of_birth
															? dateOfBirth(
																	patientData.date_of_birth,
															  )
															: ''}
													</span>
												</div>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<div className="heading">
													Gender <br />
													<span>
														{patientData.gender
															? toTitleCase(
																	patientData.gender,
															  )
															: ''}
														&nbsp;
														{matchGender ? (
															<> </>
														) : (
															<Tooltip
																title="The gender of the patient does not correspond with the gender entered in the Clinical Summary."
																trigger={
																	'hover'
																}
															>
																<WarningOutlined
																	style={{
																		color: 'red',
																	}}
																/>
															</Tooltip>
														)}
													</span>
												</div>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<div className="heading">
													Revenue Cycle
													<br />
													<span>
														{patientData.revenue_cycle ===
															'pre_service' &&
															'Pre Service'}
														{patientData.revenue_cycle ===
															'post_claim' &&
															'Post Claim'}
														{patientData.revenue_cycle ===
															'part_b_drugs' &&
															'Part B Drugs'}
													</span>
												</div>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<div className="heading">
													State
													<br />
													<span>
														{patientData.state}
													</span>
												</div>
											</Col>
											<Col xs={24} lg={8} md={12}>
												<div className="heading">
													Insurance Claim Number{' '}
													<br />
													<span>
														{patientData?.insurance_claim_number ??
															'-'}
													</span>
												</div>
											</Col>
											<Col xs={24} lg={8} md={12}>
												<div className="heading">
													Providers Encounter Number{' '}
													<br />
													<span>
														{patientData?.providers_encounter_number ??
															'-'}
													</span>
												</div>
											</Col>
											<Col xs={24} sm={12} lg={8}>
												<div className="heading">
													Insurance Company
													<br />
													<span>
														{patientData
															.insurance_company
															?.name ?? '-'}
													</span>
												</div>
											</Col>

											<Col xs={24} md={24} lg={16}>
												<div className="heading">
													Date of Service <br />
													{patientData.date_of_service
														?.single_date?.length >
														0 && (
														<>
															{patientData.date_of_service.single_date.map(
																(
																	date,
																	index,
																) => (
																	<span
																		key={
																			index
																		}
																	>
																		{dayjs(
																			date,
																		).format(
																			'MM-DD-YYYY',
																		)}
																		{index <
																			patientData
																				.date_of_service
																				.single_date
																				.length -
																				1 &&
																			', '}
																	</span>
																),
															)}
														</>
													)}
													{patientData.date_of_service
														?.single_date?.length >
														0 &&
														patientData
															.date_of_service
															?.date_range
															?.length > 0 &&
														', '}
													{patientData.date_of_service
														?.date_range?.length >
														0 && (
														<>
															{patientData.date_of_service.date_range.map(
																(
																	range,
																	index,
																) => (
																	<span
																		key={
																			index
																		}
																	>
																		{range[0]
																			? dayjs(
																					range[0],
																			  ).format(
																					'MM-DD-YYYY',
																			  )
																			: ''}
																		{' to '}
																		{range[1]
																			? dayjs(
																					range[1],
																			  ).format(
																					'MM-DD-YYYY',
																			  )
																			: ''}
																		{index <
																			patientData
																				.date_of_service
																				.date_range
																				.length -
																				1 &&
																			', '}
																	</span>
																),
															)}
														</>
													)}
													{!(
														patientData
															.date_of_service
															?.single_date
															?.length > 0 ||
														patientData
															.date_of_service
															?.date_range
															?.length > 0
													) && ' -'}
												</div>
											</Col>
										</Row>
										{patientData.status ===
										'letter_sent' ? (
											<Row className="mt-2">
												<Col xl={18} lg={18}>
													<XTag
														className={
															patientData.status
														}
													>
														{toTitleCase(
															patientData.status,
														)}
													</XTag>
												</Col>
											</Row>
										) : (
											''
										)}
									</AddressWrap>
								</XCardBackBg> */}

								{loading && (
									<AnalysisLoader
										analysisId={id}
										analysisCompleted={analysisCompleted}
										setAnalysisCompleted={
											setAnalysisCompleted
										}
										setLoading={setLoading}
										loading={loading}
										setIsMissingFieldsThere={
											setIsMissingFieldsThere
										}
										isMissingFieldsThere={
											isMissingFieldsThere
										}
									/>
								)}

								<div
									id="getAns"
									className="customScroll slideUp top-100 mt-4"
								>
									<CaseOperations
										aipa={false}
										previewMode={caseClosed}
										matchGender={matchGender}
										dateOfBirth={dateOfBirth}
										loading={loading}
										patientData={patientData}
										mainDataLoading={''}
										handleClinicalDocsChange={
											handleClinicalDocsChange
										}
										clinicalDocumentFileList={
											clinicalDocumentFileList
										}
										clinicalDocsForm={clinicalDocsForm}
										handleUploadCancel={handleUploadCancel}
										clinicalDocsLoading={
											clinicalDocsLoading
										}
										setIsClinicalUploadModalOpen={
											setIsClinicalUploadModalOpen
										}
										isClinicalUploadModalOpen={
											isClinicalUploadModalOpen
										}
										setPreviewImage={setPreviewImage}
										previewImage={previewImage}
										setPreviewTitle={setPreviewTitle}
										previewTitle={previewTitle}
										setPdfPreviewOpen={setPdfPreviewOpen}
										pdfPreviewOpen={pdfPreviewOpen}
										documentsToDisplay={documentsToDisplay}
										clinicalSummaryTextLoading={
											clinicalSummaryTextLoading
										}
										setClinicalSummaryTextLoading={
											setClinicalSummaryTextLoading
										}
										setShowClinicalTextArea={
											setShowClinicalTextArea
										}
										showClinicalTextArea={
											showClinicalTextArea
										}
										setFormData={setFormData}
										formData={formData}
										id={id}
										editCase={editCase}
										setPageContent={setPageContent}
										pageContent={pageContent}
										clinicalDocumentsToDisplay={
											clinicalDocumentsToDisplay
										}
										feedbackId={feedbackId}
										reviseCoverage={reviseCoverage}
										lastAnalysisData={lastAnalysisData}
										// closeIcdCptDrawer2={closeIcdCptDrawer2}
										// closeIcdCptDrawer={closeIcdCptDrawer}
										setLoading={setLoading}
										getBase64={getBase64}
										setClinicalDocsLoading={
											setClinicalDocsLoading
										}
										setSubmitClinicalDocs={
											setSubmitClinicalDocs
										}
										setUpdatedClinicalDocumentFileList={
											setUpdatedClinicalDocumentFileList
										}
										form={form}
										setModalTextArea={setModalTextArea}
										setModalOpen={setModalOpen}
										handleCodeClick={handleCodeClick}
										modalOpen={modalOpen}
										sendFeedback={sendFeedback}
										sendFeedbackState={sendFeedbackState}
										modalTextArea={modalTextArea}
										updatePageContent={updatePageContent}
										missingFieldsForm={missingFieldsForm}
										missingFieldsCardRef={
											missingFieldsCardRef
										}
										handleAddOption={handleAddOption}
										missingFields={missingFields}
										missingFieldInstructions={
											missingFieldInstructions
										}
										setMissingFieldsModal={
											setMissingFieldsModal
										}
										setMissingFieldInstructions={
											setMissingFieldInstructions
										}
										setMissingFields={setMissingFields}
										missingFieldTitle={missingFieldTitle}
										missingFieldsModal={missingFieldsModal}
									/>
									{/* Clinical Summary */}
									{/* <XCardBackBg>
										<Row
											gutter={[15, 15]}
											className="align-items-center"
										>
											<Col xs={24} sm={24} lg={6}>
												<div className="labelHeading">
													Clinical Summary
												</div>
											</Col>
											<Col
												xs={24}
												sm={24}
												lg={18}
												className="text-lg-end"
											>
												<Space className="d-block d-md-inline-flex">
													{!showClinicalTextArea && (
														<XButtonConfirm
															icon={
																<img
																	src="/images/edit-clinical-icon.svg"
																	style={{
																		width: '20px',
																	}}
																	alt="Edit"
																/>
															}
															className="mb-2 mb-md-0 mb-sm-0"
															type="primary"
															size="large"
															shape="round"
															style={{
																background:
																	'#fff',
															}}
															onClick={() => {
																setShowClinicalTextArea(
																	true,
																);
																clinicalForm.setFieldsValue(
																	{
																		clinical_summary:
																			patientData.clinical_summary,
																	},
																);
															}}
														>
															Edit
														</XButtonConfirm>
													)}
													{clinicalDocumentsToDisplay &&
													clinicalDocumentsToDisplay.length >
														0 ? (
														<>
															{clinicalDocumentsToDisplay &&
																clinicalDocumentsToDisplay.length >
																	0 &&
																clinicalDocumentsToDisplay.map(
																	(ele) => {
																		return (
																			<>
																				{ele?.url.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					) && (
																					<>
																						<XButtonConfirm
																							type="primary"
																							size="large"
																							shape="round"
																							icon={
																								<img
																									src="/images/pdf-icon.svg"
																									style={{
																										width: '20px',
																									}}
																									alt="Edit"
																								/>
																							}
																							className="btnActive mb-2 mb-md-0 mb-sm-0"
																							onClick={() =>
																								handlePreview(
																									ele.url,
																								)
																							}
																						>
																							Clinical
																							Document
																						</XButtonConfirm>

																						<XButtonConfirm
																							icon={
																								<img
																									src="/images/delete-icon-new.svg"
																									alt="Delete"
																								/>
																							}
																							type="primary"
																							size="large"
																							shape="round"
																							style={{
																								background:
																									'#fff',
																							}}
																							onClick={() => {
																								showClinicalDelete(
																									'Clinical Summary Document',
																									ele.url,
																								);
																							}}
																						>
																							Delete
																						</XButtonConfirm>
																					</>
																				)}
																			</>
																		);
																	},
																)}
														</>
													) : (
														<>
															
															<XButtonAdd
																onClick={() => {
																	showClinicalModal();
																}}
																icon={
																	<PlusOutlined />
																}
															></XButtonAdd>
														</>
													)}
												</Space>
											</Col>
										</Row>
									
										<XCardSummaryEdit className="mt-4 mb-4">
											<Row className="align-items-center">
												{!showClinicalTextArea ? (
													<Col xs={24}>
														<XPara1
															style={{
																textAlign:
																	'justify',
															}}
														>
															<ReadMoreArea
																expandLabel="Read More"
																collapseLabel="Read Less"
																className="readMore"
															>
																{
																	patientData.clinical_summary
																}
															</ReadMoreArea>
														</XPara1>
													</Col>
												) : (
													<>
														<Form
															form={clinicalForm}
															onFinish={(
																values,
															) => {
																setClinicalSummaryTextLoading(
																	true,
																);
																editCase({
																	id,
																	...values,
																});
															}}
															className="w-100"
														>
															<Col
																xs={24}
																className="text-end"
															>
																<XForm.Item
																	name="clinical_summary"
																	className="mb-0"
																	rules={[
																		{
																			required: true,
																			message:
																				'Please enter clinical summary',
																		},
																	]}
																>
																	<TextArea
																		name="clinical_summary"
																		showCount
																		style={{
																			marginBottom: 24,
																		}}
																		onChange={(
																			e,
																		) => {
																			handleInputChange(
																				e,
																				formData,
																				setFormData,
																			);
																		}}
																		autoSize={{
																			minRows: 5,
																		}}
																		placeholder="Enter Detailed Clinical Summary"
																	/>
																</XForm.Item>
															</Col>
															<Col
																xs={24}
																className="text-end"
															>
																<Button
																	shape="round"
																	style={{
																		color: '#000',
																		marginRight:
																			'10px',
																	}}
																	onClick={() => {
																		setShowClinicalTextArea(
																			false,
																		);
																	}}
																	className="cancel"
																>
																	Cancel
																</Button>
																<XButtonConfirm
																	loading={
																		clinicalSummaryTextLoading
																	}
																	shape="round"
																	type="primary"
																	style={{
																		background:
																			'#000',
																		color: '#fff',
																	}}
																	htmlType="submit"
																>
																	Submit
																</XButtonConfirm>
															</Col>
														</Form>
													</>
												)}
											</Row>
										</XCardSummaryEdit>
										
										<XCardSupportingDocument>
											<Row className="mb-4">
												<Col xs={24}>
													<h2>Supporting Document</h2>
												</Col>
											</Row>
											<Row gutter={(15, 15)}>
												{documentsToDisplay.length >
													0 &&
													documentsToDisplay.map(
														(doc, index) => {
															const isPDF =
																doc.url.name
																	.split('.')
																	.pop() ===
																'pdf';
															return (
																<Col
																	xs={24}
																	sm={12}
																	xl={8}
																	key={index}
																>
																	<Tooltip
																		title={
																			doc.document_name
																		}
																		placement="top"
																	>
																		<Space
																			className="uploadDocs"
																			onClick={() =>
																				handlePreview(
																					doc.url,
																				)
																			}
																		>
																			<img
																				src="/images/pdf.svg"
																				alt={
																					doc.document_name
																				}
																			/>
																			<div>
																				{
																					doc.document_name
																				}
																			</div>
																		</Space>
																	</Tooltip>
																</Col>
															);
														},
													)}
											</Row>
										</XCardSupportingDocument>
									</XCardBackBg> */}

									{/* Clinical Summary Upload */}
									{/* <Modal
										title="Upload Clinical Document"
										open={isClinicalUploadModalOpen}
										onOk={handleClinicalDocsUpload}
										okButtonProps={{
											loading: clinicalDocsLoading,
										}}
										onCancel={() => {
											if (!clinicalDocsLoading) {
												handleUploadCancel();
											}
										}}
									>
										<XForm
											form={clinicalDocsForm}
											layout="vertical"
											autoComplete="off"
										>
											<XForm.Item
												name="clinical_docs"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																clinicalDocumentFileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please Select file!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<Dragger
													fileList={
														clinicalDocumentFileList
													}
													maxCount={1}
													beforeUpload={(file) => {
														const isGIF =
															file.type ===
															'image/gif';
														const isVideo =
															file.type.startsWith(
																'video/',
															);
														const isAudio =
															file.type.startsWith(
																'audio/',
															);

														const isLt10M =
															file.size /
																1024 /
																1024 <
															10;

														if (!isLt10M) {
															notification.error({
																message:
																	'File Size Exceeded',
																description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
															});
														}
														if (
															isGIF ||
															isVideo ||
															isAudio
														) {
															notification.error({
																message:
																	'File Type Error',
																description: `GIF, video, and audio files are not allowed.`,
															});
														}

														return (
															isLt10M &&
															!isGIF &&
															!isVideo &&
															!isAudio
														);
													}}
													// accept=".pdf,.jpeg,.jpg,.png"
													accept=".pdf"
													onChange={
														handleClinicalDocsChange
													}
												>
													<p className="ant-upload-drag-icon">
														<InboxOutlined />
													</p>
													<p className="ant-upload-text">
														Click or drag file to
														this area to upload
													</p>
												</Dragger>
											</XForm.Item>
										</XForm>
									</Modal> */}

									{/* Extracted Supporting documents */}
									{/* {patientData && patientData.summary_docs ? (
										<XCardBackBg className="mt-4">
											<Row
												gutter={(15, 15)}
												className="mb-2 align-items-center"
											>
												<Col xs={20} lg={20}>
													<Space align="center">
														<img
															className="iconResp"
															src="/images/extracted-summary.svg"
															alt=""
														/>
														<XTitleHead>
															<span className="color6 d-none d-sm-block">
																Summary From
																Uploaded
																Documents
															</span>
															<span className="color6 d-block d-sm-none text-start">
																Summary From
																Uploaded <br />
																Documents
															</span>
														</XTitleHead>
													</Space>
												</Col>
											</Row>
											{patientData &&
											patientData.summary_docs ? (
												<XCardSummaryEdit>
													<Row className="align-items-center">
														<Col xs={24}>
															<XPara1
																style={{
																	textAlign:
																		'justify',
																}}
															>
																<ReadMoreArea
																	expandLabel="Read More"
																	collapseLabel="Read Less"
																	className="readMore"
																>
																	{
																		patientData.summary_docs
																	}
																</ReadMoreArea>
															</XPara1>
														</Col>
													</Row>
												</XCardSummaryEdit>
											) : (
												''
											)}
										</XCardBackBg>
									) : (
										''
									)} */}

									{/* Past Clicnical History */}
									{/* <XCardBackBg className="mt-4">
										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={20} lg={20}>
												<Space align="center">
													<img
														className="iconResp"
														src="/images/past-icon.svg"
														alt=""
													/>
													<XTitleHead>
														<span className="color1 d-none d-sm-block">
															Past Clinical
															History
														</span>

														<span className="color1 d-block d-sm-none text-start">
															Past Clinical <br />{' '}
															History
														</span>
													</XTitleHead>
												</Space>
											</Col>
											<Col
												xs={4}
												lg={4}
												className="text-end"
											>
												<XButtonAdd
													icon={<PlusOutlined />}
													onClick={() => {
														form.setFieldsValue({
															modal_text_area: '',
														});
														setModalTextArea('');
														setModalOpen([
															true,
															'Add Past Clinical History',
															'',
															null,
															'pastHistory',
														]);
													}}
												/>
											</Col>
										</Row>
										<XDivider dashed className="mt-3" />
										{pageContent.pastHistory.length > 0
											? pageContent.pastHistory.map(
													(row, index) => {
														const splitPastHistory =
															row.split(
																'- ICD-10 Code:',
															);

														return (
															<XRecommendedCard
																bgColor="#ffffff"
																className="mt-3"
															>
																<Row className="align-items-center justify-content-between">
																	<Col
																		xs={24}
																		lg={14}
																	>
																		{splitPastHistory.length >
																		1 ? (
																			<XPara1>
																				{
																					splitPastHistory[0]
																				}
																			</XPara1>
																		) : (
																			<XPara1>
																				{
																					row
																				}
																			</XPara1>
																		)}
																	</Col>
																	<Col
																		xs={24}
																		lg={6}
																		className="text-center"
																		style={{
																			borderLeft:
																				'1px solid #57636F26',
																			borderRight:
																				'1px solid #57636F26',
																		}}
																		onClick={() => {
																			handleCodeClick(
																				'ICD-10',
																				{
																					code: splitPastHistory[1],
																					description:
																						splitPastHistory[0],
																					codeTypeText:
																						'pastHistory',
																					buttontext:
																						'Add in Past',
																				},
																			);
																		}}
																		role="button"
																	>
																		<XPara1>
																			<span className="hoverGradient">
																				ICD-10:
																				{
																					splitPastHistory[1]
																				}
																			</span>
																		</XPara1>
																	</Col>
																	<Col
																		xs={24}
																		lg={4}
																		className="text-center"
																		style={{
																			padding:
																				'15px',
																			background:
																				'#DFEBF080',
																		}}
																	>
																		<XButtonImg
																			style={{
																				background:
																					'white',
																				boxShadow:
																					'0px 0px 10px 0px #0000001A',
																			}}
																			icon={
																				<img
																					src="/images/edit-icon.svg"
																					alt="Edit"
																				/>
																			}
																			className="me-3"
																			type="text"
																			onClick={() => {
																				form.setFieldsValue(
																					{
																						modal_text_area:
																							row,
																					},
																				);
																				setModalTextArea(
																					row,
																				);
																				setModalOpen(
																					[
																						true,
																						`Edit Past Clinical History`,
																						row,
																						index,
																						'pastHistory',
																					],
																				);
																			}}
																		/>
																		<XButtonImg
																			style={{
																				background:
																					'white',
																				boxShadow:
																					'0px 0px 10px 0px #0000001A',
																			}}
																			icon={
																				<img
																					src="/images/delete-icon-new.svg"
																					alt="Delete"
																				/>
																			}
																			type="text"
																			onClick={() => {
																				showDeleteConfirm(
																					'Past Clinical History',
																					index,
																					row,
																					'pastHistory',
																				);
																			}}
																		></XButtonImg>
																	</Col>
																</Row>
															</XRecommendedCard>
														);
													},
											  )
											: '-'}
									</XCardBackBg> */}
									{/* <XCardBackBg className="mt-4">
										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={20} lg={20}>
												<Space align="center">
													<img
														className="iconResp"
														src="/images/present-icon.svg"
														alt=""
													/>
													<XTitleHead>
														<span className="color2 d-none d-sm-block">
															Present Complaint
														</span>

														<span className="color2 d-block d-sm-none text-start">
															Present <br />{' '}
															Complaint
														</span>
													</XTitleHead>
												</Space>
											</Col>
											<Col
												xs={4}
												lg={4}
												className="text-end"
											>
												<XButtonAdd
													icon={<PlusOutlined />}
													onClick={() => {
														form.setFieldsValue({
															modal_text_area: '',
														});
														setModalTextArea('');
														setModalOpen([
															true,
															'Add Present Complaint',
															'',
															null,
															'presentComplaint',
														]);
													}}
												></XButtonAdd>
											</Col>
										</Row>
										<XDivider dashed className="mt-3" />
										{pageContent.presentComplaint.length > 0
											? pageContent.presentComplaint.map(
													(row, index) => {
														const splitPresentComplaint =
															row.split(
																'- ICD-10 Code:',
															);

														return (
															<XRecommendedCard
																bgColor="#ffffff"
																className="mt-3"
															>
																<Row className="align-items-center justify-content-between">
																	<Col
																		xs={24}
																		lg={14}
																	>
																		{splitPresentComplaint.length >
																		1 ? (
																			<XPara1>
																				{
																					splitPresentComplaint[0]
																				}
																			</XPara1>
																		) : (
																			<XPara1>
																				{
																					row
																				}
																			</XPara1>
																		)}
																	</Col>
																	<Col
																		xs={24}
																		lg={6}
																		className="text-center"
																		style={{
																			borderLeft:
																				'1px solid #57636F26',
																			borderRight:
																				'1px solid #57636F26',
																		}}
																		onClick={() => {
																			handleCodeClick(
																				'ICD-10',
																				{
																					code: splitPresentComplaint[1],
																					description:
																						splitPresentComplaint[0],
																					codeTypeText:
																						'presentComplaint',
																					buttontext:
																						'Add in Present',
																				},
																			);
																		}}
																		role="button"
																	>
																		<XPara1>
																			<span className="hoverGradient">
																				ICD-10:
																				{
																					splitPresentComplaint[1]
																				}
																			</span>
																		</XPara1>
																	</Col>
																	<Col
																		xs={24}
																		lg={4}
																		className="text-center"
																		style={{
																			padding:
																				'15px',
																			background:
																				'#DFEBF080',
																		}}
																	>
																		<XButtonImg
																			style={{
																				background:
																					'white',
																				boxShadow:
																					'0px 0px 10px 0px #0000001A',
																			}}
																			icon={
																				<img
																					src="/images/edit-icon.svg"
																					alt="Edit"
																				/>
																			}
																			className="me-3"
																			type="text"
																			onClick={() => {
																				form.setFieldsValue(
																					{
																						modal_text_area:
																							row,
																					},
																				);
																				setModalTextArea(
																					row,
																				);
																				setModalOpen(
																					[
																						true,
																						`Edit Present Complaint`,
																						row,
																						index,
																						'presentComplaint',
																					],
																				);
																			}}
																		/>
																		<XButtonImg
																			style={{
																				background:
																					'white',
																				boxShadow:
																					'0px 0px 10px 0px #0000001A',
																			}}
																			icon={
																				<img
																					src="/images/delete-icon-new.svg"
																					alt="Delete"
																				/>
																			}
																			type="text"
																			onClick={() => {
																				showDeleteConfirm(
																					'Present Complaint',
																					index,
																					row,
																					'presentComplaint',
																				);
																			}}
																		/>
																	</Col>
																</Row>
															</XRecommendedCard>
														);
													},
											  )
											: '-'}
									</XCardBackBg> */}

									{/* Missing Fields and Recommended Procedure */}
									{/* <XCardsCaseBg
										ref={missingFieldsCardRef}
										className="mt-4"
									>
										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={20} lg={20}>
												<Space align="center">
													<img
														className="iconResp"
														src="/images/procedure-icon.svg"
														alt=""
													/>
													<XTitleHead>
														<span className="color3 d-none d-sm-block">
															Denial Procedure
														</span>

														<span className="color3 d-block d-sm-none text-start">
															Denial <br />{' '}
															Procedure
														</span>
													</XTitleHead>
												</Space>
											</Col>
											{!missingFieldsModal && (
												<Col
													xs={4}
													lg={4}
													className="text-end"
												>
													<XButtonAdd
														icon={<PlusOutlined />}
														onClick={() => {
															form.setFieldsValue(
																{
																	modal_text_area:
																		'',
																},
															);
															setModalTextArea(
																'',
															);
															setModalOpen([
																true,
																'Add Denial Procedure',
																'',
																null,
																'recommendedProcedure',
															]);
														}}
													/>
												</Col>
											)}
										</Row>
										<XDivider dashed className="mt-3" />
										{missingFieldsModal ? (
											<>
												<XModalTitle className="ms-2 mt-4">
													Provide us with the below
													details for completing the
													analysis
												</XModalTitle>
												{missingFieldTitle && (
													<XModalSubtitle className="ms-2 mt-2">
														{missingFieldTitle}
													</XModalSubtitle>
												)}
												<XDivider
													dashed
													className="mt-3"
												/>
												<XForm
													form={missingFieldsForm}
													onFinish={() => {
														missingFieldsForm
															.validateFields()
															.then((values) => {
																setLoading(
																	true,
																);
																reviseCoverageReset();
																reviseCoverage({
																	case_id:
																		patientData._id,
																	feedbackId:
																		feedbackId,
																	clinicalHistory:
																		pageContent.pastHistory,
																	presentComplaint:
																		pageContent.presentComplaint,
																	recommendedProcedure:
																		pageContent.recommendedProcedure,
																	medicalCoverage:
																		pageContent.medicalCoverage,
																	state: patientData.state,
																	provider:
																		patientData
																			.insurance_company
																			?.name,
																	missing_fields:
																		values,
																	last_analysis_data:
																		lastAnalysisData,
																	supportingDocsSummary:
																		patientData &&
																		patientData.summary_docs,
																});

																missingFieldsForm.resetFields();
																setMissingFields(
																	[],
																);
																setMissingFieldInstructions(
																	[],
																);
																setMissingFieldsModal(
																	false,
																);
															})
															.catch((error) => {
																return;
															});
													}}
													name="groupForm"
													layout="vertical"
													autoComplete="off"
												>
													<Row
														gutter={(15, 0)}
														className="mt-4"
													>
														{missingFieldInstructions.length >
															0 && (
															<Col xs={24}>
																<XList
																	size="small"
																	header={
																		<div>
																			Instructions
																		</div>
																	}
																	bordered
																	dataSource={
																		missingFieldInstructions
																	}
																	renderItem={(
																		item,
																		index,
																	) => (
																		<List.Item>
																			<XCircle>
																				{index +
																					1}
																			</XCircle>{' '}
																			{
																				item
																			}
																		</List.Item>
																	)}
																/>
																<br />
															</Col>
														)}

														{missingFields.map(
															(item, index) => {
																if (
																	item.type ==
																	'textarea'
																) {
																	return (
																		<Col
																			xs={
																				24
																			}
																		>
																			<XForm.Item
																				label={
																					<XLabel>
																						{
																							item.label
																						}
																					</XLabel>
																				}
																				name={
																					item.name
																				}
																				rules={[
																					item.hasOwnProperty(
																						'required',
																					) &&
																						item.required && {
																							required: true,
																							message:
																								item.required_error_message ??
																								'Field is required',
																						},
																					item.hasOwnProperty(
																						'regex',
																					) &&
																						item.regex && {
																							validator:
																								(
																									rule,
																									value,
																								) => {
																									let regex =
																										new RegExp(
																											item.regex.slice(
																												1,
																												-1,
																											),
																										);
																									if (
																										!regex.test(
																											value,
																										)
																									)
																										return Promise.reject(
																											new Error(
																												item.regex_error_message ??
																													'Invalid Data',
																											),
																										);
																									return Promise.resolve();
																								},
																						},
																				]}
																			>
																				<TextArea
																					rows={
																						3
																					}
																					name={
																						item.name
																					}
																					showCount
																					className="textArea"
																					placeholder="Enter"
																				></TextArea>
																			</XForm.Item>
																		</Col>
																	);
																} else if (
																	item.type ==
																	'input'
																) {
																	return (
																		<Col
																			xs={
																				24
																			}
																		>
																			<XForm.Item
																				label={
																					<XLabel>
																						{
																							item.label
																						}
																					</XLabel>
																				}
																				name={
																					item.name
																				}
																				rules={[
																					item.hasOwnProperty(
																						'required',
																					) &&
																						item.required && {
																							required: true,
																							message:
																								item.required_error_message ??
																								'Field is required',
																						},
																					item.hasOwnProperty(
																						'regex',
																					) &&
																						item.regex && {
																							validator:
																								(
																									rule,
																									value,
																								) => {
																									let regex =
																										new RegExp(
																											item.regex.slice(
																												1,
																												-1,
																											),
																										);
																									if (
																										!regex.test(
																											value,
																										)
																									)
																										return Promise.reject(
																											new Error(
																												item.regex_error_message ??
																													'Invalid Data',
																											),
																										);
																									return Promise.resolve();
																								},
																						},
																				]}
																			>
																				<Input
																					size="large"
																					name={
																						item.name
																					}
																					placeholder="Enter"
																				></Input>
																			</XForm.Item>
																		</Col>
																	);
																} else if (
																	item.type ==
																	'checkbox'
																) {
																	return (
																		<>
																			<Col
																				xs={
																					24
																				}
																				key={
																					index
																				}
																			>
																				<XForm.Item
																					name={
																						item.name
																					}
																					rules={[
																						item.required && {
																							required: true,
																							message:
																								item.required_error_message ??
																								'Field is required',
																						},
																					]}
																				>
																					<XCheckboxGroup
																						name={
																							item.name
																						}
																						style={{
																							display:
																								'flex',
																							flexDirection:
																								'column',
																						}}
																					>
																						<XList
																							className=""
																							size="small"
																							bordered
																							header={
																								<div
																									style={{
																										display:
																											'flex',
																										justifyContent:
																											'space-between',
																										alignItems:
																											'center',
																									}}
																								>
																									<XLabel>
																										{
																											item.label
																										}
																									</XLabel>
																									<XRoundButton
																										shape="circle"
																										icon={
																											<PlusOutlined />
																										}
																										onClick={() =>
																											handleAddOption(
																												index,
																											)
																										}
																									/>
																								</div>
																							}
																							dataSource={
																								item.options
																							}
																							renderItem={(
																								option,
																								idx,
																							) => (
																								<List.Item>
																									<Checkbox
																										value={
																											option.value
																										}
																									>
																										{
																											option.label
																										}
																									</Checkbox>
																								</List.Item>
																							)}
																						/>
																					</XCheckboxGroup>
																				</XForm.Item>
																			</Col>
																		</>
																	);
																} else if (
																	item.type ==
																	'radio'
																) {
																	return (
																		<Col
																			xs={
																				24
																			}
																			key={
																				index
																			}
																		>
																			<XForm.Item
																				name={
																					item.name
																				}
																				rules={[
																					item.required && {
																						required: true,
																						message:
																							'Field is required',
																					},
																				]}
																			>
																				<Radio.Group
																					style={{
																						display:
																							'flex',
																						flexDirection:
																							'column',
																					}}
																				>
																					<XList
																						className="normal-header"
																						size="small"
																						bordered
																						header={
																							<div
																								style={{
																									display:
																										'flex',
																									justifyContent:
																										'space-between',
																									alignItems:
																										'center',
																								}}
																							>
																								<XLabel>
																									{
																										item.label
																									}
																								</XLabel>
																							</div>
																						}
																						dataSource={
																							item.options
																						}
																						renderItem={(
																							option,
																							idx,
																						) => (
																							<List.Item>
																								<Radio
																									value={
																										option.value
																									}
																								>
																									{
																										option.label
																									}
																								</Radio>
																							</List.Item>
																						)}
																					/>
																				</Radio.Group>
																			</XForm.Item>
																		</Col>
																	);
																}
															},
														)}
													</Row>
													<Row className="mt-1">
														<Col
															xs={24}
															className="text-end"
														>
															<XButtonConfirm
																type="primary"
																style={{
																	background:
																		'#000',
																	color: '#fff',
																}}
																htmlType="submit"
																className="modalButton"
																loading={''}
															>
																Submit
															</XButtonConfirm>
														</Col>
													</Row>
												</XForm>
											</>
										) : pageContent.recommendedProcedure
												.length > 0 ? (
											pageContent.recommendedProcedure.map(
												(row, index) => {
													const splitRecommendedProcedure =
														row.split(
															'- CPT Code:',
														);

													return (
														<XRecommendedCard
															bgColor="#f5f6ff"
															className="mt-3"
														>
															<Row className="align-items-center justify-content-between">
																<Col
																	xs={24}
																	lg={14}
																>
																	{splitRecommendedProcedure.length >
																	1 ? (
																		<XPara1>
																			{
																				splitRecommendedProcedure[0]
																			}
																			
																		</XPara1>
																	) : (
																		<XPara1>
																			{
																				row
																			}
																		</XPara1>
																	)}
																</Col>
																<Col
																	xs={24}
																	lg={6}
																	className="text-center"
																	style={{
																		borderLeft:
																			'1px solid #57636F26',
																		borderRight:
																			'1px solid #57636F26',
																	}}
																	onClick={() => {
																		handleCodeClick(
																			'CPT',
																			{
																				code: splitRecommendedProcedure[1],
																				description:
																					splitRecommendedProcedure[0],
																				codeTypeText:
																					'recommendedProcedure',
																				buttontext:
																					'Add in Denial',
																			},
																		);
																	}}
																	role="button"
																>
																	<XPara1>
																		<span className="hoverGradient">
																			CPT:
																			{
																				splitRecommendedProcedure[1]
																			}
																		</span>
																	</XPara1>
																</Col>
																<Col
																	xs={24}
																	lg={4}
																	className="text-center"
																	style={{
																		padding:
																			'15px',
																		background:
																			'#DFEBF080',
																	}}
																>
																	<XButtonImg
																		style={{
																			background:
																				'white',
																			boxShadow:
																				'0px 0px 10px 0px #0000001A',
																		}}
																		icon={
																			<img
																				src="/images/edit-icon.svg"
																				alt="Edit"
																			/>
																		}
																		className="me-3"
																		type="text"
																		onClick={() => {
																			form.setFieldsValue(
																				{
																					modal_text_area:
																						row,
																				},
																			);
																			setModalTextArea(
																				row,
																			);
																			setModalOpen(
																				[
																					true,
																					`Edit Denial Procedure`,
																					row,
																					index,
																					'recommendedProcedure',
																				],
																			);
																		}}
																	/>
																	<XButtonImg
																		style={{
																			background:
																				'white',
																			boxShadow:
																				'0px 0px 10px 0px #0000001A',
																		}}
																		icon={
																			<img
																				src="/images/delete-icon-new.svg"
																				alt="Delete"
																			/>
																		}
																		type="text"
																		onClick={() => {
																			showDeleteConfirm(
																				'Denial Procedure',
																				index,
																				row,
																				'recommendedProcedure',
																			);
																		}}
																	/>
																</Col>
															</Row>
														</XRecommendedCard>
													);
												},
											)
										) : (
											'-'
										)}
									</XCardsCaseBg> */}

									{/* Medical Coverage */}
									{/* <XCardsCaseBg className="mt-4">
										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={24}>
												<Space align="center">
													<img
														style={{
															width: '40px',
														}}
														src={
															loading
																? '/images/no-cross-icon.svg'
																: missingFieldsModal
																? '/images/black-cross-icon.svg'
																: pageContent
																		.medicalCoverage[0] ===
																  'Yes'
																? '/images/coverage-icon.svg'
																: '/images/cross-icon.svg'
														}
														alt=""
													/>
													<XTitleHead>
														<span
															className={
																loading
																	? 'color6'
																	: missingFieldsModal
																	? 'color6'
																	: pageContent
																			.medicalCoverage[0] ===
																	  'Yes'
																	? 'color4'
																	: 'color5'
															}
														>
															Medicare Coverage
														</span>
													</XTitleHead>
												</Space>
											</Col>
										</Row>
										{pageContent.medicalCoverage.length >
										0 ? (
											<>
												<Row
													gutter={(15, 15)}
													className="mt-3 align-items-center"
												>
													<Col xs={24}>
														<XPara1>
															{missingFieldsModal &&
																'Action Awaited on the Above Item'}
															{
																pageContent
																	.medicalCoverage[1]
															}
															{pageContent
																.medicalCoverage[2] &&
															Object.keys(
																pageContent
																	.medicalCoverage[2],
															).length > 0 ? (
																<>
																	<br />
																	<br />
																	<i>
																		Reference:{' '}
																		<a
																			href={
																				pageContent
																					.medicalCoverage[2][
																					'target'
																				]
																			}
																			rel="noreferrer"
																			target="_blank"
																		>
																			{
																				pageContent
																					.medicalCoverage[2][
																					'display_text'
																				]
																			}
																		</a>
																	</i>
																</>
															) : (
																''
															)}
														</XPara1>
													</Col>
												</Row>
											</>
										) : (
											''
										)}
									</XCardsCaseBg> */}

									{/* Missing Coverage */}
									{/* {pageContent.missing_points_for_coverage &&
										pageContent.missing_points_for_coverage
											.length > 0 && (
											<>
												<XCardsCaseBg className="mt-4">
													<Row>
														<Col xs={24}>
															<Space align="center">
																<img
																	src={
																		'/images/criteria.svg'
																	}
																	alt=""
																/>
																<XTitleHead>
																	<span className="color7">
																		Missing
																		Inclusion
																		Criteria
																	</span>
																	&nbsp;
																	<Tooltip title="This feature is experimental and may contain errors as it evolves. Please exercise discretion, especially when reviewing critical information.">
																		<Tag
																			style={{
																				border: '1px solid #9B68BF',
																				color: '#9B68BF',
																				fontSize:
																					'11px',
																			}}
																		>
																			Alpha
																		</Tag>
																	</Tooltip>
																</XTitleHead>
															</Space>
														</Col>
													</Row>
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center criteria_sub_list"
													>
														<Col xs={24}>
															<ul className="listCircule">
																{pageContent.missing_points_for_coverage?.map(
																	(
																		row,
																		index,
																	) => {
																		return (
																			<li>
																				{
																					row
																				}
																			</li>
																		);
																	},
																)}
															</ul>
														</Col>
													</Row>
												</XCardsCaseBg>
											</>
										)} */}

									{checkModulePermissions(
										'ma-plan-appeals-processing',
									).sub_modules.includes('update-coverage') ||
									checkModulePermissions(
										'case-filing',
									).sub_modules.includes(
										'update-coverage',
									) ? (
										!caseClosed &&
										!missingFieldsModal && (
											<>
												<div className="showMobile">
													<Row
														gutter={(15, 15)}
														className="mt-4"
													>
														<Col xs={12}>
															<XButtonConfirm
																className="w-100"
																type="primary"
																size="large"
																shape="round"
																style={{
																	background:
																		'#fff',
																}}
																icon={
																	<img
																		className="rotateButton"
																		src="/images/like-icon.svg"
																		alt=""
																	/>
																}
																onClick={() => {
																	form.setFieldsValue(
																		{
																			modal_text_area:
																				pageContent.medicalCoverage
																					? pageContent
																							.medicalCoverage[1]
																					: '',
																			medicare_flag:
																				pageContent.medicalCoverage
																					? pageContent
																							.medicalCoverage[0]
																					: '',
																		},
																	);
																	setModalTextArea(
																		pageContent
																			.medicalCoverage[1],
																	);
																	setModalOpen(
																		[
																			true,
																			`Edit Medicare Coverage`,
																			pageContent
																				.medicalCoverage[1],
																			1,
																			'feedbackSummary',
																		],
																	);
																}}
															>
																Refine
															</XButtonConfirm>
														</Col>
														<Col xs={12}>
															<XButtonConfirm
																className="w-100"
																type="primary"
																size="large"
																shape="round"
																style={{
																	background:
																		'#fff',
																}}
																icon={
																	<img
																		src="/images/like-icon.svg"
																		alt=""
																	/>
																}
																loading={
																	LooksGoodLoading
																}
																onClick={() => {
																	setLooksGoodLoading(
																		true,
																	);
																	sendFeedback(
																		{
																			feedback_id:
																				feedbackId,
																			feedback:
																				'positive',
																			status: 'approved',
																			response:
																				pageContent.medicalCoverage,
																		},
																	);
																}}
															>
																Looks Good
															</XButtonConfirm>
														</Col>
														<Col
															xs={24}
															className="mt-4"
														>
															<XButtonConfirm
																className="btnActive w-100"
																loading={
																	coverageloading
																}
																onClick={() => {
																	setCoverageLoading(
																		true,
																	);
																	sendFeedback(
																		{
																			feedback_id:
																				feedbackId,
																			status: 'approved',
																			response:
																				pageContent.medicalCoverage,
																		},
																	);
																}}
																disabled={
																	pageContent.status ===
																	'approved'
																		? true
																		: false
																}
																type="primary"
																shape="round"
																// icon={
																// 	<img
																// 		src="/images/check 1.svg"
																// 		alt=""
																// 	/>
																// }
																size="large"
															>
																Confirm Coverage
															</XButtonConfirm>
														</Col>
													</Row>
												</div>
												<div className="showDesktop">
													<Row className="text-end">
														<Col
															xs={24}
															lg={12}
															className="mt-4"
														>
															<Space
																style={{
																	width: '100%',
																}}
															>
																<XButtonConfirm
																	className="mb-2 mb-md-0 mb-sm-0"
																	type="primary"
																	size="large"
																	shape="round"
																	style={{
																		background:
																			'#fff',
																	}}
																	icon={
																		<img
																			className="rotateButton"
																			src="/images/like-icon.svg"
																			alt=""
																		/>
																	}
																	onClick={() => {
																		form.setFieldsValue(
																			{
																				modal_text_area:
																					pageContent.medicalCoverage
																						? pageContent
																								.medicalCoverage[1]
																						: '',
																				medicare_flag:
																					pageContent.medicalCoverage
																						? pageContent
																								.medicalCoverage[0]
																						: '',
																			},
																		);
																		setModalTextArea(
																			pageContent
																				.medicalCoverage[1],
																		);
																		setModalOpen(
																			[
																				true,
																				`Edit Medicare Coverage`,
																				pageContent
																					.medicalCoverage[1],
																				1,
																				'feedbackSummary',
																			],
																		);
																	}}
																>
																	Refine
																</XButtonConfirm>
																<XButtonConfirm
																	className="mb-2 mb-md-0 mb-sm-0"
																	type="primary"
																	size="large"
																	shape="round"
																	style={{
																		background:
																			'#fff',
																	}}
																	icon={
																		<img
																			src="/images/like-icon.svg"
																			alt=""
																		/>
																	}
																	loading={
																		LooksGoodLoading
																	}
																	onClick={() => {
																		setLooksGoodLoading(
																			true,
																		);
																		sendFeedback(
																			{
																				feedback_id:
																					feedbackId,
																				feedback:
																					'positive',
																				status: 'approved',
																				response:
																					pageContent.medicalCoverage,
																			},
																		);
																	}}
																>
																	Looks Good
																</XButtonConfirm>
															</Space>
														</Col>
														<Col
															xs={24}
															lg={12}
															className="mt-4 text-md-end"
														>
															<XButtonConfirm
																loading={
																	coverageloading
																}
																onClick={() => {
																	setCoverageLoading(
																		true,
																	);
																	sendFeedback(
																		{
																			feedback_id:
																				feedbackId,
																			status: 'approved',
																			response:
																				pageContent.medicalCoverage,
																		},
																	);
																}}
																disabled={
																	pageContent.status ===
																	'approved'
																		? true
																		: false
																}
																type="primary"
																shape="round"
																// icon={
																// 	<img
																// 		src="/images/check 1.svg"
																// 		alt=""
																// 	/>
																// }
																size="large"
																className="btnActive"
															>
																Confirm Coverage
															</XButtonConfirm>
														</Col>
													</Row>
												</div>
											</>
										)
									) : (
										<></>
									)}
								</div>
							</Col>

							{/* // todo coding tools */}
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={8}
								className="mt-4 mt-lg-0"
							>
								<DrawerCodingTools
									previewMode={caseClosed}
									fixed={isFixed}
									icdform={icdform}
									cptform={cptform}
									activeCode={activeCode}
									setActiveCode={setActiveCode}
									closeIcdCptDrawer={closeIcdCptDrawer}
									openIcdCptDrawer={openIcdCptDrawer}
									handleCodeClick={handleCodeClick}
									setModalOpen={setModalOpen}
									setModalTextArea={setModalTextArea}
									closeIcdCptDrawer2={closeIcdCptDrawer2}
									openIcdCptDrawer2={openIcdCptDrawer2}
									modalOpen={modalOpen}
									updatePageContent={updatePageContent}
									modalTextArea={modalTextArea}
									setIcdCodes={setIcdCodes}
									icdCodes={icdCodes}
									searchResults={searchResults}
									setSearchResults={setSearchResults}
									icdLoading={icdLoading}
									setIcdLoading={setIcdLoading}
									triggeredBySearch={triggeredBySearch}
									setTriggeredBySearch={setTriggeredBySearch}
									triggeredByEnter={triggeredByEnter}
									setTriggeredByEnter={setTriggeredByEnter}
									loadMore={loadMore}
									setLoadMore={setLoadMore}
									icdSearchLoading={icdSearchLoading}
									setIcdSearchLoading={setIcdSearchLoading}
									cptCodes={cptCodes}
									setCptCodes={setCptCodes}
									cptLoading={cptLoading}
									setCptLoading={setCptLoading}
									cptSearchResults={cptSearchResults}
									setCptSearchResults={setCptSearchResults}
									cptTriggeredBySearch={cptTriggeredBySearch}
									setCptTriggeredBySearch={
										setCptTriggeredBySearch
									}
									cptTriggeredByEnter={cptTriggeredByEnter}
									setCptTriggeredByEnter={
										setCptTriggeredByEnter
									}
									cptLoadMore={cptLoadMore}
									setCptLoadMore={setCptLoadMore}
									cptSearchLoading={cptSearchLoading}
									setCptSearchLoading={setCptSearchLoading}
									icdFilters={icdFilters}
									setIcdFilters={setIcdFilters}
									cptFilters={cptFilters}
									setCptFilters={setCptFilters}
								/>
							</Col>
							{/* // todo coding tools */}
						</Row>
					</>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>

			{/* //todo Drawer of the Coding Tools Start */}
			{!isFixed && (
				<XAddCodeSticky type="primary" onClick={showIcdCptDrawer}>
					Coding Tools
				</XAddCodeSticky>
			)}

			{/* Modal for preview */}
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				{previewImage ? (
					<img
						alt="example"
						style={{
							width: '100%',
						}}
						src={previewImage}
					/>
				) : null}
			</Modal>
			<Modal
				open={pdfPreviewOpen}
				title="Preview"
				footer={null}
				onCancel={() => setPdfPreviewOpen(false)}
				width={pdfWidth} // Set the width of the modal dynamically
			>
				<div>
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
						<div
							style={{
								height: '750px',
							}}
						>
							<Viewer fileUrl={previewImage} />
						</div>
					</Worker>
				</div>
			</Modal>

			{/* <XModal
				title={
					<>
						<EditOutlined />
						<span className="ms-2">{modalOpen[1]}</span>
					</>
				}
				centered
				open={modalOpen[0]}
				onOk={() => {
					form.validateFields()
						.then((res) => {
							if (modalOpen[4] === 'feedbackSummary') {
								sendFeedback({
									feedback_id: feedbackId,
									feedback: 'negative',
									status: 'pending_for_analysis',
									response: [coverage, modalTextArea],
								});
							} else {
								// Format the text according to the selected code type (ICD-10 or CPT)
								const formattedText = `${
									res.modal_text_area
								} - ${
									modalOpen[4] === 'recommendedProcedure'
										? 'CPT Code'
										: 'ICD-10 Code'
								}: ${res.code}`;
								// Save the formatted text in setModalTextArea
								setModalTextArea(formattedText);

								// Optionally update page content
								updatePageContent(
									modalOpen[4],
									modalOpen[3],
									formattedText,
								);

								// updatePageContent(
								// 	modalOpen[4],
								// 	modalOpen[3],
								// 	modalTextArea,
								// );
							}
						})
						.catch((err) => {
							console.log('error', err);
						});
				}}
				okButtonProps={{
					loading: sendFeedbackState.apiState === 'loading',
				}}
				onCancel={() => {
					form.resetFields();
					setModalTextArea('');
					setModalOpen([false, '', ' ']);
				}}
				className="modalButton"
				okText="Submit"
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							{modalOpen[4] === 'feedbackSummary' && (
								<>
									<XForm.Item
										name="medicare_flag"
										label="Coverage"
										rules={[
											{
												required: true,
												message: 'Field is required',
											},
										]}
									>
										<Select
											showSearch
											placeholder="Select a person"
											optionFilterProp="children"
											onChange={(e) => {
												setCoverage(e);
											}}
											options={[
												{
													value: 'Yes',
													label: 'YES',
												},
												{
													value: 'No',
													label: 'NO',
												},
											]}
										/>
									</XForm.Item>

									<XForm.Item
										name="modal_text_area"
										rules={[
											{
												required: true,
												message: 'Field is required',
											},
										]}
									>
										<TextArea
											name="modal_text_area"
											showCount
											style={{
												height: 120,
												marginBottom: 24,
											}}
											className="textArea mt-2"
											placeholder="Enter"
											onChange={(e) =>
												setModalTextArea(e.target.value)
											}
										>
										</TextArea>
									</XForm.Item>
								</>
							)}
							{modalOpen[4] !== 'feedbackSummary' && (
								<>
									<XForm.Item
										name="modal_text_area"
										label="Description"
										rules={[
											{
												required: true,
												message:
													'Description is required',
											},
										]}
									>
										<TextArea
											name="modal_text_area"
											showCount
											style={{
												height: 100,
												// marginBottom: 24,
											}}
											className="textArea mt-2"
											placeholder="Enter description"
										/>
									</XForm.Item>
									<XForm.Item
										name="code"
										label={`${
											modalOpen[4] ===
											'recommendedProcedure'
												? 'CPT'
												: 'ICD-10'
										} Code`}
										rules={[
											{
												required: true,
												message: 'Code is required',
											},
										]}
									>
										<Input
											className="textArea mt-2"
											placeholder={`Enter ${
												modalOpen[4] ===
												'recommendedProcedure'
													? 'CPT'
													: 'ICD-10'
											} Code`}
										/>
									</XForm.Item>
								</>
							)}
						</Col>
					</Row>
				</XForm>
			</XModal> */}

			{/* Modal for adding new option */}
			<XModal
				title="Add New Option"
				open={newCheckBoxModal[0]}
				onOk={handleNewCheckBoxModal}
				okText="Submit"
				onCancel={() => {
					setNewCheckBoxModal([false, null]);
					setNewOptionText('');
					newOptionForm.setFieldsValue({});
					newOptionForm.resetFields();
				}}
			>
				<XForm
					form={newOptionForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name={'new-option'}
						rules={[
							{
								required: true,
								message: 'Please input option',
							},
						]}
					>
						<Input
							size="large"
							className="mt-3 mb-3"
							value={newOptionText}
							onChange={(e) => setNewOptionText(e.target.value)}
							placeholder="Enter new option text"
						/>
					</XForm.Item>
				</XForm>
			</XModal>

			<XModal
				centered
				open={thanksModal[0]}
				closable={false} // Hide the close icon
				// onOk={() => setThanksModal([false, ''])}
				// onCancel={() => {
				// 	setThanksModal([false, '']);
				// }}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					gutter={(15, 0)}
					className="mt-2"
				>
					<Col xs={24} lg={18}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1
							style={{ textAlign: 'center' }}
							className="mt-0 mb-4 "
						>
							{thanksModal[1]}
						</XPara1>
					</Col>
				</Row>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getAnalysisState: state.getAnalysis,
	editCaseState: state.editCase,
	getIcdCodesState: state.getIcdCodes,
	getCptCodesState: state.getCptCodes,
});

const mapDispatchToProps = (dispatch) => ({
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
	editCase: (data) => dispatch(editCaseApi(data)),
	editCaseReset: () => dispatch(editCaseReset()),
	getIcdCodes: (data) => dispatch(getIcdCodesApi(data)),
	getIcdCodesReset: () => dispatch(getIcdCodesReset()),
	getCptCodes: (data) => dispatch(getCptCodesApi(data)),
	getCptCodesReset: () => dispatch(getCptCodesReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseAnalysisView);
