import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tabs, Card, Result, Avatar } from 'antd';
import { Button, Input } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { XContainer } from '../../styles/GlobalStyle';

// actions

import { checkModulePermissions } from '../../utils/helper';
import HeaderFile from '../../components/Header/HeaderFile';

import Header from '../../components/Header/Header';

import FilingManagement from './PreServiceFilingManagement';
import TabPane from 'antd/es/tabs/TabPane';
import { getCountByFilingAndRevenueApi } from '../../actions/getCountByFilingAndRevenueAction';

const CaseManagement = (props) => {
	const {
		getCountByFilingAndRevenueState,
		getCountByFilingAndRevenue,
		maProcessing,
		setMaProcessing,
	} = props;
	const navigate = useNavigate();
	const location = useLocation();

	const [activeTab, setActiveTab] = useState('all');
	const [filingStatusCount, setFilingStatusCount] = useState({
		untimely: 0,
		escalation: 0,
		at_risk: 0,
		in_process: 0,
	});
	const [filingStatus, setFilingStatus] = useState();
	const [revenueCycleCount, setRevenueCycleCount] = useState({
		pre_service: 0,
		post_claim: 0,
		part_b_drugs: 0,
		all: 0,
	});
	const onChange = (key) => {
		// Update the URL with the selected tab's value
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('revenue_cycle', key);

		// Update other query parameters as needed
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');

		// Construct the new URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

		// Push the new URL to the browser history
		window.history.pushState({}, '', newUrl);

		// Optionally, you can set the active tab state here if needed
		setActiveTab(key);
	};

	const handleClick = (filing_status) => {
		// Update the URL with the filing status
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('filing_status', filing_status); // Assuming 'filing_status' is the query parameter to store the filing status

		// Construct the new URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

		// Push the new URL to the browser history
		window.history.pushState({}, '', newUrl);
		setFilingStatus(filing_status);
		// window.location.reload();
	};

	const items = [
		{
			key: 'all',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					All{' '}
					<Avatar size={20} className="ml-2">
						{revenueCycleCount.all}{' '}
					</Avatar>
				</div>
			),
			children: (
				<FilingManagement
					maProcessing={maProcessing}
					filingStatus={filingStatus}
				/>
			),
		},
		{
			key: 'pre_service',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					Pre-Service{' '}
					<Avatar size={20} className="ml-2">
						{revenueCycleCount.pre_service}{' '}
					</Avatar>
				</div>
			),
			children: (
				<FilingManagement
					maProcessing={maProcessing}
					filingStatus={filingStatus}
				/>
			),
		},
		{
			key: 'post_claim',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					Post Claim{' '}
					<Avatar size={20} className="ml-2">
						{revenueCycleCount.post_claim}{' '}
					</Avatar>
				</div>
			),
			children: (
				<FilingManagement
					maProcessing={maProcessing}
					filingStatus={filingStatus}
				/>
			),
		},
		{
			key: 'part_b_drugs',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					Part B Drugs{' '}
					<Avatar size={20} className="ml-2">
						{revenueCycleCount.part_b_drugs}{' '}
					</Avatar>
				</div>
			),
			children: (
				<FilingManagement
					maProcessing={maProcessing}
					filingStatus={filingStatus}
				/>
			),
		},
	];

	useEffect(() => {
		getCountByFilingAndRevenue({
			for_page: maProcessing,
			// monitor_type: maProcessing ? maProcessing : 'ma_standard',
		});
	}, [window.location.search]);

	useEffect(() => {
		if (getCountByFilingAndRevenueState.apiState === 'success') {
			const data = getCountByFilingAndRevenueState.data;

			// Initialize an object to hold the updated counts
			const updatedRevenueCycleCount = {
				pre_service: 0,
				post_claim: 0,
				part_b_drugs: 0,
				all: 0,
			};
			const updatedFilingStatusCount = {
				untimely: 0,
				escalation: 0,
				at_risk: 0,
				in_process: 0,
			};

			// Iterate through the revenue_cycle array and update the counts
			data.revenue_cycle.forEach((item) => {
				updatedRevenueCycleCount[item._id] = item.count;
			});

			// Update the state with the new counts
			setRevenueCycleCount(updatedRevenueCycleCount);
			props.setAppealLetterApprovals(
				data.pending_appeal_letter_approvals,
			);

			// Iterate through the revenue_cycle array and update the counts
			data.filing_status.forEach((item) => {
				updatedFilingStatusCount[item._id] = item.count;
			});

			// Update the state with the new counts
			setFilingStatusCount(updatedFilingStatusCount);
		}
	}, [getCountByFilingAndRevenueState]);

	// use effects

	return (
		<>
			{checkModulePermissions(
				'ma-plan-appeals-processing',
			).sub_modules.includes('list-cases') ? (
				<>
					{maProcessing !== 'appeal_letter_approvals' && (
						<Row gutter={16} className="case-filings">
							<Col
								className="gutter-row"
								span={8}
								xs={24}
								md={12}
								lg={8}
								style={{ marginTop: '5px' }}
							>
								<Card
									style={{
										borderRadius: '20px',
										background: '#F9EBEB',
									}}
									className="case-filing"
									onClick={() => handleClick('untimely')}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												flexDirection: 'column',
												alignItems: 'left',
											}}
										>
											<p
												style={{
													fontFamily: 'Poppins',
													fontSize: '30px',
													fontWeight: '600',
													lineHeight: '24px',
													textAlign: 'left',
													color: '#DB3A17',
												}}
											>
												{filingStatusCount.untimely}
											</p>
											<p
												style={{
													fontFamily: 'Poppins',
													fontSize: '16px',
													fontWeight: '400',
													lineHeight: '24px',
													textAlign: 'left',
													color: '#002141',
													gap: '0px',
													opacity: '0.5',
													margin: 0,
												}}
											>
												Untimely
											</p>
										</div>
										<div>
											<img src="/images/timer.svg" />
										</div>
									</div>
								</Card>
							</Col>

							<Col
								className="gutter-row"
								span={8}
								xs={24}
								md={12}
								lg={8}
								style={{ marginTop: '5px' }}
							>
								{' '}
								<Card
									style={{
										borderRadius: '20px',
										background: '#F9F8EB',
									}}
									className="case-filing"
									onClick={() => handleClick('at_risk')}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												flexDirection: 'column',
												alignItems: 'left',
											}}
										>
											<p
												style={{
													fontFamily: 'Poppins',
													fontSize: '30px',
													fontWeight: '600',
													lineHeight: '24px',
													textAlign: 'left',
													color: '#E5AA11',
												}}
											>
												{filingStatusCount.at_risk}
											</p>
											<p
												style={{
													fontFamily: 'Poppins',
													fontSize: '16px',
													fontWeight: '400',
													lineHeight: '24px',
													textAlign: 'left',
													color: '#002141',
													gap: '0px',
													opacity: '0.5',
													margin: 0,
												}}
											>
												At Risk
											</p>
										</div>
										<div>
											<img src="/images/at_risk.svg" />
										</div>
									</div>
								</Card>
							</Col>
							<Col
								className="gutter-row"
								span={8}
								xs={24}
								md={12}
								lg={8}
								style={{ marginTop: '5px' }}
							>
								{' '}
								<Card
									style={{
										borderRadius: '20px',
										background: '#EBF9F1',
									}}
									className="case-filing"
									onClick={() => handleClick('in_process')}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												flexDirection: 'column',
												alignItems: 'left',
											}}
										>
											<p
												style={{
													fontFamily: 'Poppins',
													fontSize: '30px',
													fontWeight: '600',
													lineHeight: '24px',
													textAlign: 'left',
													color: '#1F9254',
												}}
											>
												{filingStatusCount.in_process}
											</p>
											<p
												style={{
													fontFamily: 'Poppins',
													fontSize: '16px',
													fontWeight: '400',
													lineHeight: '24px',
													textAlign: 'left',
													color: '#002141',
													gap: '0px',
													opacity: '0.5',
													margin: 0,
												}}
											>
												In Process
											</p>
										</div>
										<div>
											<img src="/images/in_process.svg" />
										</div>
									</div>
								</Card>
							</Col>
						</Row>
					)}
					<Row style={{ marginTop: '20px' }}>
						<Col xs={24}>
							{maProcessing !== 'appeal_letter_approvals' ? (
								<Tabs
									activeKey={activeTab}
									onChange={onChange}
									className="hello"
								>
									{items.map((item) => (
										<TabPane
											tab={item.label}
											key={item.key}
										>
											{activeTab === item.key &&
												item.children}
										</TabPane>
									))}
								</Tabs>
							) : (
								<FilingManagement
									maProcessing={maProcessing}
									filingStatus={filingStatus}
								/>
							)}
						</Col>
					</Row>
				</>
			) : (
				<Result
					status="403"
					title="403"
					subTitle="Sorry, you are not authorized to access this page."
					extra={
						<Button type="primary" onClick={() => navigate('/')}>
							Back Home
						</Button>
					}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	getCountByFilingAndRevenueState: state.getCountByFilingAndRevenue,
});

const mapDispatchToProps = (dispatch) => ({
	getCountByFilingAndRevenue: (data) =>
		dispatch(getCountByFilingAndRevenueApi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement);
