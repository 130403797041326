import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Result, Radio, Tag, Tooltip } from 'antd';
import { Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	XTag,
	XForm,
	XButton2,
	FilterButton,
	XSelect,
	XSearch,
} from '../../styles/GlobalStyle';

import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
// actions
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import {
	getHospitalDropdownApi,
	getHospitalDropdownReset,
} from '../../actions/getHospitalDropdownAction';
import { getCasesApi, getCasesReset } from '../../actions/getCasesAction';
import { deleteCaseApi, deleteCaseReset } from '../../actions/deleteCaseAction';
import {
	checkModulePermissions,
	toTitleCase,
	truncateString,
	urlPagination,
} from '../../utils/helper';
import { caseStatuses, dateFilters } from '../../utils/constants';
import DataTable from '../../components/DataTable/DataTable';
import { filterByLabel } from '../../utils/formFunctions';
import {
	getInsuranceDropdownApi,
	getInsuranceDropdownReset,
} from '../../actions/getInsuranceDropdownAction';
import { getOrganizationsApi } from '../../actions/getOrganizationsAction';
import { getRegionsApi } from '../../actions/getRegionsAction';

const CaseManagement = (props) => {
	const {
		getCases,
		getCasesReset,
		getCasesState,
		getStateDropdownState,
		getStateDropdown,
		getHospitalDropdown,
		getHospitalDropdownState,
		getRegions,
		getRegionsState,
		getOrganizations,
		getOrganizationsState,
	} = props;

	const navigate = useNavigate();

	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState('');
	const [searchOptions, setSearchOptions] = useState([]);

	const defaultFilters = {
		search: '',
		page: 1,
		sort: 'createdAt',
		sortDirection: 'descend',
		created_on: 'all',
		hospital: '',
		organization: '',
		region: '',
		state: '',
		status: '',
		insurance_company: '',
		limit: 10,
		revenue_cycle: 'all',
		filing_status: 'all',
		monitor_type: 'ma_standard',
	};
	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};
		let isAppealLetterApporvals = false;

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {

			if(key=="monitor_type" && value=="appeal_letter_approvals" && !checkModulePermissions(
				'appeal-letter',
			).sub_modules.includes('confirm-letter'))
			{
				urlFilters[key] = "ma_standard";
				isAppealLetterApporvals  = true;
			}
			else
			{
				urlFilters[key] = value;
			}
		}
		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		if(isAppealLetterApporvals)
		{
			urlParams.set('monitor_type','ma_standard');
			const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
			// Push the new URL to the browser history
			window.history.pushState({}, '', newUrl);	
		}

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);
	const [form] = XForm.useForm();
	const [insuranceCompanies, setInsuranceCompanies] = useState([]);

	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const onChange = (key) => {
		setFilters({
			...filters,
			[key.target.name]: key.target.value,
		});
	};

	function getStatusDisplay(status) {
		if (status.includes('first')) {
			return '1st';
		} else if (status.includes('second')) {
			return '2nd';
		} else if (status.includes('third')) {
			return '3rd';
		} else if (status.includes('fourth')) {
			return '4th';
		} else if (status.includes('judicial')) {
			return 'JR';
		} else if (status === 'organization_determination') {
			return 'OD';
		} else {
			return 'OD';
		}
	}

	function getStatusTooltipDisplay(status) {
		if (status.includes('first')) {
			return 'First Appeal';
		} else if (status.includes('second')) {
			return 'Second Appeal';
		} else if (status.includes('third')) {
			return 'Third Appeal';
		} else if (status.includes('fourth')) {
			return 'Fourth Appeal';
		} else if (status.includes('judicial')) {
			return 'Judicial Review';
		} else if (status === 'organization_determination') {
			return 'Organization Determination';
		} else {
			return 'Organization Determination';
		}
	}

	const columns = [
		{
			dataIndex: 'status_filing',
			fixed: 'left',
			width: 150,
			className: 'custom-border-left',

			render: (status_filing) => (
				<>
					<div className={`triangle ${status_filing[0]}`}></div>
					<Tooltip
						title={
							status_filing[1] ? (
								truncateString(
									getStatusTooltipDisplay(status_filing[1]),
								)
							) : (
								<></>
							)
						}
						placement="top"
					>
						<Tag
							style={{
								marginLeft: '50px',
								background: '#DEE9E9',
								color: '#11263C',
							}}
						>
							{getStatusDisplay(status_filing[1])}
						</Tag>
					</Tooltip>
				</>
			),
		},
		{
			title: 'Case ID',
			dataIndex: 'case_id',
			fixed: 'left',
			width: 150,
			// className: 'custom-border-left',

			render: (case_id) => {
				return (
					<Link
						style={{ color: '#000', textDecoration: 'underline' }}
						to={`${case_id[2]}/${case_id[0]}`}
					>
						<div style={{ width: '100px' }}>{case_id[1]}</div>
					</Link>
				);
			},
		},
		{
			title: 'Patient ID',
			dataIndex: 'mrn',
			fixed: 'left',
			width: 150,
			render: (mrnId) => (
				<Link
					style={{ color: '#000', textDecoration: 'underline' }}
					to={`${mrnId[2]}/${mrnId[0]}`}
				>
					<div style={{ width: '100px' }}>{mrnId[1]}</div>
				</Link>
			),
		},
		{
			title: 'Case Name',
			dataIndex: 'patient_name',
			fixed: 'left',
			width: 150,
			render: (nameId) => (
				<Link
					style={{
						color: '#000',
						textDecoration: 'underline',
					}}
					to={`${nameId[2]}/${nameId[0]}`}
				>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{truncateString(nameId[1], 25)}
					</div>
				</Link>
			),
		},
		{
			title: 'Insurance Company',
			dataIndex: 'insurance_company',
			width: 300,
			render: (insurance_company) => <div>{insurance_company}</div>,
		},
		{
			title: 'Hospital',
			dataIndex: 'hospital',
			width: 200,
			render: (hospital) => (
				<div>
					<div style={{ width: '100%' }}>{hospital}</div>
				</div>
			),
		},
		{
			title: 'State',
			dataIndex: 'state',
			width: 150,
			render: (state) => (
				<div>
					<div style={{ width: '100%' }}>{state}</div>
				</div>
			),
		},
		{
			title: 'Allocated to',
			dataIndex: 'allocated',
			width: 150,
			render: (allocated) => (
				<div>
					<div style={{ width: '100%' }}>{allocated}</div>
				</div>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: 200,
			render: (status) => (
				<div>
					<div>{status}</div>
				</div>
			),
		},
		// {
		// 	title: 'Deadline Date',
		// 	dataIndex: 'deadline_date',
		// 	width: 150,
		// 	sorter: (a, b) => a.deadline_date - b.deadline_date,
		// 	sortDirections: ['ascend', 'descend'],
		// 	render: (deadline_date) => (
		// 		<div>
		// 			<div style={{ width: '100%' }}>{deadline_date}</div>
		// 		</div>
		// 	),
		// },
		{
			title: 'Created On',
			dataIndex: 'createdAt',
			width: 150,
			sorter: (a, b) => a.createdAt - b.createdAt,
			sortDirections: ['ascend', 'descend'],
			render: (createdAt) => (
				<div>
					<div style={{ width: '100%' }}>{createdAt}</div>
				</div>
			),
		},
	];

	// use effects

	useEffect(() => {
		if (getCasesState.apiState === 'success') {
			if (getCasesState.data.all_cases.length > 0) {
				const updatedData = [];
				const updatedSearchOptions = [];
				setTotalRecords(getCasesState.data.total_count);
				getCasesState.data.all_cases.map((item, index) => {
					console.log(item);
					updatedSearchOptions.push({ value: item.patient_name });
					updatedData.push({
						key: item._id,
						status_filing: [item.filing_status, item.status],
						case_id: [item._id, item.case_id, '/case/ma'],
						mrn: [item._id, item.mrn, '/case/ma'],
						patient_name: [item._id, item.patient_name, '/case/ma'],
						insurance_company: item.insurance
							? item.insurance.name
							: '-',
						allocated: item.allocated_to ? item.allocated_to : '-',
						hospital: item.hospitals ? item.hospitals.name : '-',
						state: item.state ? item.state : '-',
						status: (
							<>
								<XTag className={item.status}>
									{toTitleCase(item.status)}
								</XTag>
							</>
						),
						deadline_date: (
							<div className="showDate">
								{moment(item.deadline_date).format(
									'DD-MM-YYYY',
								)}
							</div>
						),
						createdAt: (
							<div className="showDate">
								{moment(item.createdAt).format('DD-MM-YYYY')}
							</div>
						),

						statusMobile: item.status,
					});
				});
				setSearchOptions(updatedSearchOptions);
				setTableData(updatedData);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
		}
		getCasesReset();
	}, [getCasesState]);

	const applyFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through filters and update the query parameters
		Object.entries(filters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the current value
				queryParams.append(key, value);
			}
		});

		// Set the page to 1
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');
		filters.page = 1;

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	useEffect(() => {
		// Retrieve filters from the updated URL
		console.log('I was called');
		const updatedFilters = getFiltersFromUrl();
		setFilters({ ...updatedFilters });

		// Make API call with updated filters and pagination
		checkModulePermissions(
			'ma-plan-appeals-processing',
		).sub_modules.includes('list-cases') &&
			getCases({
				...updatedFilters,
				for_page: 'ma_process',
			});
	}, [window.location.search]);

	const clearFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through defaultFilters and update the query parameters
		Object.entries(defaultFilters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the default value
				queryParams.append(key, value);
			}
		});

		// Clear the form fields
		form.resetFields();

		// Update the state with default values
		setFilters(defaultFilters);

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};
	return (
		<>
			<Card
				bodyStyle={{ padding: '10px' }}
				style={{ borderRadius: '30px' }}
			>
				{!props.displayHeader ? (
					<></>
				) : (
					<Row>
						<Col className="mt-3 ms-4">
							<label className="headerTagDashboard">
								Case Management
							</label>
						</Col>
					</Row>
				)}

				<CaseSearch
					filters={[
						<XForm.Item
							name="status"
							colon={false}
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
							label="Status"
							className="removeFormMargin"
						>
							<Radio.Group
								onChange={onChange}
								name="status"
								defaultValue={
									filters.status ? filters.status : null
								}
							>
								<Row>
									{caseStatuses.map((item, index) => (
										<Col xs={12} lg={12} md={12} xl={12}>
											<Radio
												value={item.value}
												className="filter-sub-item"
											>
												{item.label}
											</Radio>
										</Col>
									))}
								</Row>
							</Radio.Group>
						</XForm.Item>,
						<XForm.Item
							name="hospital"
							label="Hospital"
							style={{ fontWeight: 'bold' }}
							colon={false}
							className="removeFormMargin"
						>
							<XSelect
								size="large"
								placeholder="Select Hospital"
								name="hospital"
								allowClear
								className="filter-sub-item"
								showSearch={true}
								defaultValue={
									filters.hospital ? filters.hospital : null
								}
								onFocus={() => {
									getHospitalDropdown();
								}}
								onSearch={(search) => {
									if (search.length >= 3) {
										getHospitalDropdown({
											search,
										});
									} else if (search.length === 0) {
										getHospitalDropdown();
									}
								}}
								onClear={() => {
									setFilters({
										...filters,
										hospital: '',
									});
								}}
								filterOption={filterByLabel}
								options={
									getHospitalDropdownState.apiState ===
										'success' &&
									getHospitalDropdownState.data.hospitals
										?.length > 0 &&
									getHospitalDropdownState.data.hospitals.map(
										(itm) => {
											return {
												label: itm.name,
												value: itm._id,
											};
										},
									)
								}
								onSelect={(value) => {
									setFilters({
										...filters,
										hospital: value,
									});
								}}
							/>
						</XForm.Item>,
						<XForm.Item name="organization" label={'Organization'}>
							<XSelect
								size="large"
								placeholder="Select Organization"
								name="organization"
								showSearch={true}
								className="removeBg"
								onFocus={() => {
									getOrganizations();
								}}
								onSearch={(search) => {
									if (search.length >= 3) {
										getOrganizations({
											search,
										});
									} else if (search.length === 0) {
										getOrganizations();
									}
								}}
								// filterOption={filterByLabel}
								options={
									getOrganizationsState.apiState ===
										'success' &&
									getOrganizationsState.data.list?.length >
										0 &&
									getOrganizationsState.data.list.map(
										(itm) => {
											return {
												label: itm.name,
												value: itm._id,
											};
										},
									)
								}
								onSelect={(value, options) => {
									setFilters({
										...filters,
										organization: value,
									});
								}}
							></XSelect>
						</XForm.Item>,
						<XForm.Item name="region" label={'Region'}>
							<XSelect
								size="large"
								placeholder="Select Region"
								name="region"
								showSearch={true}
								className="removeBg"
								onFocus={() => {
									getRegions();
								}}
								onSearch={(search) => {
									if (search.length >= 3) {
										getRegions({
											search,
										});
									} else if (search.length === 0) {
										getRegions();
									}
								}}
								filterOption={filterByLabel}
								options={
									getRegionsState.apiState === 'success' &&
									getRegionsState.data.regions?.length > 0 &&
									getRegionsState.data.regions.map((itm) => {
										return {
											label: itm.name,
											value: itm._id,
										};
									})
								}
								onSelect={(value, options) => {
									setFilters({
										...filters,
										region: value,
									});
								}}
							></XSelect>
						</XForm.Item>,
						<XForm.Item
							name="state"
							colon={false}
							label="State"
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
							className="removeFormMargin"
						>
							<XSelect
								size="large"
								allowClear
								className="filter-sub-item"
								placeholder="Select State"
								defaultValue={
									filters.state ? filters.state : null
								}
								onFocus={() => {
									getStateDropdown();
								}}
								onSearch={(search) => {
									if (search.length >= 3) {
										getStateDropdown({
											search,
										});
									} else if (search.length === 0) {
										getStateDropdown();
									}
								}}
								onClear={() => {
									setFilters({
										...filters,
										state: '',
										insurance_company: '',
									});
									form.setFieldValue(
										'insurance_company',
										null,
									);
									setInsuranceCompanies([]);
								}}
								showSearch={true}
								filterOption={filterByLabel}
								options={
									getStateDropdownState.apiState ===
										'success' &&
									getStateDropdownState.data.all_states
										?.length > 0 &&
									getStateDropdownState.data.all_states.map(
										(itm) => {
											return {
												label: itm.state,
												value: itm.state,
												providers: itm.providers,
											};
										},
									)
								}
								onSelect={(value, options) => {
									setFilters({
										...filters,
										state: value,
									});
									setInsuranceCompanies(options.providers);
								}}
							/>
						</XForm.Item>,
						<XForm.Item
							name="insurance_company"
							label="Insurance Company"
							colon={false}
							className="removeFormMargin"
						>
							<XSelect
								size="large"
								placeholder="Select Insurance Company"
								name="insurance_company"
								allowClear
								className="filter-sub-item"
								defaultValue={
									filters.insurance_company
										? filters.insurance_company
										: null
								}
								showSearch={true}
								onClear={() => {
									setFilters({
										...filters,
										insurance_company: null,
									});
								}}
								filterOption={filterByLabel}
								options={
									insuranceCompanies.length > 0 &&
									insuranceCompanies.map((itm) => {
										return {
											label: itm.name,
											value: itm._id,
										};
									})
								}
								onSelect={(value) => {
									setFilters({
										...filters,
										insurance_company: value,
									});
								}}
							/>
						</XForm.Item>,
						<XForm.Item
							name="created_on"
							colon={false}
							label="Date Within"
							className="removeFormMargin"
						>
							<Radio.Group
								onChange={onChange}
								name="created_on"
								defaultValue={filters.created_on}
							>
								<Row>
									{dateFilters.map((item, index) => (
										<Col xs={12} lg={12} md={12} xl={12}>
											<Radio
												value={item.value}
												className="filter-sub-item"
											>
												{item.label}
											</Radio>
										</Col>
									))}
								</Row>
							</Radio.Group>
						</XForm.Item>,
					]}
					onClose={onClose}
					open={open}
					applyFilters={applyFilters}
					clearFilters={clearFilters}
					form={form}
				/>
				<Row gutter={(15, 30)} style={{ padding: '20px ' }}>
					<Col
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
						xs={24}
						md={10}
						lg={3}
						xl={3}
						xxl={3}
					>
						<p className="tableHeader">
							Total {totalRecords} Cases
						</p>
					</Col>
					<Col
						xs={24}
						md={14}
						lg={{ span: 6 }}
						xl={{ span: 6 }}
						xxl={{ span: 6 }}
					>
						<XForm.Item>
							<XSearch
								placeholder="Search for Cases"
								allowClear
								enterButton="Search"
								size="large"
								defaultValue={
									filters.patient_name
										? filters.patient_name
										: null
								}
								onChange={(v) => {
									if (v.target.value === '') {
										urlPagination(
											filters,
											'patient_name',
											'',
										);
										setFilters({
											...filters,
											patient_name: '',
										});
									}
								}}
								onSearch={(v) => {
									urlPagination(filters, 'patient_name', v);
									setFilters({
										...filters,
										patient_name: v,
									});
								}}
							/>
						</XForm.Item>
					</Col>
					<Col
						xs={24}
						md={12}
						lg={{ span: 4, offset: 7 }}
						xl={{ span: 4, offset: 7 }}
						xxl={{ span: 4, offset: 7 }}
					>
						<XForm.Item className="text-right">
							<FilterButton
								onClick={() => showDrawer()}
								icon={
									<img
										src="/images/filter-add.svg"
										alt="bell"
										height={'15px'}
									/>
								}
							>
								Filters
							</FilterButton>
						</XForm.Item>
					</Col>
					<Col
						xs={24}
						md={12}
						lg={{ span: 4 }}
						xl={{ span: 4 }}
						xxl={{ span: 4 }}
					>
						{checkModulePermissions(
							'ma-plan-appeals-processing',
						).sub_modules.includes('add-case') ? (
							<XForm.Item className="text-right">
								<XButton2
									onClick={() => navigate('/case/add')}
									icon={<PlusOutlined />}
								>
									New Case
								</XButton2>
							</XForm.Item>
						) : (
							<></>
						)}
					</Col>
				</Row>
				<DataTable
					tableData={tableData}
					columns={columns}
					totalRecords={totalRecords}
					setFilters={setFilters}
					filters={filters}
					state={getCasesState.apiState}
					page={'case'}
				/>
			</Card>
		</>
	);
};

const mapStateToProps = (state) => ({
	getCasesState: state.getCases,
	deleteCaseState: state.deleteCase,
	getStateDropdownState: state.getStateDropdown,
	getHospitalDropdownState: state.getHospitalDropdown,
	getInsuranceDropdownState: state.getInsuranceDropdown,
	getRegionsState: state.getRegions,
	getOrganizationsState: state.getOrganizations,
});

const mapDispatchToProps = (dispatch) => ({
	getCases: (data) => dispatch(getCasesApi(data)),
	getCasesReset: () => dispatch(getCasesReset()),
	deleteCase: (data) => dispatch(deleteCaseApi(data)),
	deleteCaseReset: () => dispatch(deleteCaseReset()),
	getStateDropdown: (params) => dispatch(getStateDropdownApi(params)),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getHospitalDropdown: (params) => dispatch(getHospitalDropdownApi(params)),
	getHospitalDropdownReset: () => dispatch(getHospitalDropdownReset()),
	getInsuranceDropdown: (params) => dispatch(getInsuranceDropdownApi(params)),
	getInsuranceDropdownReset: () => dispatch(getInsuranceDropdownReset()),
	getRegions: (params) => dispatch(getRegionsApi(params)),
	getOrganizations: (data) => dispatch(getOrganizationsApi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement);
