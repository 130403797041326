import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import {
	Row,
	Col,
	Input,
	Button,
	Space,
	Form,
	// Timeline,
	Result,
	Tooltip,
	notification,
	Tag,
	Select,
	Upload,
	Modal,
	InputNumber,
	Card,
	DatePicker,
	Avatar,
	Image,
	Affix,
	Checkbox,
	Radio,
	List,
	Skeleton,
	FloatButton,
	Divider,
	Spin,
} from 'antd';
import axios from 'axios';
import AppealLoading from '../../json/reloading.json';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XTag,
	XCardsCriteriaBg,
	XCardsTransparent,
	XAffix,
	XSelect,
	XRoundButton,
	XList,
	XCircle,
	XLabel,
	XModalTitle,
	XModalSubtitle,
	ChatButton,
	ChatBoxWrap,
	XShareButton,
} from '../../styles/GlobalStyle';
import {
	ArrowLeftOutlined,
	WarningOutlined,
	PlusOutlined,
	EditOutlined,
	FileTextOutlined,
	FileImageOutlined,
	UserOutlined,
	AntDesignOutlined,
	DeleteOutlined,
	CloseOutlined,
	FilePdfOutlined,
	InboxOutlined,
	EyeOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import HeaderFile from '../../components/Header/HeaderFile';
import Header from '../../components/Header/Header';
import ChatBox from '../../components/Chat/Chat';
import {
	XCardPreview,
	XCardInner,
	XTabs,
	XRadio,
} from './AppealLetterPreviewStyle';
import {
	XButtonConfirm,
	XButtonLike,
	XDivider,
	XLottieOverlay,
	XPara1,
	XRecommendedCard,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import {
	ApprovHeading,
	CaseTimelineTitle,
	ResponseHeading,
	XAddCodeSticky,
	XCardBackBg,
} from '../CaseForm/CaseFormStyle';
import {
	getAppealLetterApi,
	getAppealLetterReset,
} from '../../actions/getAppealLetterAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import {
	getVerifyApproveAppealLetterUserListReset,
	getVerifyApproveAppealLetterUserListApi,
} from '../../actions/getVerifyApproveAppealLetterUserListAction';
import {
	saveAppealLetterApi,
	saveAppealLetterReset,
} from '../../actions/saveAppealLetterAction';
import { handleInputChange } from '../../utils/formFunctions';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import {
	checkModulePermissions,
	getUserToken,
	toTitleCase,
} from '../../utils/helper';
import config from '../../config';
import { XLottie } from '../Login/LoginStyle';
import DownloadAnimation from '../../json/download.json';
import DrawerCodingTools from '../../components/CodingTools/DrawerCodingTools';
const { TextArea } = Input;
const { Dragger } = Upload;

const AppealLetterPreview = (props) => {
	const location = useLocation();
	// Get the id from the URL
	const { id } = useParams();

	const {
		getAppealLetter,
		getAppealLetterState,
		getAppealLetterReset,
		editCase,
		editCaseState,
		editCaseReset,
		getVerifyApproveAppealLetterUserList,
		getVerifyApproveAppealLetterUserListState,
		getVerifyApproveAppealLetterUserListReset,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
		saveAppealLetter,
		saveAppealLetterState,
		saveAppealLetterReset,
	} = props;
	const navigate = useNavigate();
	const [appealForm] = XForm.useForm();
	const editorRef = useRef(null);
	const {
		appealLetterType,
		// id,
		patientDetails,
		isEdit,
		content,
		header,
		footer,
		// regenerateFlag,
	} = location.state || {};

	const [sendTeamMember, setSendTeamMember] = useState('team');
	const [appealLetterContent, setAppealLetterContent] = useState(content);
	const [appealLetterHeader, setAppealLetterHeader] = useState(header);
	const [appealLetterFooter, setAppealLetterFooter] = useState(footer);
	const [appealLetterLoading, setAppealLetterLoading] = useState(false);
	const [editAppealLetterLoading, setEditAppealLetterLoading] =
		useState(false);
	const [selectMember, setSelectMember] = useState(1);
	const [appealLetterUsers, setAppealLetterUsers] = useState([]);
	const [editFlag, setEditFlag] = useState(isEdit || false);

	const [chatOpen, setChatOpen] = useState(false);
	const [isMdDisabled, setIsMdDisabled] = useState(false);
	const [isCodingDisabled, setIsCodingDisabled] = useState(false);
	const [activeKey, setActiveKey] = useState('1');

	const sendToCodingTeam = (formValues) => {
		// setSubmitCase(false);
		setAppealLetterLoading(true);

		editCase({
			...formValues,
			id: id,
			status: 'sent_for_verification',
			status_remarks: 'Appeal Letter sent for Verification',
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			sent_to_coding_team: true,
			// appeal_status: 'sent',
			// appeal_status_updated_at: moment(),
		});
		// setAppealLetterLoading(false)
		//
		// ApproveModalOpen(false);
	};

	const [isAppealVerifyApproveModalOpen, setIsAppealVerifyApproveModalOpen] =
		useState(
			isCodingDisabled
				? ['', '', '']
				: [
						'Send To Coding Team for Verification',
						sendToCodingTeam,
						'coding-team',
				  ],
		);

	const [downloadLoading, setDownloadLoading] = useState(false);
	const [formData, setFormData] = useState({});
	const [patientData, setPatientData] = useState(patientDetails || {});
	const [editAppealLetterSelected, setEditAppealLetterSelected] =
		useState(false);
	// const [showAppealLetterPreview, setShowAppealLetterPreview] =
	// 	useState(false);
	const [showEditAppealLetter, setShowEditAppealLetter] = useState(
		isEdit || false,
	);
	const [mainDataLoading, setMainDataLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isAnalyse, setIsAnalyse] = useState(false);
	// for send appeal letter
	const [displayFaxUpload, setDisplayFaxUpload] = useState(false);
	const [displayEmailContent, setDisplayEmailContent] = useState(false);
	const [letterDownloaded, setLetterDownloaded] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [sendLetterLoading, setSendLetterLoading] = useState(false);
	// State for holding filters
	const [icdform] = XForm.useForm();
	const [cptform] = XForm.useForm();
	const [icdFilters, setIcdFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
		related: false,
		childCode: '',
	});

	//CPT Codes
	const [cptFilters, setCptFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
	});
	const [openIcdCptDrawer, setOpenIcdCptDrawer] = useState(false);
	const [openIcdCptDrawer2, setOpenIcdCptDrawer2] = useState(false);
	const [activeCode, setActiveCode] = useState(null);
	const [icdCodes, setIcdCodes] = useState([]); // To store fetched ICD codes
	const [searchResults, setSearchResults] = useState([]); // To store search results
	const [icdLoading, setIcdLoading] = useState(false); // To manage loading state
	const [triggeredBySearch, setTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [triggeredByEnter, setTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [loadMore, setLoadMore] = useState(false); // To track search-triggered calls
	const [icdSearchLoading, setIcdSearchLoading] = useState(false); // To manage loading state
	const [cptCodes, setCptCodes] = useState([]); // To store fetched ICD codes
	const [cptLoading, setCptLoading] = useState(false); // To manage loading state
	const [cptSearchResults, setCptSearchResults] = useState([]); // To store search results
	const [cptTriggeredBySearch, setCptTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [cptTriggeredByEnter, setCptTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [cptLoadMore, setCptLoadMore] = useState(false); // To track search-triggered calls
	const [cptSearchLoading, setCptSearchLoading] = useState(false); // To manage loading state

	// Example of handleCodeClick
	const handleCodeClick = (codeType, codeData) => {
		// Check if the code has leading or trailing spaces
		let trimmedCode = codeData.code;

		if (trimmedCode !== trimmedCode.trim()) {
			trimmedCode = trimmedCode.trim();
		}

		// // Check if it's a child code
		// const isChildCode = trimmedCode.includes('.');
		// const parentCode = isChildCode
		// 	? trimmedCode.split('.')[0]
		// 	: trimmedCode;
		setIcdCodes();
		closeIcdCptDrawer();
		setActiveCode({
			type: codeType,
			code: codeData.code,
			description: codeData.description,
			codeTypeText: codeData.codeTypeText,
			buttontext: codeData.buttontext,
		});

		if (codeType === 'CPT') {
			// // Set filters to fetch related codes
			setCptFilters({
				related: true,
				childCode: trimmedCode,
			});
			setCptTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
		} else {
			// Set filters to fetch related codes
			setIcdFilters({
				related: true,
				childCode: trimmedCode,
			});
			setTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
		}
	};

	const showIcdCptDrawer = () => {
		setOpenIcdCptDrawer(true);
		setIcdCodes();
		setActiveCode(null);
		setSearchResults();
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptCodes();
		setCptSearchResults();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer = () => {
		setOpenIcdCptDrawer(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer2 = () => {
		setOpenIcdCptDrawer2(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	useEffect(() => {
		// Check if the page is being accessed by navigation or refresh
		const navigatedFromAnotherPage = sessionStorage.getItem(
			'navigatedFromAnotherPage',
		);
		if (id) {
			if (navigatedFromAnotherPage) {
				// This is a page refresh; call the API
				setLoading(true);
				setIsAnalyse(true);
				getAppealLetter({
					id: id,
					type: appealLetterType,
					regenerate_appeal_letter: false,
				});
			} else {
				// This means we arrived on this page from another page, set the flag
				sessionStorage.setItem('navigatedFromAnotherPage', 'true');
			}
		}
		// Cleanup on unmount to remove the flag when navigating away
		return () => {
			sessionStorage.removeItem('navigatedFromAnotherPage');
		};
	}, [location, id]);

	// useEffect(() => {
	// 	if (id) {
	// 		setLoading(true);
	// 		getAppealLetter({
	// 			id: id,
	// 			type: appealLetterType,
	// 			regenerate_appeal_letter: false,
	// 		});
	// 	}
	// 	// if (editFlag) {
	// 	// 	setLoading(true);
	// 	// 	editAppealLetter(appealLetterType);
	// 	// }
	// }, [id]);

	useEffect(() => {
		if (getAppealLetterState.apiState === 'success') {
			// setLoading(false);
			setAppealLetterLoading(false);

			if (isAnalyse) {
				getAnalysis({ id: id });
			}
			setIsAnalyse(true);
			setAppealLetterContent(getAppealLetterState.data.appeal_letter);
			setAppealLetterHeader(getAppealLetterState.data.header);
			setAppealLetterFooter(getAppealLetterState.data.footer);
			if (editAppealLetterSelected || editFlag) {
				setShowEditAppealLetter(true);
				setEditFlag(false);
			}

			// if (editFlag) {
			// 	setLoading(true);
			// 	editAppealLetter(appealLetterType);
			// }
		} else if (getAppealLetterState.apiState === 'error') {
			setAppealLetterLoading(false);
			setLoading(false);
			notification.error({
				message: getAppealLetterState.message
					? getAppealLetterState.message
					: 'Unable to get Appeal Letter content',
			});
		}
		getAppealLetterReset();
	}, [getAppealLetterState.apiState]);

	const getCodingTeamButtonFunction = (status, remark, value) => {
		if (patientData?.appeal_letter_status === 'coding_team_sent') {
			return codingTeamApproved(status, remark, value);
		}
	};

	const codingTeamApproved = (status, remark, coding_team_status) => {
		setAppealLetterLoading(true);
		editCase({
			id: id,
			status: status,
			status_remarks: `Appeal letter ${remark}`,
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			coding_team_status: coding_team_status,
		});
	};

	useEffect(() => {
		if (getVerifyApproveAppealLetterUserListState.apiState === 'success') {
			let dropdown = [];
			getVerifyApproveAppealLetterUserListState.data.map((ele) => {
				return dropdown.push({
					label: `${ele.name}`,
					value: ele._id,
				});
			});
			setAppealLetterUsers(dropdown);
			getVerifyApproveAppealLetterUserListReset();
		} else if (
			getVerifyApproveAppealLetterUserListState.apiState === 'error'
		) {
			setAppealLetterUsers([]);
			notification.error({
				message: 'Unable to save Appeal Letter content',
			});
			getVerifyApproveAppealLetterUserListReset();
		}
	}, [getVerifyApproveAppealLetterUserListState]);

	useEffect(() => {
		if (editCaseState.apiState === 'loading') {
			setAppealLetterLoading(true);
		}
		if (editCaseState.apiState === 'success') {
			if (sendLetterLoading) {
				setSendLetterLoading(false);
				navigate(-1);
			}
			getAnalysis({ id: id });

			appealForm.setFieldsValue({
				appeal_letter_assigned_to: '',
				appeal_letter_remarks: '',
			});
			appealForm.resetFields('');
			setSelectMember(1);
			// setIsAppealVerifyApproveModalOpen(['', '', '']);
			setAppealLetterLoading(false);
			// notification.success({
			// 	message: editCaseState.message,
			// });
		} else if (editCaseState.apiState === 'error') {
			setAppealLetterLoading(false);
			setSendLetterLoading(false);
			setDownloadLoading(false);
			notification.error({
				message: 'Unable to confirm Appeal letter',
			});
		}
		editCaseReset();
	}, [editCaseState.apiState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'loading') {
			setMainDataLoading(true);
		}
		if (getAnalysisState.apiState === 'success') {
			setLoading(false);
			setMainDataLoading(false);
			setPatientData(getAnalysisState.data.case_details);
		}
		if (getAnalysisState.apiState === 'error') {
			setMainDataLoading(false);
		}
		getAnalysisReset();
	}, [getAnalysisState.apiState]);

	useEffect(() => {
		if (saveAppealLetterState.apiState === 'success') {
			setAppealLetterContent('');
			setAppealLetterHeader('');
			setAppealLetterFooter('');
			setEditAppealLetterLoading(false);
			setShowEditAppealLetter(false);
			setAppealLetterLoading(false);
			setLoading(true);
			getAppealLetter({
				id: id,
				type: appealLetterType,
				regenerate_appeal_letter: false,
			});
			saveAppealLetterReset();
		} else if (saveAppealLetterState.apiState === 'error') {
			setAppealLetterLoading(false);
			setShowEditAppealLetter(false);
			notification.error({
				message: 'Unable to save Appeal Letter content',
			});
			saveAppealLetterReset();
		}
	}, [saveAppealLetterState.apiState]);

	const sendAppealLetterForApproval = (formValues) => {
		setAppealLetterLoading(true);
		editCase({
			...formValues,
			id: id,
			status: 'letter_prepared',
			status_remarks: 'Appeal Letter Sent for approval',
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			send_for_approval: true,
		});
	};

	const handleTabsChange = (key) => {
		setActiveKey(key);
		if (key === '1') {
			setIsAppealVerifyApproveModalOpen([
				'Send To Coding Team for Verification',
				sendToCodingTeam,
				'coding-team',
			]);
		} else {
			setIsAppealVerifyApproveModalOpen([
				'Send To MD Advisor for Approval',
				sendAppealLetterForApproval,
				'md-advisor',
			]);
		}
		setSelectMember(1);
		setAppealLetterUsers([]);
		appealForm.resetFields('');
		appealForm.setFieldsValue({});
	};

	const confirmAppealLetter = (status) => {
		setAppealLetterLoading(true);
		editCase({
			id: id,
			status: 'letter_prepared',
			status_remarks: 'Appeal letter confirmed',
			appeal_letter: appealLetterContent,
			type: appealLetterType,
			appeal_letter_status: status,
		});
	};

	const editAppealLetterFromModal = () => {
		// editAppealLetter(appealLetterType);
		setShowEditAppealLetter(true);
	};

	const editAppealLetter = (type) => {
		// getAppealLetter({ id: id, type: type });
		// setEditAppealLetterSelected(true);
	};

	const closeEditAppealLetter = () => {
		setShowEditAppealLetter(false);
		setEditAppealLetterSelected(false);
	};

	const saveAndPreviewAppealLetter = () => {
		if (editorRef.current) {
			setEditAppealLetterLoading(true);
			setEditAppealLetterSelected(false);
			saveAppealLetter({
				id: id,
				appeal_letter: editorRef.current.getContent(),
				type: appealLetterType,
			});
		}
	};

	useEffect(() => {
		patientData?.appeal_letter_status === 'md_advisor_sent' &&
		patientData?.case_amount >=
			patientData?.appeal_letter_approval_threshold
			? setIsMdDisabled(true)
			: patientData?.appeal_letter_status === 'md_advisor_sent' &&
			  patientData?.case_amount <
					patientData?.appeal_letter_approval_threshold &&
			  checkModulePermissions('appeal-letter')
			? setIsMdDisabled(true)
			: checkModulePermissions('appeal-letter')
			? localStorage.getItem('role') === 'coding-team' ||
			  localStorage.getItem('role') === 'md-advisor'
				? setIsMdDisabled(true)
				: // .sub_modules.includes(
				// 		'confirm-letter',
				//   )
				//   localStorage.getItem('role') !== 'coding-team' &&
				//   localStorage.getItem('role') !== 'md-advisor' &&
				[
						'md_advisor_sent',
						'md_advisor_approved',
						'coding_team_sent',
				  ].includes(patientData.appeal_letter_status)
				? setIsMdDisabled(true)
				: patientData?.case_amount <
						patientData?.appeal_letter_approval_threshold &&
				  checkModulePermissions('appeal-letter')
				? //   .sub_modules.includes(
				  // 		'confirm-letter',
				  //   )
				  setIsMdDisabled(false)
				: localStorage.getItem('role') !== 'md-advisor'
				? setIsMdDisabled(false)
				: setIsMdDisabled(true)
			: setIsMdDisabled(true);
	}, [patientData]);

	useEffect(() => {
		if (
			patientData?.appeal_letter_status === 'coding_team_sent' ||
			localStorage.getItem('role') === 'coding-team' ||
			localStorage.getItem('role') === 'md-advisor' ||
			patientData?.appeal_letter_status === 'md_advisor_sent'
		) {
			setIsCodingDisabled(true); // Condition met, disable
		} else {
			setIsCodingDisabled(false); // Condition not met, enable
		}
	}, [patientData]);

	// for send appeal letter

	const dateOfBirth = (inputDateString) => {
		const date = new Date(inputDateString);

		// Extract the year, month, and day components from the Date object
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is zero-based, so add 1
		const day = date.getDate();

		// Format the date as "YYYY-MM-DD" or any desired format
		return `${year}-${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}`;
	};

	useEffect(() => {
		if (displayEmailContent && !letterDownloaded) {
			getDownloadSignedURL();
			setDownloadLoading(true);
		}
	}, [displayEmailContent]);

	const handleEmailSend = () => {
		const subject = `Case Details: ${patientData.patient_first_name} ${patientData.patient_last_name} (MRN: ${patientData.mrn})`;
		const body = `Hi,\n\nI hope this email finds you well. Below are the case details regarding denials made by ${
			patientData.insurance_company?.name || ''
		}.\n\nMRN: ${patientData.mrn}\nName: ${
			patientData.patient_first_name
		} ${patientData.patient_last_name}\nAge: ${moment(
			patientData.date_of_birth,
		).fromNow(true)},\nGender: ${
			patientData.gender ? toTitleCase(patientData.gender) : ''
		}\nDOB: ${
			patientData.date_of_birth
				? dateOfBirth(patientData.date_of_birth)
				: ''
		}\nState: ${patientData.state}\nInsurance Company: ${
			patientData.insurance_company?.name || '-'
		}\n\nClinical Summary: ${patientData.clinical_summary}.`;
		const mailtoLink = `mailto:?subject=${encodeURIComponent(
			subject,
		)}&body=${encodeURIComponent(body)}`;
		// Open the email client
		window.open(mailtoLink);
		// setDisplayEmailContent(false);
		// setLetterDownloaded(false);
		setDownloadLoading(false);
		editCase({
			id: id,
			appeal_status: 'sent',
			appeal_status_updated_at: moment(),
		});
		// navigate(-1);
		// generateAppealLetterReset();
	};

	const getDownloadSignedURL = async () => {
		try {
			let length = patientData.appeal_letter_logs.length - 1;
			const response = await axios.get(
				`${config.api.base_url}/settings/get-Appeal-Signed-Url?fileName=${patientData.appeal_letter_logs[length]?.url[0]?.url}`,
				{
					headers: {
						'Content-type': 'application/json; charset=UTF-8',
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			);
			let res = response.data;
			if (res.status === 1) {
				const pdfResponse = await fetch(res.data);
				const arrayBuffer = await pdfResponse.arrayBuffer();

				const blob = new Blob([arrayBuffer], {
					type: 'application/pdf',
				});
				saveAs(blob, 'Appeal-letter.pdf');

				notification.success({
					message: 'Appeal Letter downloaded',
				});

				if (displayEmailContent) {
					setDisplayEmailContent(true);
					setLetterDownloaded(true);
					setDownloadLoading(false);
				} else {
					editCase({
						id: id,
						appeal_status: 'sent',
						appeal_status_updated_at: moment(),
					});
					setDownloadLoading(false);
					setLetterDownloaded(false);
					setDisplayEmailContent(false);
					setDisplayFaxUpload(false);
					setSendLetterLoading(true);
					// navigate(-1);
				}

				setDownloadLoading(false);
			} else {
				setDownloadLoading(false);
				setLetterDownloaded(false);
				setDisplayEmailContent(false);
				setDisplayFaxUpload(false);
				notification.error({
					message: 'Unable to download Appeal Letter',
				});
			}
		} catch (error) {
			notification.success({
				message: 'Could not upload',
			});
			setDownloadLoading(false);
			setLetterDownloaded(false);
			setDisplayEmailContent(false);
			setDisplayFaxUpload(false);
			setDownloadLoading(false);
			console.error('Error occurred:', error);
		}
	};

	return (
		<>
			{downloadLoading && (
				<XLottieOverlay>
					<XLottie animationData={DownloadAnimation} loop={true} />
				</XLottieOverlay>
			)}
			{loading && (
				<XLottieOverlay>
					<XLottie animationData={AppealLoading} loop={true} />
				</XLottieOverlay>
			)}
			<Col lg={24} xl={24} xs={0} md={0}>
				<Header
					headerTag="Case Filings"
					breadcrumb="true"
					preview="true"
					previewId="Appeal Letter Preview"
					caseId={patientData?.case_id}
				/>
			</Col>
			<Col lg={0} xl={0} xs={24} md={24}>
				<HeaderFile activeNav="caseManagement" />
			</Col>

			<XContainer fluid>
				<Row gutter={(0, 30)}>
					<Col xs={24} sm={24} md={24} lg={14} xl={16}>
						<XCardPreview>
							<div
								style={{
									// height: '700px',
									overflowX: 'hidden',
									// overflowY: 'auto',
									textAlign: 'justify',
									padding: '0 5px',
								}}
								className="appeal-letter-modal"
							>
								{/* edit button */}
								{checkModulePermissions(
									'appeal-letter',
								).sub_modules.includes('edit-letter') ? (
									<XButtonLike
										type="primary"
										style={{
											float: 'right',
										}}
										onClick={editAppealLetterFromModal}
									>
										<EditOutlined
											style={{
												fontSize: '20px',
											}}
										/>
									</XButtonLike>
								) : (
									<></>
								)}
								{/* edit button */}
								<div
									dangerouslySetInnerHTML={{
										__html: appealLetterHeader,
									}}
								/>
								<XDivider />
								<div
									dangerouslySetInnerHTML={{
										__html: appealLetterContent,
									}}
								/>
								<XDivider />
								<div
									dangerouslySetInnerHTML={{
										__html: appealLetterFooter,
									}}
								/>
								<br />
							</div>
							{/* footer start */}

							{/* <div
								style={{
									background: `${
										patientData.appeal_letter_remarks
											? '#e0e0e0'
											: ''
									}`,
									padding: '10px',
								}}
							> */}
							{/* {patientData.appeal_letter_remarks ? (
									<>
										<p
											style={{
												fontWeight: 'bold',
												fontSize: '16px',
												textAlign: 'start',
											}}
										>
											Query
										</p>
										<p
											style={{
												textAlign: 'justify',
											}}
										>
											{patientData.appeal_letter_remarks}
										</p>
									</>
								) : (
									<></>
								)} */}
							{/* <div
									key="buttonsContainer"
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										gap: '10px',
										alignItems: 'center',
										marginTop: '10px',
									}}
								>
									{patientData?.appeal_letter_status ===
									'coding_team_sent' ? (
										checkModulePermissions(
											'appeal-letter',
										).sub_modules.includes(
											'verify-coding-team',
										) ? (
											<Button
												loading={appealLetterLoading}
												key="approvedByCodingTeam"
												type="primary"
												onClick={() =>
													getCodingTeamButtonFunction(
														'sent_for_verification',
														'Verified',
														'coding_team_approved',
													)
												}
												style={{
													background: `black`,
												}}
											>
												Approve
											</Button>
										) : (
											<></>
										)
									) : (
										<></>
									)}
									{patientData?.appeal_letter_status ===
										'md_advisor_sent' &&
									patientData?.case_amount >=
										patientData?.appeal_letter_approval_threshold ? (
										checkModulePermissions(
											'appeal-letter',
										).sub_modules.includes(
											'confirm-letter',
										) ? (
											<>
												<Button
													loading={
														appealLetterLoading
													}
													key="approvedByCodingTeam"
													type="primary"
													onClick={() => {
														confirmAppealLetter(
															'md_advisor_approved',
														);
													}}
													style={{
														background: `black`,
													}}
												>
													Approve
												</Button>
											</>
										) : (
											<></>
										)
									) : patientData?.case_amount <
											patientData?.appeal_letter_approval_threshold &&
									  checkModulePermissions(
											'appeal-letter',
									  ).sub_modules.includes(
											'confirm-letter',
									  ) ? (
										<>
											<Button
												loading={appealLetterLoading}
												key="approvedByCodingTeam"
												type="primary"
												onClick={() =>
													confirmAppealLetter(
														'md_advisor_approved',
													)
												}
												style={{
													background: `black`,
												}}
											>
												Approve
											</Button>
										</>
									) : (
										<></>
									)}
								</div> */}
							{/* </div> */}
							{/* footer end */}
							{/* <Row
								gutter={[15, 15]}
								className="align-items-center"
							>
								<Col xs={12}>
									<img
										className="img-fluid"
										style={{ maxWidth: '100px' }}
										src="/images/preview-logo.png"
										alt=""
									/>
								</Col>
								<Col xs={12} className="text-end">
									<XButtonConfirm
										icon={
											<img
												src="/images/edit-clinical-icon.svg"
												style={{
													width: '20px',
												}}
												alt="Edit"
											/>
										}
										className="mb-2 mb-md-0 mb-sm-0"
										type="primary"
										size="large"
										shape="round"
										style={{
											background: '#fff',
										}}
									>
										Edit
									</XButtonConfirm>
								</Col>
							</Row> */}
							{/* <XCardInner className="mt-4">
								<div className="cardHeader">
									<Row
										gutter={[15, 15]}
										className="align-items-center"
									>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												Patient :<span>{'bush'}</span>
											</div>
										</Col>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												MRN :<span>{'mrn'}</span>
											</div>
										</Col>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												DOS :<span>Dec 12, 2023</span>
											</div>
										</Col>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												Dec 12, 2023 :
												<span>Mar 9, 1939</span>
											</div>
										</Col>
										<Col xs={12} sm={8} lg={6}>
											<div className="headerTitles">
												Visit # :<span>&nbsp;</span>
											</div>
										</Col>
									</Row>
								</div>
								<div className="hadPadding">
									<Row>
										<Col xs={24}>
											<p className="para2">
												This letter is in reference to
												the claim submitted for the
												medical services provided to a
												patient with a history of type 2
												diabetes mellitus, uncontrolled
												hypertension, and a recent
												diagnosis of ischemic heart
												disease. The patient presented
												with symptoms of chest pain and
												shortness of breath, and upon
												evaluation, a coronary
												angiography was performed,
												revealing significant stenosis
												in multiple vessels. We are
												seeking coverage for the
												coronary angiography, CPT Code:
												93458, as part of the patient's
												treatment plan.(1,2).
											</p>
											<p className="para2">
												According to the Local Coverage
												Determination (LCD) section
												L35121, coverage for coronary
												angiography is indicated when
												there is a medical necessity for
												the procedure. In this case, the
												patient's clinical presentation
												and the subsequent findings from
												the coronary angiography align
												with the policy guidelines,
												establishing the procedure as
												medically necessary and
												therefore eligible for coverage
												under the patient's insurance
												plan.
											</p>
											<p className="para2">
												The medical necessity for the
												coronary angiography is
												substantiated by the patient's
												symptoms and the significant
												stenosis found in multiple
												vessels, which required
												immediate and accurate diagnosis
												to guide further treatment. The
												documentation of the patient's
												condition and the results of the
												angiography are comprehensive
												and in accordance with the
												policy guidelines. The
												angiography was essential in
												determining the appropriate
												management and therapeutic
												interventions for the patient's
												ischemic heart disease, thereby
												justifying the claim for
												coverage.
											</p>
											<p className="para2">
												We kindly request your prompt
												attention to this matter and
												would appreciate your positive
												response to this claim. Should
												you require any additional
												information or documentation to
												process this claim, please do
												not hesitate to contact us. We
												thank you for your time and
												consideration in reviewing this
												appeal.
											</p>
											<p className="para2">
												Thank you for your time and
												reconsideration.
											</p>
											<p className="para2">Sincerely,</p>
											<p className="para2">
												Amin Aiolos <br />
												DecodeX
											</p>
										</Col>
									</Row>
									<Divider
										style={{ borderColor: '#d7dade' }}
										dashed
									/>
									<Row gutter={[15, 15]}>
										<Col xs={24}>
											<div className="listTitle">
												Supporting Documents
											</div>
										</Col>
										<Col xs={24}>
											<ul className="listItems">
												<li>Dummy Sample Doc</li>
												<li>Job Doc</li>
												<li>Denial Sample Doc</li>
											</ul>
										</Col>
									</Row>
									<Divider
										style={{ borderColor: '#d7dade' }}
										dashed
									/>
									<Row gutter={[15, 15]}>
										<Col xs={24}>
											<p className="para2">
												Mayo Clinic Laboratories 3050
												Superior Drive NW Rochester, MN
												55905 mcl@mayo.edu
											</p>
										</Col>
									</Row>
								</div>
							</XCardInner> */}
						</XCardPreview>
					</Col>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={10}
						xl={8}
						className="mt-4 mt-lg-0"
					>
						<XAffix offsetTop={20}>
							<XCardBackBg>
								{checkModulePermissions(
									'appeal-letter',
								).sub_modules.includes('share-letter') &&
								patientData.appeal_letter_status ===
									'md_advisor_approved' ? (
									<>
										<CaseTimelineTitle className="mb-3">
											Send To
										</CaseTimelineTitle>
										{/* new code start */}
										<Row gutter={[15, 15]}>
											<Col xs={24}>
												{displayEmailContent ? (
													<XRecommendedCard
														role="button"
														className="shareModalActive"
														onClick={() => {
															setDisplayFaxUpload(
																false,
															);
															if (
																!letterDownloaded
															) {
																setDisplayEmailContent(
																	true,
																);
															}
														}}
													>
														<Row className="align-items-center">
															<Col
																className="text-center"
																xs={4}
															>
																<img
																	src="/images/envelope 1 copy.svg"
																	alt="envelope"
																/>
															</Col>
															<Col xs={20}>
																<p className="m-0">
																	Send Via
																	Email
																</p>
															</Col>
														</Row>
													</XRecommendedCard>
												) : (
													<XRecommendedCard
														role="button"
														className="shareModal"
														onClick={() => {
															setDisplayFaxUpload(
																false,
															);
															if (
																!letterDownloaded
															) {
																setDisplayEmailContent(
																	true,
																);
															}
														}}
													>
														<Row className="align-items-center">
															<Col
																className="text-center"
																xs={4}
															>
																<img
																	src="/images/envelope 1.svg"
																	alt="emvelope"
																/>
															</Col>
															<Col xs={20}>
																<p className="m-0">
																	Send Via
																	Email
																</p>
															</Col>
														</Row>
													</XRecommendedCard>
												)}
											</Col>

											{/* <Col></Col> */}
										</Row>
										<Row gutter={[15, 15]}>
											<Col xs={24}>
												{displayFaxUpload ? (
													<XRecommendedCard
														role="button"
														className="shareModalActive mt-2"
														onClick={() => {
															setDisplayEmailContent(
																false,
															);
															setDisplayFaxUpload(
																true,
															);
															setLetterDownloaded(
																false,
															);
														}}
													>
														<Row className="align-items-center">
															<Col
																className="text-center"
																xs={4}
															>
																<img
																	src="/images/fax-active.svg"
																	alt="fax"
																/>
															</Col>
															<Col xs={20}>
																<p className="m-0">
																	Share Via
																	Fax
																</p>
															</Col>
														</Row>
													</XRecommendedCard>
												) : (
													<XRecommendedCard
														role="button"
														className="shareModal mt-2"
														onClick={() => {
															setDisplayEmailContent(
																false,
															);
															setDisplayFaxUpload(
																true,
															);
															setLetterDownloaded(
																false,
															);
														}}
													>
														<Row className="align-items-center">
															<Col
																className="text-center"
																xs={4}
															>
																<img
																	src="/images/fax 1.svg"
																	alt="fax"
																/>
															</Col>
															<Col xs={20}>
																<p className="m-0">
																	Share Via
																	Fax
																</p>
															</Col>
														</Row>
													</XRecommendedCard>
												)}
											</Col>
										</Row>
										<Row gutter={[15, 15]}>
											<Col xs={24}>
												<XRecommendedCard
													role="button"
													className="shareModal mt-2"
													onClick={() => {
														setDisplayFaxUpload(
															false,
														);
														setDisplayEmailContent(
															false,
														);
														getDownloadSignedURL();
														setDownloadLoading(
															true,
														);
													}}
												>
													<Row className="align-items-center">
														<Col
															className="text-center"
															xs={4}
														>
															{sendLetterLoading &&
															!displayFaxUpload &&
															!displayEmailContent ? (
																<Spin
																	indicator={
																		<LoadingOutlined
																			spin
																		/>
																	}
																	size="small"
																/>
															) : (
																<img
																	src="/images/download 1.svg"
																	alt="download"
																/>
															)}
														</Col>
														<Col xs={20}>
															<p className="m-0">
																Download PDF
															</p>
														</Col>
													</Row>
												</XRecommendedCard>
											</Col>
										</Row>
										{displayFaxUpload ? (
											<div className="mt-3">
												<Dragger showUploadList={false}>
													<img
														src="/images/file-upload 1.svg"
														style={{
															marginBottom: '8px',
														}}
														alt="file upload"
													/>
													<p
														style={{
															color: '#ACACAC',
															fontFamily:
																'Poppins',
															fontSize: '14px',
															fontStyle: 'normal',
															fontWeight: '500',
															lineHeight: '20px',
															letterSpacing:
																'0.28px',
														}}
													>
														Upload Acknowledgement
													</p>
												</Dragger>
												<XForm.Item className="text-right mt-3">
													<XShareButton
														loading={
															sendLetterLoading
														}
														style={{
															background:
																'#212226',
														}}
														onClick={() => {
															setSendLetterLoading(
																true,
															);
															// setSuccessModal(
															// 	true,
															// );
															//!Remove this state on funtion call

															setLetterDownloaded(
																false,
															);
															setDisplayEmailContent(
																false,
															);
															// setDisplayFaxUpload(
															// 	false,
															// );

															editCase({
																id: id,
																appeal_status:
																	'sent',
																appeal_status_updated_at:
																	moment(),
															});
															// navigate(-1);
														}}
													>
														Send
													</XShareButton>
												</XForm.Item>
											</div>
										) : (
											''
										)}
										{displayEmailContent &&
										letterDownloaded ? (
											<div className="mt-3">
												<p>
													The letter has been
													downloaded. Please ensure
													that you do not overlook
													attaching the document in
													the email you are about to
													send.
												</p>
												<XForm.Item className="text-right mt-3">
													<XShareButton
														loading={
															sendLetterLoading
														}
														style={{
															background:
																'#212226',
														}}
														onClick={() => {
															setSendLetterLoading(
																true,
															);
															handleEmailSend();
														}}
													>
														Send Email
													</XShareButton>
												</XForm.Item>
											</div>
										) : (
											''
										)}
										{/* new code end */}
									</>
								) : isMdDisabled && isCodingDisabled ? (
									''
								) : (
									<>
										<CaseTimelineTitle className="mb-3">
											Send To
										</CaseTimelineTitle>
										<XTabs
											// defaultActiveKey="1"
											activeKey={activeKey}
											centered={true}
											size="large"
											type="card"
											onChange={handleTabsChange}
											items={[
												{
													label: 'Coding Team',
													key: '1',
													// disabled: isCodingDisabled,
													// disabled:
													// 	patientData?.appeal_letter_status ===
													// 		'coding_team_sent' ||
													// 	localStorage.getItem(
													// 		'role',
													// 	) === 'coding-team' ||
													// 	localStorage.getItem(
													// 		'role',
													// 	) === 'md-advisor' ||
													// 	patientData?.appeal_letter_status !==
													// 		null,
													children:
														isCodingDisabled ? (
															<></>
														) : (
															<>
																<XForm
																	form={
																		appealForm
																	}
																	className="w-100 mt-3"
																	layout="vertical"
																	autoComplete="off"
																	onFinish={
																		isAppealVerifyApproveModalOpen[1]
																	}
																>
																	<Row
																		gutter={[
																			15,
																			15,
																		]}
																		className="align-items-center mt-2"
																	>
																		<Col
																			xs={
																				24
																			}
																		>
																			<XForm.Item name="radio_group">
																				<XRadio.Group
																					className="w-100"
																					name="radiogroup"
																					defaultValue={
																						1
																					}
																					value={
																						selectMember
																					}
																					onChange={(
																						e,
																					) => {
																						setSelectMember(
																							e
																								.target
																								.value,
																						);
																						appealForm.setFieldValue(
																							{
																								appeal_letter_assigned_to:
																									null,
																							},
																						);
																					}}
																				>
																					<Row className="align-items-center">
																						<Col
																							xs={
																								12
																							}
																						>
																							<Radio
																								value={
																									1
																								}
																							>
																								Send
																								to
																								Coding
																								Team
																							</Radio>
																						</Col>
																						<Col
																							xs={
																								12
																							}
																						>
																							<Radio
																								value={
																									2
																								}
																							>
																								Assign
																								to
																								Coding
																								Team
																								Member
																							</Radio>
																						</Col>
																					</Row>
																				</XRadio.Group>
																			</XForm.Item>
																			{/* <XRadio.Group
																name="radiogroup"
																onChange={(e) => {
																
																	setSelectMember(
																		e.target
																			.value,
																	);
																	appealForm.setFieldValue(
																		{
																			appeal_letter_assigned_to:
																				null,
																		},
																	);
																}}
																value={selectMember}
																
															>
																<XRadio value={1}>
																	Send to Team
																</XRadio>
																<XRadio value={2}>
																	Assign to Member
																</XRadio>
															</XRadio.Group> */}
																		</Col>
																	</Row>

																	{selectMember ===
																		2 && (
																		<Row
																			gutter={[
																				15,
																				15,
																			]}
																			className="align-items-center"
																		>
																			<Col
																				xs={
																					24
																				}
																			>
																				<XForm.Item
																					name="appeal_letter_assigned_to"
																					label="Assign To"
																					rules={[
																						{
																							required: true,
																							message:
																								'User is required',
																						},
																					]}
																				>
																					<XSelect
																						size="large"
																						placeholder="Assign To"
																						name="appeal_letter_assigned_to"
																						allowClear
																						showSearch={
																							true
																						}
																						filterOption={(
																							input,
																							option,
																						) =>
																							option.label
																								.toLowerCase()
																								.includes(
																									input.toLowerCase(),
																								)
																						}
																						style={{
																							textAlign:
																								'left',
																						}}
																						className="demo-case-css"
																						options={
																							appealLetterUsers
																						}
																						onFocus={() => {
																							getVerifyApproveAppealLetterUserList(
																								{
																									role: isAppealVerifyApproveModalOpen[2],
																									organization_id:
																										patientData.organization,
																								},
																							);
																						}}
																						onSearch={(
																							search,
																						) => {
																							if (
																								search.length >=
																								3
																							) {
																								getVerifyApproveAppealLetterUserList(
																									{
																										role: isAppealVerifyApproveModalOpen[2],
																										search,
																										organization_id:
																											patientData.organization,
																									},
																								);
																							} else if (
																								search.length ===
																								0
																							) {
																								getVerifyApproveAppealLetterUserList(
																									{
																										role: isAppealVerifyApproveModalOpen[2],
																										organization_id:
																											patientData.organization,
																									},
																								);
																							}
																						}}
																						onChange={(
																							value,
																						) => {
																							setFormData(
																								{
																									...formData,
																									appeal_letter_assigned_to:
																										value,
																								},
																							);
																						}}
																					/>
																				</XForm.Item>
																			</Col>
																		</Row>
																	)}

																	<Row
																		gutter={[
																			15,
																			15,
																		]}
																		className="align-items-center"
																	>
																		<Col
																			xs={
																				24
																			}
																		>
																			<XForm.Item
																				name="appeal_letter_remarks"
																				label="Enter Remarks"
																				rules={[
																					{
																						required: true,
																						message:
																							'Enter Remarks',
																					},
																				]}
																			>
																				<TextArea
																					size="large"
																					placeholder="Enter Remarks"
																					name="appeal_letter_remarks"
																					onChange={(
																						e,
																					) =>
																						handleInputChange(
																							e,
																							formData,
																							setFormData,
																						)
																					}
																				/>
																			</XForm.Item>
																		</Col>
																	</Row>
																	<Row
																		gutter={[
																			15,
																			15,
																		]}
																		className="align-items-center"
																	>
																		<Col
																			xs={
																				24
																			}
																		>
																			<XButtonConfirm
																				className="ms-0"
																				style={{
																					background:
																						'#007299',
																					width: '100%',
																				}}
																				loading={
																					appealLetterLoading
																				}
																				htmlType="submit"
																			>
																				Send
																			</XButtonConfirm>
																		</Col>
																	</Row>
																	{/* <Row
																gutter={[
																	15, 15,
																]}
																className="align-items-center mt-2"
															>
																<Col xs={24}>
																	<XButtonConfirm
																		className="ms-0"
																		type="primary"
																		size="large"
																		shape="round"
																		style={{
																			// background:
																			// 	'#fff',
																			// color: '#212226',
																			background:
																				'#fff',
																			color: '#007299',
																			width: '100%',
																		}}
																		onClick={() => {
																			// setIsAppealVerifyApproveModalOpen(
																			// 	[
																			// 		'',
																			// 		'',
																			// 		'',
																			// 	],
																			// );
																			setAppealLetterUsers(
																				[],
																			);
																			appealForm.resetFields(
																				'',
																			);
																			appealForm.setFieldsValue(
																				{},
																			);
																			setSelectMember(
																				1,
																			);
																		}}
																	>
																		Reset
																	</XButtonConfirm>
																</Col>
															</Row> */}
																</XForm>
															</>
														),
												},

												{
													label: 'MD Advisor',
													key: '2',
													// disabled: isMdDisabled,

													// disabled:
													// 	patientData?.appeal_letter_status ===
													// 		'md_advisor_sent' &&
													// 	patientData?.case_amount >=
													// 		patientData?.appeal_letter_approval_threshold
													// 		? true
													// 		: patientData?.case_amount <
													// 				patientData?.appeal_letter_approval_threshold &&
													// 		  checkModulePermissions(
													// 				'appeal-letter',
													// 		  ).sub_modules.includes(
													// 				'confirm-letter',
													// 		  )
													// 		? false
													// 		: checkModulePermissions(
													// 				'appeal-letter',
													// 		  ).sub_modules.includes(
													// 				'confirm-letter',
													// 		  )
													// 		? localStorage.getItem(
													// 				'role',
													// 		  ) !== 'coding-team' &&
													// 		  localStorage.getItem(
													// 				'role',
													// 		  ) !== 'md-advisor' &&
													// 		  [
													// 				'md_advisor_send',
													// 				'md_advisor_approved',
													// 				'coding_team_sent',
													// 		  ].includes(
													// 				patientData.appeal_letter_status,
													// 		  )
													// 			? true
													// 			: localStorage.getItem(
													// 					'role',
													// 			  ) !== 'md-advisor'
													// 			? false
													// 			: true
													// 		: true,
													children: isMdDisabled ? (
														<></>
													) : (
														<>
															<XForm
																form={
																	appealForm
																}
																className="w-100 mt-3"
																layout="vertical"
																autoComplete="off"
																onFinish={
																	isAppealVerifyApproveModalOpen[1]
																}
															>
																<Row
																	gutter={[
																		15, 15,
																	]}
																	className="align-items-center mt-2"
																>
																	<Col
																		xs={24}
																	>
																		<XForm.Item name="radio_group">
																			<XRadio.Group
																				className="w-100"
																				name="radiogroup"
																				defaultValue={
																					1
																				}
																				onChange={(
																					e,
																				) => {
																					setSelectMember(
																						e
																							.target
																							.value,
																					);
																					appealForm.setFieldValue(
																						{
																							appeal_letter_assigned_to:
																								null,
																						},
																					);
																				}}
																			>
																				<Row className="align-items-center">
																					<Col
																						xs={
																							12
																						}
																					>
																						<Radio
																							value={
																								1
																							}
																						>
																							Send
																							to
																							MD
																							Advisor
																							Team
																						</Radio>
																					</Col>
																					<Col
																						xs={
																							12
																						}
																					>
																						<Radio
																							value={
																								2
																							}
																						>
																							Assign
																							to
																							MD
																							Advisor
																							Member
																						</Radio>
																					</Col>
																				</Row>
																			</XRadio.Group>
																		</XForm.Item>
																	</Col>
																</Row>

																{selectMember ===
																	2 && (
																	<Row
																		gutter={[
																			15,
																			15,
																		]}
																		className="align-items-center"
																	>
																		<Col
																			xs={
																				24
																			}
																		>
																			<XForm.Item
																				name="appeal_letter_assigned_to"
																				label="Assign To"
																				rules={[
																					{
																						required: true,
																						message:
																							'User is required',
																					},
																				]}
																			>
																				<XSelect
																					size="large"
																					placeholder="Assign To"
																					name="appeal_letter_assigned_to"
																					allowClear
																					showSearch={
																						true
																					}
																					filterOption={(
																						input,
																						option,
																					) =>
																						option.label
																							.toLowerCase()
																							.includes(
																								input.toLowerCase(),
																							)
																					}
																					style={{
																						textAlign:
																							'left',
																					}}
																					className="demo-case-css"
																					options={
																						appealLetterUsers
																					}
																					onFocus={() => {
																						getVerifyApproveAppealLetterUserList(
																							{
																								role: isAppealVerifyApproveModalOpen[2],
																								organization_id:
																									patientData.organization,
																							},
																						);
																					}}
																					onSearch={(
																						search,
																					) => {
																						if (
																							search.length >=
																							3
																						) {
																							getVerifyApproveAppealLetterUserList(
																								{
																									role: isAppealVerifyApproveModalOpen[2],
																									search,
																									organization_id:
																										patientData.organization,
																								},
																							);
																						} else if (
																							search.length ===
																							0
																						) {
																							getVerifyApproveAppealLetterUserList(
																								{
																									role: isAppealVerifyApproveModalOpen[2],
																									organization_id:
																										patientData.organization,
																								},
																							);
																						}
																					}}
																					onChange={(
																						value,
																					) => {
																						setFormData(
																							{
																								...formData,
																								appeal_letter_assigned_to:
																									value,
																							},
																						);
																					}}
																				/>
																			</XForm.Item>
																		</Col>
																	</Row>
																)}

																<Row
																	gutter={[
																		15, 15,
																	]}
																	className="align-items-center"
																>
																	<Col
																		xs={24}
																	>
																		<XForm.Item
																			name="appeal_letter_remarks"
																			label="Enter Remarks"
																			rules={[
																				{
																					required: true,
																					message:
																						'Enter Remarks',
																				},
																			]}
																		>
																			<TextArea
																				size="large"
																				placeholder="Enter Remarks"
																				name="appeal_letter_remarks"
																				onChange={(
																					e,
																				) =>
																					handleInputChange(
																						e,
																						formData,
																						setFormData,
																					)
																				}
																			/>
																		</XForm.Item>
																	</Col>
																</Row>
																<Row
																	gutter={[
																		15, 15,
																	]}
																	className="align-items-center"
																>
																	<Col
																		xs={24}
																	>
																		<XButtonConfirm
																			className="ms-0"
																			style={{
																				background:
																					'#007299',
																				width: '100%',
																			}}
																			loading={
																				appealLetterLoading
																			}
																			htmlType="submit"
																		>
																			Send
																		</XButtonConfirm>
																	</Col>
																</Row>
																{/* <Row
																gutter={[
																	15, 15,
																]}
																className="align-items-center mt-2"
															>
																<Col xs={24}>
																	<XButtonConfirm
																		className="ms-0"
																		type="primary"
																		// size="large"
																		shape="round"
																		style={{
																			background:
																				'#fff',

																			// color: '#212226',
																			color: '#007299',
																			width: '100%',
																		}}
																		onClick={() => {
																			// setIsAppealVerifyApproveModalOpen(
																			// 	[
																			// 		'',
																			// 		'',
																			// 		'',
																			// 	],
																			// );
																			setAppealLetterUsers(
																				[],
																			);
																			appealForm.resetFields(
																				'',
																			);
																			appealForm.setFieldsValue(
																				{},
																			);
																			setSelectMember(
																				1,
																			);
																		}}
																	>
																		Reset
																	</XButtonConfirm>
																</Col>
															</Row> */}
															</XForm>
														</>
													),
												},
											]}
										/>
									</>
								)}
								{checkModulePermissions('appeal-letter') &&
									localStorage.getItem('role') !==
										'coding-team' &&
									localStorage.getItem('role') !==
										'md-advisor' &&
									[
										'md_advisor_sent',
										'coding_team_sent',
									].includes(
										patientData.appeal_letter_status,
									) && (
										<XRecommendedCard
											// bgColor="#ffffff"
											background="rgba(223, 235, 240, 0.5);"
											className="mt-3"
										>
											<Row
												className="align-items-center"
												style={{
													display: 'flex', // Enable flexbox
													justifyContent: 'center', // Horizontally center
													alignItems: 'center', // Vertically center
												}}
											>
												<img
													style={{ padding: '10px' }}
													src="/images/response.svg"
													alt="response"
												/>
												{patientData?.appeal_letter_status ===
													'md_advisor_sent' && (
													<ResponseHeading>
														Awaiting for MD advisor
														to respond
													</ResponseHeading>
												)}
												{patientData?.appeal_letter_status ===
													'coding_team_sent' && (
													<ResponseHeading>
														Awaiting for coding team
														to respond
													</ResponseHeading>
												)}
											</Row>
										</XRecommendedCard>
									)}

								{/* <XDivider /> */}
								<div
									style={{
										background: `${
											patientData.appeal_letter_remarks
												? '#e0e0e0'
												: ''
										}`,
										padding: '10px',
									}}
								>
									{patientData.appeal_letter_remarks ? (
										<>
											<p
												style={{
													fontWeight: 'bold',
													fontSize: '16px',
													textAlign: 'start',
												}}
											>
												Query
											</p>
											<p
												style={{
													textAlign: 'justify',
												}}
											>
												{
													patientData.appeal_letter_remarks
												}
											</p>
										</>
									) : (
										<></>
									)}
									{/* {patientData?.appeal_letter_status ===
									'md_advisor_approved' && (
									<CaseTimelineTitle className="mb-3">
										Send Apeeal Letter.....
									</CaseTimelineTitle>
								)} */}
									{/* {patientData?.case_amount <
									patientData?.appeal_letter_approval_threshold &&
								checkModulePermissions(
									'appeal-letter',
								).sub_modules.includes('confirm-letter') ? (
									<>
										<XDivider />
										<CaseTimelineTitle className="mb-3">
											Approve and Send Letter
										</CaseTimelineTitle>
									</>
								) : (
									''
								)} */}
									{/* ///////////// button condiitons here old code */}
								</div>
							</XCardBackBg>

							<div
								key="buttonsContainer"
								// style={{
								// 	display: 'flex',
								// 	justifyContent: 'flex-end',
								// 	gap: '10px',
								// 	alignItems: 'center',
								// 	marginTop: '10px',
								// }}
							>
								{patientData?.appeal_letter_status ===
								'coding_team_sent' ? (
									checkModulePermissions(
										'appeal-letter',
									).sub_modules.includes(
										'verify-coding-team',
									) ? (
										<>
											<XCardBackBg className="mt-4 align-item-center">
												<ApprovHeading>
													Confirm and Approve
												</ApprovHeading>
												<Row
													gutter={[15, 15]}
													className="align-items-center mt-3"
												>
													<Col xs={24}>
														<XButtonConfirm
															className="ms-0"
															style={{
																background:
																	'#007299',
																color: '#fff',
																width: '100%',
																type: 'primary',
																size: 'large',
																shape: 'round',
																borderRadius:
																	'20px',
															}}
															loading={
																appealLetterLoading
															}
															// htmlType="submit"

															onClick={() =>
																getCodingTeamButtonFunction(
																	'sent_for_verification',
																	'Verified',
																	'coding_team_approved',
																)
															}
														>
															Approve
														</XButtonConfirm>
													</Col>
												</Row>
											</XCardBackBg>
											{/* <Button
											loading={appealLetterLoading}
											key="approvedByCodingTeam"
											type="primary"
											onClick={() =>
												getCodingTeamButtonFunction(
													'sent_for_verification',
													'Verified',
													'coding_team_approved',
												)
											}
											style={{
												background: `black`,
											}}
										>
											Approve
										</Button> */}
										</>
									) : (
										<></>
									)
								) : patientData?.appeal_letter_status ===
										'md_advisor_sent' &&
								  patientData?.case_amount >=
										patientData?.appeal_letter_approval_threshold ? (
									checkModulePermissions(
										'appeal-letter',
									).sub_modules.includes('confirm-letter') ? (
										<>
											<XCardBackBg className="mt-4 align-item-center">
												<ApprovHeading>
													Confirm and Approve
												</ApprovHeading>
												<Row
													gutter={[15, 15]}
													className="align-items-center mt-3"
												>
													<Col xs={24}>
														<XButtonConfirm
															className="ms-0"
															style={{
																background:
																	'#007299',
																color: '#fff',
																width: '100%',
																type: 'primary',
																size: 'large',
																shape: 'round',
																borderRadius:
																	'20px',
															}}
															loading={
																appealLetterLoading
															}
															// htmlType="submit"
															onClick={() => {
																confirmAppealLetter(
																	'md_advisor_approved',
																);
															}}
														>
															Approve
														</XButtonConfirm>
													</Col>
												</Row>
											</XCardBackBg>
										</>
									) : (
										<></>
									)
								) : patientData?.case_amount <
										patientData?.appeal_letter_approval_threshold &&
								  checkModulePermissions(
										'appeal-letter',
								  ).sub_modules.includes('confirm-letter') ? (
									<>
										<XCardBackBg className="mt-4 align-item-center">
											<ApprovHeading>
												Confirm and Approve
											</ApprovHeading>
											<Row
												gutter={[15, 15]}
												className="align-items-center mt-3"
											>
												<Col xs={24}>
													<XButtonConfirm
														className="ms-0"
														style={{
															background:
																'#007299',
															color: '#fff',
															width: '100%',
															type: 'primary',
															size: 'large',
															shape: 'round',
															borderRadius:
																'20px',
														}}
														loading={
															appealLetterLoading
														}
														// htmlType="submit"
														onClick={() =>
															confirmAppealLetter(
																'md_advisor_approved',
															)
														}
													>
														Approve
													</XButtonConfirm>
												</Col>
											</Row>
										</XCardBackBg>
									</>
								) : (
									<></>
								)}
								{/* {patientData?.appeal_letter_status ===
								'md_advisor_sent' &&
							patientData?.case_amount >=
								patientData?.appeal_letter_approval_threshold ? (
								checkModulePermissions(
									'appeal-letter',
								).sub_modules.includes('confirm-letter') ? (
									<>
										<XCardBackBg className="mt-4 align-item-center">
											<ApprovHeading>
												Confirm and send letter to the
												provider
											</ApprovHeading>
											<Row
												gutter={[15, 15]}
												className="align-items-center mt-3"
											>
												<Col xs={24}>
													<XButtonConfirm
														style={{
															background:
																'#007299',
															color: '#fff',
															width: '95%',
															type: 'primary',
															size: 'large',
															shape: 'round',
															borderRadius:
																'20px',
														}}
														loading={
															appealLetterLoading
														}
														// htmlType="submit"
														onClick={() => {
															confirmAppealLetter(
																'md_advisor_approved',
															);
														}}
													>
														Approve2
													</XButtonConfirm>
												</Col>
											</Row>
										</XCardBackBg>
										
									</>
								) : (
									<></>
								)
							) : patientData?.case_amount <
									patientData?.appeal_letter_approval_threshold &&
							  checkModulePermissions(
									'appeal-letter',
							  ).sub_modules.includes('confirm-letter') ? (
								<>
									<XCardBackBg className="mt-4 align-item-center">
										<ApprovHeading>
											Confirm and send letter to the
											provider
										</ApprovHeading>
										<Row
											gutter={[15, 15]}
											className="align-items-center mt-3"
										>
											<Col xs={24}>
												<XButtonConfirm
													style={{
														background: '#007299',
														color: '#fff',
														width: '95%',
														type: 'primary',
														size: 'large',
														shape: 'round',
														borderRadius: '20px',
													}}
													loading={
														appealLetterLoading
													}
													// htmlType="submit"
													onClick={() =>
														confirmAppealLetter(
															'md_advisor_approved',
														)
													}
												>
													Approve3
												</XButtonConfirm>
											</Col>
										</Row>
									</XCardBackBg>
								</>
							) : (
								<></>
							)} */}
							</div>
						</XAffix>
						{/* <XCardBackBg className="mt-4 align-item-center">
							<ApprovHeading>
								Confirm and send letter to the provider
							</ApprovHeading>
							<Row
								gutter={[15, 15]}
								className="align-items-center mt-3"
							>
								<Col xs={24}>
									<XButtonConfirm
										style={{
											background: '#007299',
											color: '#fff',
											width: '95%',
											type: 'primary',
											size: 'large',
											shape: 'round',
											borderRadius: '20px',
										}}
										loading={appealLetterLoading}
										htmlType="submit"
									>
										Approve
									</XButtonConfirm>
								</Col>
							</Row>
						</XCardBackBg> */}

						<ChatBoxWrap>
							{chatOpen && <ChatBox case_id={id} />}

							<ChatButton
								type="text"
								onClick={() => {
									setChatOpen(!chatOpen);
								}}
							>
								{chatOpen ? (
									<>
										<span className="mobileClose">
											<img
												src="/images/chat-mob-close.svg"
												alt="Close chat Icon"
											/>
										</span>
										<span className="hideMobileClose">
											<img
												src="/images/chat-cross-icon.svg"
												alt="Close chat Icon"
												width="60px"
												height="60px"
											/>
										</span>
									</>
								) : (
									<img
										src="/images/chat-icon.svg"
										alt="Open chat Icon"
										width="60px"
										height="60px"
									/>
								)}
							</ChatButton>
						</ChatBoxWrap>
					</Col>
				</Row>
			</XContainer>

			{/* //todo Drawer of the Coding Tools Start */}
			<XAddCodeSticky type="primary" onClick={showIcdCptDrawer}>
				Coding Tools
			</XAddCodeSticky>

			<DrawerCodingTools
				previewMode={true}
				fixed={false}
				icdform={icdform}
				cptform={cptform}
				activeCode={activeCode}
				setActiveCode={setActiveCode}
				closeIcdCptDrawer={closeIcdCptDrawer}
				openIcdCptDrawer={openIcdCptDrawer}
				handleCodeClick={handleCodeClick}
				// setModalOpen={setModalOpen}
				// setModalTextArea={setModalTextArea}
				// modalOpen={modalOpen}
				// updatePageContent={updatePageContent}
				// modalTextArea={modalTextArea}
				closeIcdCptDrawer2={closeIcdCptDrawer2}
				openIcdCptDrawer2={openIcdCptDrawer2}
				setIcdCodes={setIcdCodes}
				icdCodes={icdCodes}
				searchResults={searchResults}
				setSearchResults={setSearchResults}
				icdLoading={icdLoading}
				setIcdLoading={setIcdLoading}
				triggeredBySearch={triggeredBySearch}
				setTriggeredBySearch={setTriggeredBySearch}
				triggeredByEnter={triggeredByEnter}
				setTriggeredByEnter={setTriggeredByEnter}
				loadMore={loadMore}
				setLoadMore={setLoadMore}
				icdSearchLoading={icdSearchLoading}
				setIcdSearchLoading={setIcdSearchLoading}
				cptCodes={cptCodes}
				setCptCodes={setCptCodes}
				cptLoading={cptLoading}
				setCptLoading={setCptLoading}
				cptSearchResults={cptSearchResults}
				setCptSearchResults={setCptSearchResults}
				cptTriggeredBySearch={cptTriggeredBySearch}
				setCptTriggeredBySearch={setCptTriggeredBySearch}
				cptTriggeredByEnter={cptTriggeredByEnter}
				setCptTriggeredByEnter={setCptTriggeredByEnter}
				cptLoadMore={cptLoadMore}
				setCptLoadMore={setCptLoadMore}
				cptSearchLoading={cptSearchLoading}
				setCptSearchLoading={setCptSearchLoading}
				icdFilters={icdFilters}
				setIcdFilters={setIcdFilters}
				cptFilters={cptFilters}
				setCptFilters={setCptFilters}
			/>

			{/* edit appeal letter modal */}
			<Modal
				title="Edit Appeal Letter"
				open={showEditAppealLetter}
				okText="Save and Preview"
				// loading={appealLetterLoading}
				onOk={saveAndPreviewAppealLetter}
				width={900}
				okButtonProps={{
					style: {
						background: 'black',
					},
					loading: editAppealLetterLoading, // Show loading state on the button
				}}
				onCancel={closeEditAppealLetter}
			>
				<Editor
					apiKey={config.tiny_url_key}
					onInit={(evt, editor) => (editorRef.current = editor)}
					init={{
						menubar: false,
						plugins: ['code', 'image', 'link', 'visualblocks'],
						branding: false,
						toolbar:
							'undo redo | formatselect | ' +
							'bold italic backcolor | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize | ' +
							'bullist numlist outdent indent | image link |' +
							'removeformat | visualblocks code | help ',
						content_style:
							'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
						font_size_formats: '8px 10px 12px 14px 18px 24px 36px',
					}}
					initialValue={appealLetterContent}
				/>
			</Modal>
			{/* fax success modal */}
			<XModal
				centered
				open={successModal}
				onOk={() => setSuccessModal(false)}
				onCancel={() => {
					navigate(-1);
					setSuccessModal(false);
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
				width={400}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col
						xs={24}
						lg={{ span: 18, offset: 3 }}
						className="text-center"
					>
						<img
							src="/images/fax-success.svg"
							style={{ marginBottom: '8px' }}
							alt="fax"
						/>

						<XPara1 className="mt-5 mb-4">
							The letter has been successfully sent via fax.
						</XPara1>
					</Col>
				</Row>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getAppealLetterState: state.getAppealLetter,
	editCaseState: state.editCase,
	getVerifyApproveAppealLetterUserListState:
		state.getVerifyApproveAppealLetterUserList,
	getAnalysisState: state.getAnalysis,
	saveAppealLetterState: state.saveAppealLetter,
});

const mapDispatchToProps = (dispatch) => ({
	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
	getAppealLetterReset: (params) => dispatch(getAppealLetterReset(params)),
	editCase: (params) => dispatch(editCaseApi(params)),
	editCaseReset: (params) => dispatch(editCaseReset(params)),
	getVerifyApproveAppealLetterUserList: (params) =>
		dispatch(getVerifyApproveAppealLetterUserListApi(params)),
	getVerifyApproveAppealLetterUserListReset: () =>
		dispatch(getVerifyApproveAppealLetterUserListReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: (params) => dispatch(getAnalysisReset(params)),
	saveAppealLetter: (params) => dispatch(saveAppealLetterApi(params)),
	saveAppealLetterReset: (params) => dispatch(saveAppealLetterReset(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AppealLetterPreview);
