import styled from 'styled-components';
import '../../styles/GlobalStyle';
import {
	Card,
	Button,
	Radio,
	Avatar,
	Upload,
	Collapse,
	Modal,
	Drawer,
	Tabs,
} from 'antd';

export const XCardBackBg = styled(Card)`
	// background: var(
	// 	--Linear,
	// 	linear-gradient(
	// 		90deg,
	// 		rgba(105, 234, 203, 0.15) 0%,
	// 		rgba(234, 204, 248, 0.15) 48%,
	// 		rgba(102, 84, 241, 0.15) 100%
	// 	)
	// );
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	.ant-card-body {
		border-radius: 10px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 20px !important;
		}
	}
`;

export const ApprovHeading = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	color: #212226;
	font-family: Inter;
`;

export const ResponseHeading = styled.span`
	// display: flex;
	// align-items: center;
	// justify-content: center;
	padding: 10px;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	color: #57636f;
	font-family: Inter;
`;

export const XButtonBack = styled(Button)`
	color: ${({ theme }) => theme.colors.menu2};
	border-radius: 20px !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	background: ${({ theme }) => theme.colors.white};
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
	svg {
		font-size: 16px;
		font-weight: 600;
	}
	&:hover {
		background: ${({ theme }) => theme.colors.menu2} !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;
export const XEditTitle = styled.span`
	margin-left: 15px;
	color: ${({ theme }) => theme.colors.menu2};
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0.36px;
`;

export const XRadio = styled(Radio)`
	margin-top: 10px;
	color: ${({ theme }) => theme.colors.menu2};
	font-weight: 400;
	font-size: 12px !important;
	line-height: 16px !important;
	.ant-form-item-control-input {
		min-height: auto !important;
	}
	.ant-radio-checked .ant-radio-inner {
		background: #007299;
		border-color: #007299;
	}
`;
export const XAvatarName = styled(Avatar)`
	background: #007299;
	color: ${({ theme }) => theme.colors.white};
`;
export const MainTitle = styled.span`
	margin-left: 10px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.color11};
	font-family: ${({ theme }) => theme.colors.fontPoppins};
	display: inline-block;
	span {
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: ${({ theme }) => theme.colors.color11};
	}
`;
export const SubTitle = styled.span`
	margin-left: 10px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.color11};
`;
export const ParagraphPage = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: ${({ theme }) => theme.colors.color11};
	font-family: ${({ theme }) => theme.colors.fontPoppins};
`;
export const AddressWrap = styled(Card)`
	width: 100%;
	position: relative;
	background: #f7f8fa;
	border-radius: 10px;
	font-family: ${({ theme }) => theme.colors.fontPoppins};
	.heading {
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		color: ${({ theme }) => theme.colors.menu2};
		span {
			// padding-left: 5px;
			font-weight: 500;
			color: #57636f;
		}
	}
`;
export const CaseFillingTitle = styled.p`
	margin: 0px;
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-weight: 500;
	font-size: 14px;
	letter-spacing: -0.02em;
	color: ${({ theme }) => theme.colors.menu2};
`;

export const CaseTimelineTitle = styled.h1`
	margon: 0px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.menu2};
`;
export const CaseUpdateCard = styled.section`
	width: 100%;
	position: relative;
	hr {
		background: ${({ theme }) => theme.colors.menu2};
	}
	.title {
		font-weight: 400;
		font-size: 13px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.ant-form-item-label label,
	label.ant-form-item-no-colon,
	.ant-form-item-label > label,
	.ant-form-item-label {
		font-family: ${({ theme }) => theme.colors.fontInter};
		font-weight: 400 !important;
		font-size: 13px !important;
		line-height: 16px !important;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.ant-form-item-control-input {
		min-height: 24px !important;
	}
	.inputBox.ant-picker,
	.ant-input-affix-wrapper,
	.selectBox.ant-select {
		width: 100%;
		background: #ffffff !important;
		border: 1px solid rgba(0, 33, 65, 0.1) !important;
		border-radius: 10px !important;
		font-size: 12px !important;
		color: ${({ theme }) => theme.colors.menu2};
	}
	input.inputText {
		background: #ffffff !important;
	}
	${
		'' /* .ant-form-item-control-input {
		background: #ffffff !important;
		border-radius: 10px !important;
	} */
	}
	.innerCardWrap {
		width: 100%;
		position: relative;
		background: ${({ theme }) => theme.colors.white};
		padding: 15px;
		border: 1px solid rgba(0, 33, 65, 0.1);
		border-radius: 10px;
	}
`;
export const XUpload = styled(Upload)`
	width: 100%;
	positioon: relative;
	cursor: pointer;
	.ant-upload.ant-upload-select {
		width: 100% !important;
		positioon: relative;
		height: auto !important;
	}
	.uploadCard {
		width: 100%;
		position: relative;
		background: rgba(15, 115, 238, 0.05);
		border: 1px solid rgba(0, 33, 65, 0.1);
		border-radius: 10px;
		padding: 20px 10px;
		text-align: center;
	}
`;
export const XCollapseMain = styled(Collapse)`
	.ant-collapse-item .ant-collapse-header {
		background: #fff !important;
		border-radius: 8px !important;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #212226;
	}
	.ant-collapse-content {
		width: 100%;
		position: relative;
		padding: 0px 5px 15px 0px;
		max-height: 45vh;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 2px;
			height: 2px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
		&::-webkit-scrollbar {
			width: 3px;
			height: 3px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
	}
`;
export const XButtonWrap = styled.div`
	width: 100%;
	positioon: relative;
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	padding: 10px 15px;
	font-weight: 400;
	font-size: 14px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: ${({ theme }) => theme.colors.menu2};
`;
export const XButtonModal = styled(Button)`
	background: #212226 !important;
	svg {
		color: ${({ theme }) => theme.colors.white};
	}
	&:hover {
		border-color: ${({ theme }) => theme.colors.white} !important;
	}
`;

export const XICDModal = styled(Modal)`
	.icdIcons {
		background: #f5f5f5;
		border-radius: 8px;
		padding: 15px;
		text-align: center;
	}
	.modalTitle {
		width: 100%;
		position: relative;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.modalTitleSub {
		width: 100%;
		position: relative;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.modalParagraph {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: rgba(33, 34, 38, 0.6);
	}
	.ant-modal-content {
		padding: 24px;
	}
`;
export const XICDModalFooterBTN = styled(Button)`
	width: 100%;
	position: relative;
	background: #007299;
	border: 1px solid #007299;
	border-radius: 25px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${({ theme }) => theme.colors.white};
	&:hover {
		background: ${({ theme }) => theme.colors.white} !important;
		border: 1px solid #007299 !important;
		color: ${({ theme }) => theme.colors.menu2} !important;
	}
`;
export const XAddCodeSticky = styled(Button)`
	position: fixed;
	transform: rotate(-90deg);
	right: -42px;
	top: 21%;
	z-index: 1 !important;
	background: #007299;
	border: 1px solid rgba(0, 114, 153, 0.1);
	border-radius: 10px 10px 0px 0px;
	font-weight: 400;
	font-size: 14px;
	line-height: 38px;
	color: ${({ theme }) => theme.colors.white};
	&:hover {
		border-color: #007299 !important;
		background: #fff !important;
		color: #007299 !important;
	}
`;
export const XDrawerAddCode = styled(Drawer)`
	font-family: ${({ theme }) => theme.colors.fontInter} !important;
	.ant-drawer-body {
		padding: 0px !important;
	}
	.ant-drawer-title,
	.ant-drawer-header {
		color: #007299;
		background: #f2f8fa;
		border: none;
		font-weight: 600 !important;
	}
	.titleBg {
		width: 100%;
		position: relative;
		background: #f2f8fa;
		padding: 0px 24px 24px 24px;
		border-bottom: 1px dashed #dbdbdb;
	}
	.contentPadding {
		width: 100%;
		position: relative;
		padding: 24px;
	}
	.titleICD {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.icdPara1 {
		font-weight: 600 !important;
		font-size: 14px;
		line-height: 24px;
		color: #007299;
	}
	.icdPara2 {
		font-weight: 400 !important;
		font-size: 12px;
		line-height: 22px;
		letter-spacing: -0.02em;
		color: ${({ theme }) => theme.colors.menu2};
		.react-read-more-read-less {
			font-weight: 500 !important;
			display: block;
			color: #007299;
		}
	}
	.icdPara3 {
		font-weight: 600 !important;
		font-size: 14px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.btnAdd {
		background: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
		svg {
			filter: none;
		}
	}
	.ant-drawer-close {
		position: absolute;
		right: 0;
	}
	.ant-drawer-body {
		&::-webkit-scrollbar {
			width: 2px;
			height: 2px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
		&::-webkit-scrollbar {
			width: 3px;
			height: 3px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
	}
`;
export const XTabIcdCpt = styled(Tabs)`
	font-family: ${({ theme }) => theme.colors.fontInter} !important;
	.titleICD {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.icdPara1 {
		font-weight: 600 !important;
		font-size: 14px;
		line-height: 24px;
		color: #007299;
	}
	.icdPara2 {
		font-weight: 400 !important;
		font-size: 12px;
		line-height: 22px;
		letter-spacing: -0.02em;
		color: ${({ theme }) => theme.colors.menu2};
		.react-read-more-read-less {
			font-weight: 500 !important;
			display: block;
			color: #007299;
		}
	}
	.icdPara3 {
		font-weight: 600 !important;
		font-size: 14px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.btnAdd {
		background: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
		svg {
			filter: none;
		}
	}
	.ant-tabs-nav {
		&:before {
			display: none !important;
		}
	}
	.ant-tabs-nav-wrap {
		display: block !important;
	}
	.ant-tabs-nav-list {
		background: #ffffff;
		border: 1px solid rgba(0, 114, 153, 0.1);
		border-radius: 10px;
	}
	.ant-tabs-tab {
		width: 50% !important;
		display: block !important;
		text-align: center !important;
		background: none !important;
		border: none !important;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #007299;
		&:hover {
			${
				'' /* background: #007299 !important;
			border-radius: 10px !important; */
			}
			.ant-tabs-tab-btn {
				color: #007299 !important;
			}
		}
	}
	.ant-tabs-tab.ant-tabs-tab-active {
		background: #007299 !important;
		border-radius: 10px !important;
		.ant-tabs-tab-btn {
			color: #fff !important;
		}
	}
	#stckyScroll {
		overflow-y: scroll !important;
		height: 100%;
		max-height: 85vh !important;
		&::-webkit-scrollbar {
			width: 2px;
			height: 2px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
		&::-webkit-scrollbar {
			width: 3px;
			height: 3px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
	}
`;
export const XButtonBackRound = styled(Button)`
	color: ${({ theme }) => theme.colors.menu2};
	border-radius: 10px !important;
	border: 1px solid #f7f8fa;
	background: #f7f8fa;
	box-shadow: none;
	svg {
		font-size: 16px;
		font-weight: 600;
		color: ${({ theme }) => theme.colors.menu2};
	}
	&:hover {
		background: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
		svg {
			color: ${({ theme }) => theme.colors.white};
		}
	}
`;
export const XRelatedCard = styled(Card)`
	background: #f2f8fa;
	margin-bottom: 15px !important;
	// border-bottom: none;
	border-radius: 10px 10px 10px 10px;
	padding: 15px !important;
	.ant-card-body {
		padding: 0px !important;
	}
	${(props) =>
		props.variant === 'CPT'
			? `
			margin-bottom: 0px !important;
			border-bottom: none;
			border-radius: 10px 10px 0px 0px;
			`
			: `
			margin-bottom: 15px !important;
			border-radius: 10px;
			`}
`;
export const XRelatedReadMore = styled(Card)`
	border-top: none;
	border-radius: 0px 0px 10px 10px;
	margin-bottom: 24px !important;
	padding: 40px 15px 15px 15px !important;
	.ant-card-body {
		padding: 0px !important;
	}
	.react-read-more-read-less {
		position: absolute;
		top: -25px;
		width: 100%;
		padding-right: 15px;
	}
`;
export const XCardSupportingDocument = styled(Card)`
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	h2 {
		margin: 0px;
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		font-family: ${({ theme }) => theme.colors.fontInter};
		letter-spacing: -0.02em;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.uploadDocs {
		width: 100%;
		position: relative;
		background: #f7f8fa;
		border-radius: 10px;
		padding: 15px 20px;
		cursor: pointer;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.02em;
		color: ${({ theme }) => theme.colors.menu2};
		margin-bottom: 15px;
	}
`;
