import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { CenterWrapper, Logo, Title, XForm, XLottie } from './LoginStyle';
import { XContainer } from '../../styles/GlobalStyle';
import { Button, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import { loginApi, loginReset } from '../../actions/loginAction';
import { handleInputChange } from '../../utils/formFunctions';
import { getExpiresAt, isLogin } from '../../utils/helper';
import { requestPermissionAndGetToken } from '../../firebase/firebase';

const Login = (props) => {
	const { login, loginReset, loginState } = props;
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (isLogin()) {
			let expiresAt = getExpiresAt();
			if (expiresAt !== null && new Date() < new Date(expiresAt))
				navigate('/');
		}
	}, []);

	const generateFcmToken = async () => {
		await requestPermissionAndGetToken();
	};

	useEffect(() => {
		if (loginState.apiState === 'success') {
			setLoading(false);
			localStorage.setItem('user_id', loginState.data.id);
			localStorage.setItem('accessToken', loginState.data.accessToken);
			localStorage.setItem('refreshToken', loginState.data.refreshToken);
			localStorage.setItem('username', loginState.data.username);
			localStorage.setItem('email', loginState.data.email);
			localStorage.setItem('profile', loginState.data.profile_image);
			localStorage.setItem('expiresAt', loginState.data.expiresAt);
			localStorage.setItem('role', loginState.data.role.role);
			localStorage.setItem(
				'org_logo',
				loginState.data.organization_logo || '',
			);
			localStorage.setItem(
				'changePassword',
				loginState.data.changePassword,
			);
			localStorage.setItem(
				'permissions',
				JSON.stringify(loginState.data.permissions),
			);
			localStorage.setItem(
				'dashboardData',
				JSON.stringify(loginState.data.dashboardData),
			);

			generateFcmToken();
			loginReset();
			if (loginState.data.changePassword) {
				navigate('/consent');
			} else {
				navigate('/');
			}
		} else if (loginState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: loginState.message });
			loginReset();
		}
	}, [loginState]);

	return (
		<XContainer fluid>
			<Row>
				<Col xs={24}>
					<Logo>
						<img
							className="img-fluid"
							src="./images/logo.svg"
							alt="logo"
						/>
					</Logo>
				</Col>
			</Row>
			<CenterWrapper>
				<Row className="align-items-center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						lg={{ span: 10, order: 1 }}
					>
						<Title className="mb-4 mb-lg-5">
							Welcome to Medicare Coverage Analysis Platform..
						</Title>
						<XForm
							form={form}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={() => {
								setLoading(true);
								login(formData);
							}}
						>
							<XForm.Item
								name="email"
								label="Email Address"
								rules={[
									{
										required: true,
										message: 'Please enter email address',
									},
								]}
							>
								<Input
									size={'large'}
									name="email"
									placeholder="Enter Email Address"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
							<XForm.Item
								name="password"
								label="Password"
								rules={[
									{
										required: true,
										message: 'Please enter password',
									},
								]}
							>
								<Input.Password
									name="password"
									size={'large'}
									placeholder="Enter Password"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>
							<XForm.Item>
								{/* <XForm.Item
									name="remember"
									valuePropName="checked"
									noStyle
								>
									<XCheckbox>Keep me signed in</XCheckbox>
								</XForm.Item> */}
								<Link
									className="login-form-forgot"
									to="/forgot-email-password"
								>
									Forgot email or password?
								</Link>
							</XForm.Item>

							<XForm.Item className="mt-5">
								{loading ? (
									<Button type="primary" loading>
										Logging In...
									</Button>
								) : (
									<Button htmlType="submit">Log In</Button>
								)}
							</XForm.Item>
						</XForm>
					</Col>
					<Col
						xs={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						lg={{ span: 10, offset: 4, order: 2 }}
						className="text-center"
					>
						<XLottie animationData={LoginAnimation} loop={true} />
					</Col>
				</Row>
			</CenterWrapper>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({
	loginState: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	login: (params) => dispatch(loginApi(params)),
	loginReset: () => dispatch(loginReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
